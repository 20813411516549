<template>
  <!-- Payment information -->
  <div class="paymentInformation">
    <!-- 单价、定时刷新数据时间 -->
    <div class="feeTitle">
      <div class="feeTitle-name">1 {{ currencyData.name }} ≈ {{ price }} {{ positionData.code }}</div>
      <div class="feeTitle-value">
        <div class="loading-svg">
          <img src="@/assets/images/SellTime.png" alt="">
        </div>
        <div class="feeTitle-value-text"><span>{{ timeDownNumber }}</span>{{ $t('sellFee_components.codeSecond') }}</div>
      </div>
    </div>
    <!-- 详细费用信息 -->
    <div class="fee-content">
      <div class="fee-content-title" @click="expandFee">
        <div class="left">
          {{ $t('sellFee_components.title1') }} <span>{{ routerParams.amount }} {{ currencyData.name }}</span> {{ $t('sellFee_components.title2') }} <span>{{ routerParams.getAmount }} {{ positionData.code }}</span>
        </div>
        <div class="right">
<!--          <img :src="feeState?imageTop:imageBottom">-->
        </div>
      </div>
      <!-- rampFee  v-if="feeState"    feeState    openAnimation-->
      <div class="fee-content-details "  >
        <div class="fee-content-details-line">
          <div class="title">
            {{ $t('sellFee_components.rampFee') }}
            <el-popover
                placement="top"
                :trigger="triggerType"
                :offset="-18"
                :content="$t('sellFee_components.tips')">
              <div slot="reference"><img class="tipsIcon" src="../../assets/images/exclamatoryMarkIcon.png"></div>
            </el-popover>
          </div>
          <div class="value">
            <span class="minText">{{ $t('sellFee_components.RampFeeTips') }}</span>
            {{ feeInfo.fiatSymbol }} {{ rampFee }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 卖币费用组件
 * isHome 是否是首页使用
 */
import common from "../../utils/common";

export default {
  name: "includedDetails",
  props: {
    isHome: {
      type: Boolean,
      default: null
    }
  },
  data(){
    return{
      triggerType: "hover",

      timeDown: 60,
      timeOut: null,
      feeState: false,
      feeParams: {},
      feeInfo: {},
      routerParams: {},
      currencyData: {},
      positionData: {},
      timeDownNumber: 15,
      imageBottom:require('@/assets/images/blackDownIcon1.png'),
      imageTop:require('@/assets/images/imageTop.png'),
    }
  },
  watch: {
    //输入金额改变后刷新数据
    '$store.state.sellRouterParams.amount': {
      deep: true,
      handler(){
        //模拟防抖输入完成之后再触发
        clearTimeout(this.timeOut)
        this.timeOut = setTimeout(()=>{
          this.timingSetting();
        },500)
      }
    },
    //选择国家后刷新数据
    '$store.state.sellRouterParams.payCommission.fiatCode': {
      deep: true,
      handler(){
        this.$parent.getAmount = ''
        this.timingSetting();
      }
    },
    //选择数字货币后刷新数据
    '$store.state.sellRouterParams.currencyData.name': {
      deep: true,
      handler(){
        this.$parent.getAmount = ''
        this.timingSetting();
      }
    }
  },
  mounted(){
    //判断是pc还是移动端，用于展示的提示信息是click还是hover触发
    this.triggerType = common.equipmentEnd === 'pc' ? "hover" : "click";
    this.timingSetting();
  },
  activated(){
    //判断是pc还是移动端，用于展示的提示信息是click还是hover触发
    this.triggerType = common.equipmentEnd === 'pc' ? "hover" : "click";
    if(this.isHome && this.isHome === true){
      this.timingSetting();
    }
  },
  destroyed(){
    window.clearInterval(this.timeOut);
    this.timeOut = null;
  },
  deactivated(){
    window.clearInterval(this.timeOut);
    this.timeOut = null;
  },
  computed: {
    price(){
      let decimalDigits = 0;
      let resultValue = this.feeInfo.price * this.feeInfo.rateUsd;
      resultValue >= 1 ? decimalDigits = 2 : decimalDigits = 6;
      let price =  resultValue?resultValue.toFixed(decimalDigits):''
      isNaN(resultValue) || price <= 0 ? price = 0 : '';
      return price;
    },
    rampFee(){
      let decimalDigits = 0;
      let resultValue = this.feeInfo.rampFee;
      resultValue >= 1 ? decimalDigits = 2 : decimalDigits = 6;
      let rampFee =  resultValue?resultValue.toFixed(decimalDigits):''
      isNaN(resultValue) || rampFee <= 0 ? rampFee = 0 : '';
      return rampFee;
    },
  },
  methods:{
    //15秒定时刷新费用数据
    timingSetting(){
      this.currencyData = this.$store.state.sellRouterParams.currencyData;
      this.positionData = this.$store.state.sellRouterParams.positionData;
      this.routerParams = this.$store.state.sellRouterParams;
      window.clearInterval(this.timeOut);
      this.timeOut = null;
      this.queryFee();
      this.timeOut = setInterval(()=> {
        if (this.timeDownNumber === 1) {
          this.timeDownNumber = 15;
          this.queryFee();
        }else{
          this.timeDownNumber -= 1;
        }
      },1000)
    },
    //获取费用数据
    queryFee(){
      let params = JSON.parse(JSON.stringify(this.$store.state.feeParams));
      params.amount = this.$store.state.sellRouterParams.amount;
      //存在商户信息入参添加appId
      let accessMerchantInfo = sessionStorage.getItem("buyMerchantInfo");
      let merchantType = sessionStorage.getItem("merchantType");
      if(accessMerchantInfo !== undefined && accessMerchantInfo && JSON.parse(accessMerchantInfo).merchantParam_state && JSON.parse(accessMerchantInfo).appId && merchantType === 'sell'){
        params.appId = JSON.parse(accessMerchantInfo).appId;
      }
      //控制卖币首页按钮禁用
      if(this.isHome && this.isHome === true && this.timeDownNumber !== 15){
        this.$parent.exchange = true
      }
      //首页切换金额时将按钮禁用
      this.$axios.get(this.$api.get_inquiryFeeSell,params).then(res=>{
        if(res && res.data){
          this.$store.state.sellRouterParams.currencyData.price = res.data.price;
          this.feeInfo = res.data;
          //修改首页费用数据
          if(this.isHome && this.isHome === true){
            this.$parent.feeInfo = this.feeInfo;
            let decimalDigits = 0;
            let resultValue = res.data.fiatAmount - res.data.rampFee;
            resultValue >= 1 ? decimalDigits = 2 : decimalDigits = 6;
            let getAmount = resultValue?resultValue.toFixed(decimalDigits):''
            isNaN(resultValue) || getAmount <= 0 ? getAmount = 0 : '';
            this.$parent.getAmount = getAmount;
            // if(this.$route.path === '/'){
            //   this.$parent.exchange = false
              this.$parent.warningTextState = true;
              if((this.$parent.payAmount === '' || this.$parent.payAmount  <= 0) && this.$parent.getAmount) {
                // this.$parent.getAmount = ''
              }
            // }
            this.$store.state.sellRouterParams.getAmount = getAmount;
          }
        }
      })
    },

    //展开费用信息
    expandFee(){
      this.feeState = this.feeState === true ? false : true;
      if(this.feeState === true){
        setTimeout(()=>{
          this.$parent.$refs.includedDetails_ref.$el.scrollIntoView({behavior: "smooth", block: "end", inline: 'end'});
        })
        return
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.paymentInformation {
  .feeTitle {
    font-size: .13rem;
    font-weight: normal;
    color: #031633;
    display: flex;
    align-items: center;
    padding: 0 .2rem 0;
    .feeTitle-name {
      color: #031633;
      font-family: Regular;
      font-weight: 400;
    }

    .feeTitle-value {
      font-family: Regular;
      font-weight: 400;
      display: flex;
      align-items: center;
      margin-left: auto;
      color: #6E7687;

      .loading-svg {
        margin-right: 0.04rem;
        font-size: 0.13rem;
        margin-top: 0.02rem;
        img {
          height: 0.12rem;
        }
      }
      .feeTitle-value-text{
        span{
          display: inline-block;
          width: 0.15rem;
          text-align: center;
        }
      }
    }
  }

  .fee-content {
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #F4F5F7;
    border: 1px solid #D9D9D9;
    border-radius: .06rem;
    //border-radius: 0.12rem;
    margin-top: 0.36rem;
    padding: .2rem;
    .fee-content-title {
       padding: 0rem 0  .1rem;
      display: flex;
      align-items: center;
      font-size: 0.13rem;
      font-family:Regular;
      font-weight: normal;
      color: #6E7687;
      cursor: pointer;
      border-bottom: 1px dashed #D9D9D9;

      span {
        font-weight: bold;
      }

      .left {
        word-break: break-all;
        font-size: 0.13rem;
        font-family: Regular;
        font-weight: 400;
        color: #6E7687;
        span:nth-of-type(2){
          color: rgba(0, 89, 218, 1);
        }
        span {
          color: #031633;
          font-weight: bold;
          font-family: Regular;
          //font-weight: 400;
        }
      }

      .right {
        margin-left: auto;
        img{
          width: 0.24rem;
        }
      }
    }

    .fee-content-details {
      .fee-content-details-line {
        display: flex;
        align-items: center;
        margin-top: 0.16rem;

        .title {
          display: flex;
          align-items: center;
          font-size: 0.13rem;
          font-family: Regular;
          font-weight: 400;
          color: #6E7687;

          .tipsIcon {
            width: 0.12rem;
            height: 0.12rem;
            margin:.02rem 0 0 0.08rem;
            display: flex;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .value {
          margin-left: auto;
          display: flex;
          align-items: center;
          font-size: 0.13rem;
          font-family: Regular;
          color: #6E7687;
          font-weight: 400;

          .minText {
            font-family: Regular;
            font-weight: normal;
            color: #C2C2C2;
            margin-right: 0.04rem;
            margin-top: -0.02rem;
          }
        }
      }
    }

    //展开过渡动画
    .openAnimation{
      animation: openHandler 0.2s;
    }
  }
}

//展开过渡动画
@keyframes openHandler{
  0% {
    height: 0.025rem;
  }
  10% {
    height: 0.05rem;
  }
  20% {
    height: 0.075rem;
  }
  30% {
    height: 0.1rem;
  }
  50% {
    height: 0.125rem;
  }
  60% {
    height: 0.15rem;
  }
  70% {
    height: 0.175rem;
  }
}
</style>
