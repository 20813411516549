<template>
  <div id="homePage">
    <div class="homePage_content" v-show="searchState">
      <div class="homePage_view" v-show="!menuState">
        <div class="home-header">
          <div class="home-tab">
            <div :class="{'tabClass': tabstate==='buyCrypto'}" @click="switchTab('buyCrypto')" v-if="tableState===false">{{ $t('homePage.tab_buy') }}</div>
            <div :class="{'tabClass': tabstate==='sellCrypto'}" @click="switchTab('sellCrypto')" v-if="tableState===false">{{ $t('homePage.tab_sell') }}</div>
            <!-- 商户配置菜单栏 -->
            <div :class="{'tabClass': tabstate==='buyCrypto'}" @click="switchTab('buyCrypto')" v-if="tableState===true && tabstate==='buyCrypto'">{{ $t('homePage.tab_buy') }}</div>
            <div :class="{'tabClass': tabstate==='sellCrypto'}" @click="switchTab('sellCrypto')" v-else-if="tableState===true && tabstate==='sellCrypto'">{{ $t('homePage.tab_sell') }}</div>
          </div>
          <div class="allPage-icon">
            <img style="height:.32rem;width:.32rem;margin-top:-.1rem" src="@/assets/images/meun-icon.png" @click="openMenu">
          </div>
        </div>
        <div class="home-children">
          <buyCrypto v-show="$store.state.homeTabstate === 'buyCrypto'" :allBasicData="basicData" ref="buyCrypto_ref"/>
          <sellCrypto v-show="$store.state.homeTabstate === 'sellCrypto'" :allBasicData="basicData" ref="sellCrypto_ref"/>
        </div>
      </div>
      <!--  menu view  -->
      <div v-show="menuState">
        <div class="navigationBar_view">
          <div class="navigationBar_view_left">{{ $t('menu.menu') }}</div>
          <div class="navigationBar_view_right" @click="openMenu">
            <img src="@/assets/images/ShutDown.png" style="margin-right:-.05rem" >
          </div>
        </div>
        <routerMenu/>
      </div>
    </div>
    <!-- search Public organization -->
    <search v-if="!searchState" ref="search_ref" :viewName="viewName" :allBasicData="basicData" routerFrom="home"/>
  </div>
</template>

<script>
import sellCrypto from '@/views/initialPage/childrens/sellCrypto'
import buyCrypto from '@/views/initialPage/childrens/buyCrypto'
import search from '@/components/SearchView/search'
import routerMenu from '@/components/Menu/routerMenu'
import {hashQuery, merchantInfo} from "@/utils/publicRequest";

export default {
  name: "index",
  components: { buyCrypto, sellCrypto, search, routerMenu },
  data() {
    return {
      menuState: false,
      searchState: true,
      viewName: "",
      basicData: {},
    }
  },
  mounted(){
    console.log("home data mounted");
    this.merchantDocking();
  },
  computed: {
    //商户对接tab状态
    tableState(){
      return this.$store.state.tableState;
    },
    tabstate(){
      let _this = this;
      if(hashQuery().query.showTable === 'buy'){
        _this.$store.state.tableState = true;
        _this.$store.state.homeTabstate = 'buyCrypto';
      }else if(hashQuery().query.showTable === 'sell'){
        _this.$store.state.tableState = true;
        _this.$store.state.homeTabstate = 'sellCrypto';
      }
      return this.$store.state.homeTabstate;
    }
  },
  beforeRouteEnter(to,from,next){
    next(vm=>{
      //登陆后刷新首页基本数据
      if(to.name === 'home' && from.name === 'verifyCode'){
        vm.merchantDocking();
      }
    })
  },
  methods: {
    openSearch(view) {
      this.searchState = false;
      this.viewName = view;
    },
    openMenu(){
      this.menuState = this.menuState === true ? false : true;
      this.$store.state.menuState = this.menuState ? true : false;
    },
    switchTab(tab){
      this.$store.state.homeTabstate = tab;
    },

    //获取基本数据 - 国家、法币、加密币、网络
    queryInfo(){
      let _this = this;
      //带入商户信息 过滤商户加密货币
      let merchantInfo = "";
      if(JSON.stringify(hashQuery().query) !== "{}"){
        merchantInfo = hashQuery().fullPath;
      }else if(JSON.stringify(hashQuery().query) === "{}" && sessionStorage.getItem("buyMerchantInfo") && JSON.parse(sessionStorage.getItem("buyMerchantInfo")).merchantParam) {
        merchantInfo = JSON.parse(sessionStorage.getItem("buyMerchantInfo")).merchantParam;
      }
      this.$axios.get(this.$api.get_buyCryptoInfo + '?' + merchantInfo,"").then(res=>{
        if(res && res.data){
          _this.basicData = JSON.parse(JSON.stringify(res.data));
          localStorage.setItem("allBasicData",JSON.stringify(_this.basicData));
          if(_this.$store.state.tableState === true && _this.$store.state.homeTabstate === 'sellCrypto'){
            _this.$refs.sellCrypto_ref.currentLocation();
            _this.merchantVerify();
          }else if(_this.$store.state.tableState === true && _this.$store.state.homeTabstate === 'buyCrypto'){
            _this.$refs.buyCrypto_ref.basicsInfo(1);
          }else{
            _this.$refs.buyCrypto_ref.basicsInfo(1);
            _this.$refs.sellCrypto_ref.currentLocation();
            _this.merchantVerify();
          }
        }
      }).catch(()=>{
        this.$store.commit("faitBuyPage/restoreDefault");
      })
    },

    //对接商户参数 - 语言、tab状态、商户token
    async merchantDocking(){
      hashQuery();
      merchantInfo();
      let orderNo = hashQuery().query.orderNo ? hashQuery().query.orderNo : '';
      orderNo === "" ? this.queryInfo() : '';
    },

    //校验商户信息是否有效
    merchantVerify(){
      let state = sessionStorage.getItem("merchantType") && sessionStorage.getItem("merchantType") === 'buy';
      if(state){
        return
      }
      let merchantParams = {};
      JSON.stringify(hashQuery().query) !== "{}" ? merchantParams = hashQuery().query : merchantParams = JSON.parse(sessionStorage.getItem("buyMerchantInfo"));
      merchantParams === null ? merchantParams = {} : '';
      merchantParams.networkDefault = false;
      merchantParams.addressDefault = false;
      merchantParams.memoDefault = false;

      sessionStorage.setItem("buyMerchantInfo",JSON.stringify(merchantParams));

      let params = merchantParams;
      delete params.networkDefault;
      delete params.addressDefault;
      delete params.memoDefault;
      delete params.merchantParam_state;

      this.$axios.get(this.$api.get_orderVerification, params).then(res=>{
        this.$nextTick(()=>{
          this.$refs.sellCrypto_ref.merchantInformationAssignment(res,merchantParams);
        })
      }).catch((error)=>{
        this.$refs.sellCrypto_ref.merchantInformationAssignment(error,merchantParams);
      })
    },
  },
};
</script>

<style lang="scss" scoped>
html,body,#homePage,.homePage_view,.homePage_content{
  width: 100%;
  height: 100%;
}
.homePage_view{
  display: flex;
  flex-direction: column;
  .home-children{
    flex: 1;
    overflow: auto;
  }
  .home-header {
    display: flex;
    align-items: center;
    padding-bottom: 0.24rem;
    .home-tab{
      display: flex;
      align-items: center;
      font-family: Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 0.18rem;
      color: #949EA4;
      div{
        display: flex;
        cursor: pointer;
        padding-bottom: 0.12rem;
        border-bottom: 4px solid #FFFFFF;

      }
      div:nth-of-type(2){
        margin-left: 0.4rem;
        cursor: pointer;
      }
      .tabClass{
        color: #063376;
        border-bottom: 4px solid #0059DA;
      }
    }
    .allPage-icon {
      margin-left: auto;
      display: flex;
      img{
        width: 0.2rem;
        cursor: pointer;
      }
    }
  }
}

.navigationBar_view {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
  .navigationBar_view_left {
    display: flex;
    align-items: center;
    font-size: 0.18rem;
    font-family: Semibold;
    font-weight: 600;
    // font-weight: bold;
    color: #031633;
  }
  .navigationBar_view_right {
    padding: .06rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.1rem 0 0.1rem 0.1rem;
    margin-left: auto;
    cursor: pointer;
    img {
      width: 0.22rem;
      cursor: pointer;
      margin-right: -.2rem;
    }
  }
}
</style>
