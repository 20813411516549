<template>
  <!-- Payment information -->
  <div class="paymentInformation">
    <!-- 单价、定时刷新数据时间 -->
    <div class="feeTitle" :class="{'titleMargin': !isHome}">
      <div class="feeTitle-name" v-if="isHome">1 {{ pageData.cryptoInfo.crypto }} ≈ {{ pageData.feeInfo.cryptoPrice }} {{ pageData.fiatCurrencyInfo.fiat }}</div>
      <div class="feeTitle-value">
        <div class="loading-svg">
         <img src="@/assets/images/SellTime.png" alt="">
        </div>
        <div class="feeTitle-value-text"><span>{{ timeDownNumber }}</span>{{ $t('buyFee_components.codeSecond') }}</div>
      </div>
    </div>
    <div class="fee-content" :class="{'contentMargin': isHome,}">
      <div class="fee-content-title" :class="{'clearTitleBorder': detailsState}" @click="expandCollapse"> <!--  @click="expandCollapse" -->
        <div class="left">
          {{ $t('buyFee_components.youGet') }} <span>{{ pageData.cryptoAmount }} {{ pageData.cryptoInfo.crypto }}</span> {{ $t('buyFee_components.title2') }} <span>{{ pageData.fiatCurrencyAmount }} {{ pageData.fiatCurrencyInfo.fiat }}</span>
        </div>
        <!-- 商户接入模式禁止点击 -->
        <div class="right" v-if="merchantInfo.merchantType !== 'api'">
          <img :src="detailsState?image_Top:imageBottom">
        </div>
      </div>
      <!-- 详细费用信息 -->
      <div class="fee-content-details" v-if="detailsState" :class="{'openAnimation': detailsState}"> <!--  v-if="detailsState" :class="{'openAnimation': detailsState}" -->
        <!-- ramp fee -->
        <div class="fee-content-details-line">
          <div class="title">
            {{ $t('buyFee_components.rampFee') }}
            <el-popover
                placement="top"
                trigger="click"
                :offset="-18"
                v-model="popover_state">
              <p>{{ $t('buyFee_components.tips') }}</p>
              <div slot="reference"><img class="tipsIcon" src="../../assets/images/exclamatoryMarkIcon.png"></div>
            </el-popover>
          </div>
          <!-- 确认支付页隐藏rampFee的as low as提示文案(payWayCode有值为确认订单页) -->
          <!-- 无折扣rampFee -->
          <div class="value"  v-if="pageData.feeInfo.discountRampFee === pageData.feeInfo.rampFee"><span class="minText" v-if="!payWayCode">{{ $t('buyFee_components.rampFeeTips') }}</span> {{ pageData.fiatCurrencyInfo.fiatSymbol }} {{ pageData.feeInfo.rampFee }}</div>
          <!-- 有折扣rampFee -->
          <div class="value wordValue" v-else><span class="minText" v-if="!payWayCode">{{ $t('buyFee_components.rampFeeTips') }}</span> <p>{{ pageData.fiatCurrencyInfo.fiatSymbol }} {{ pageData.feeInfo.discountRampFee ? pageData.feeInfo.discountRampFee : '' }}</p> <p>{{ pageData.fiatCurrencyInfo.fiatSymbol }} {{ pageData.feeInfo.rampFee ? pageData.feeInfo.rampFee : '' }}</p></div>
        </div>
        <!-- network fee -->
        <div class="fee-content-details-line">
          <div class="title">{{ $t('buyFee_components.networkFee') }}</div>
          <div class="value">{{ pageData.fiatCurrencyInfo.fiatSymbol }} {{ pageData.feeInfo.networkFee }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 买币费用展示组件
 * type 1 - 首次调用不触发、2 - 首次调用触发、3 - 商户确认订单需要
 * isHome - 是否首页调用
 * payWayCode - 选择支付方式后带入支付方式code
 */
import common from "../../utils/common";
import { noData } from "@/utils";
import { mapState } from 'vuex';
import { hashQuery } from "@/utils/publicRequest";

export default {
  name: "includedDetails",
  props: {
    type: {
      type: Number,
      default: null
    },
    //是否在首页
    isHome: {
      type: Boolean,
      default: null
    },
    //确认订单页支付方式code
    payWayCode: {
      type: String,
      default: null,
    },
  },
  data(){
    return{
      triggerType: "hover",
      popover_state: false,

      timeDown: 60,
      timeOut: null,
      detailsState: false,

      timeDownNumber: 15,

      imageBottom:require('@/assets/images/blackDownIcon1.png'),
      image_Top:require('@/assets/images/imageTop.png'),
    }
  },
  mounted(){
    this.scenarioVerification();
  },
  activated(){
    this.scenarioVerification();
  },
  destroyed(){
    this.timeDownNumber = 15;
    window.clearInterval(this.timeOut);
    this.timeOut = null;
  },
  deactivated(){
    this.timeDownNumber = 15;
    window.clearInterval(this.timeOut);
    this.timeOut = null;
  },
  computed: {
    ...mapState('userInfo',{
      isDiscount: state => state.isDiscount,
      token: state => state.token,
      activityStatus: state => state.activityStatus,
    }),
    ...mapState('faitBuyPage',{
      fiatCurrencyInfo: state => state.fiatCurrencyInfo,
      cryptoInfo: state => state.cryptoInfo,
      networkInfo: state => state.networkInfo,
      fiatCurrencyAmount: state => state.fiatCurrencyAmount,
      cryptoAmount: state => state.cryptoAmount,
      feeInfo: state => state.feeInfo,
    }),
    ...mapState('buyPayments', {
      orderNo: state => state.orderNo,
    }),
    ...mapState('secondaryBuyPayments',{
      fiatCurrencyInfo_secondaryBuy: state => state.fiatCurrencyInfo,
      cryptoInfo_secondaryBuy: state => state.cryptoInfo,
      networkInfo_secondaryBuy: state => state.networkInfo,
      fiatCurrencyAmount_secondaryBuy: state => state.fiatCurrencyAmount,
      cryptoAmount_secondaryBuy: state => state.cryptoAmount,
      feeInfo_secondaryBuy: state => state.feeInfo,
    }),

    //商户信息
    ...mapState('buyCryptoMerchant',['merchantInfo']),

    /**
     * 页面所展示费用信息,区分ramp二次支付展示信息和ramp流程展示信息
     * 二次支付条件 - 存在$route.query.pathHistry
     * @returns {{fiatCurrencyInfo, networkInfo, feeInfo, cryptoAmount, cryptoInfo, fiatCurrencyAmount}}
     */
    pageData(){
      let params = {};
      //二次支付费用数据
      if(this.$route.query.pathHistry==='tradeHistory'){
        params = {
          fiatCurrencyInfo: this.fiatCurrencyInfo_secondaryBuy,
          cryptoInfo: this.cryptoInfo_secondaryBuy,
          networkInfo: this.networkInfo_secondaryBuy,
          fiatCurrencyAmount: this.fiatCurrencyAmount_secondaryBuy,
          cryptoAmount: this.cryptoAmount_secondaryBuy,
          feeInfo: this.feeInfo_secondaryBuy,
        }
        return params
      }
      //首页场景数据、商户确认订单数据
      params = {
        fiatCurrencyInfo: this.fiatCurrencyInfo,
        cryptoInfo: this.cryptoInfo,
        networkInfo: this.networkInfo,
        fiatCurrencyAmount: this.fiatCurrencyAmount,
        cryptoAmount: this.cryptoAmount,
        feeInfo: this.feeInfo,
      }
      return params;
    }
  },
  methods:{
    //分场景调用不同费用方法
    scenarioVerification(){
      //首页默认展开费用信息
      this.isHome ? (this.detailsState = true) : (this.detailsState = false);
      //判断是pc还是移动端，用于展示的提示信息是click还是hover触发
      this.triggerType = common.equipmentEnd === 'pc' ? "hover" : "click";
      if(this.type === 1){
        this.timingSetting(1);
        return
      }
      if(this.type === 2 && this.$route.query.orderNo && this.$route.query.orderNo !== 'undefined' && this.$route.query.orderNo !== ''){
        this.merchantQueryFee();
        this.timingSetting(2);
        return;
      }
      if(this.type === 2){
        this.queryFee();
        this.timingSetting(2);
      }
    },

    /**
     * 15秒定时刷新费用数据
     * @param type
     * type===1 ramp流程
     * type===2 api商户
     */
    timingSetting(type){
      this.timeDownNumber = 15;
      window.clearInterval(this.timeOut);
      this.timeOut = null;
      this.timeOut = setInterval(()=> {
        if (this.timeDownNumber === 1) {
          this.timeDownNumber = 15;
          type === 1 ? this.queryFee() : this.merchantQueryFee();
        }else{
          this.timeDownNumber -= 1;
        }
      },1000)
    },

    /**
     * 获取费用数据
     * 场景: ramp流程、二次支付
     * 商户信息有效携带商户信息
     */
    queryFee(){
      let _this = this;
      //1 - ramp流程场景、2 - 二次支付场景
      let type = this.$route.query.pathHistry==='tradeHistory' ? 2 : 1;
      //带入商户信息 过滤商户加密货币
      let merchantInfo = "";
      if(JSON.stringify(hashQuery().query) !== "{}"){
        merchantInfo = hashQuery().fullPath;
      }else if(JSON.stringify(hashQuery().query) === "{}" && sessionStorage.getItem("buyMerchantInfo") && JSON.parse(sessionStorage.getItem("buyMerchantInfo")).merchantParam_state && JSON.parse(sessionStorage.getItem("buyMerchantInfo")).merchantParam) {
        merchantInfo = JSON.parse(sessionStorage.getItem("buyMerchantInfo")).merchantParam;
      }
      this.$axios.post(this.$api.post_buyInfoFee + "?" + merchantInfo,this.defineQueryData(type),'').then(res=> {
        if (res && res.data) {
          _this.storeData(type,res)
        }
      })
    },
    /**
     * 费用信息接口入参
     * @param type
     * @returns {{side: string, amount, alpha2, fiat, crypto, network, payWayCode: string}}
     */
    defineQueryData(type){
      let params = {};
      //type - 1首页数据参数
      if(type === 1){
        params = {
          crypto: this.cryptoInfo.crypto,
          fiat: this.fiatCurrencyInfo.fiat,
          side: 'buy',
          amount: this.fiatCurrencyAmount,
          alpha2: this.fiatCurrencyInfo.alpha2,
          network: this.networkInfo.network,
          payWayCode: this.payWayCode,
        }
        return params
      }
      //type - 2二次支付场景参数
      if(type === 2){
        params = {
          crypto: this.cryptoInfo_secondaryBuy.crypto,
          fiat: this.fiatCurrencyInfo_secondaryBuy.fiat,
          side: 'buy',
          amount: this.fiatCurrencyAmount_secondaryBuy,
          alpha2: this.fiatCurrencyInfo_secondaryBuy.alpha2,
          network: this.networkInfo_secondaryBuy.network,
          payWayCode: this.payWayCode,
        }
        return params
      }
    },

    /**
     * api商户确费用信息
     *
     */
    merchantQueryFee(){
      let _this = this;
      let params = {
        orderNo: this.orderNo,
      }
      this.$axios.get(this.$api.get_orderFee,params).then(res=> {
        if (res && res.data) {
          _this.storeData(3,res)
        }
      })
    },

    /**
     * 分场景存储费用信息
     * @param type 场景类型
     * type===1 - ramp流程
     * type===2 - 二次支付
     * type===3 - api商户
     * @param res 需要存储费用信息
     */
    storeData(type,res){
      //type - 1存储首页信息
      if(type === 1){
        //存储费用信息
        let feeInfo = {
          rampFee: noData(res.data.rampFee),  //通道费
          networkFee: res.data.discount ? res.data.discountNetworkFee : res.data.networkFee, //网络费
          cryptoPrice: noData(res.data.cryptoPrice), //加密货币价格
          discountRampFee: noData(res.data.discountRampFee), //折扣后的通道费
        }
        this.$store.commit("faitBuyPage/set_feeInfo",feeInfo);
        //存储数字货币金额
        let cryptoAmount = res.data.discount ? res.data.discountCryptoAmount : res.data.cryptoAmount
        this.$store.commit("faitBuyPage/set_cryptoAmount",noData(cryptoAmount));
        //存储用户是否有折扣
        this.$store.commit("userInfo/set_isDiscount",res.data.discount);
        return
      }
      //type - 2存储二次支付确认订单费用信息
      if(type === 2){
        let feeInfo = {
          rampFee: noData(res.data.rampFee),  //通道费
          networkFee: res.data.discount ? res.data.discountNetworkFee : res.data.networkFee, //网络费
          cryptoPrice: noData(res.data.cryptoPrice), //加密货币价格
          discountRampFee: noData(res.data.discountRampFee), //折扣后的通道费
        }
        this.$store.commit("secondaryBuyPayments/set_feeInfo",feeInfo);
        //存储数字货币金额
        let cryptoAmount = res.data.discount ? res.data.discountCryptoAmount : res.data.cryptoAmount
        this.$store.commit("secondaryBuyPayments/set_cryptoAmount",noData(cryptoAmount));
        //存储用户是否有折扣
        this.$store.commit("userInfo/set_isDiscount",res.data.discount);
        return;
      }
      //type - 3商户确认订单费用信息
      let feeInfo = {
        rampFee: Number(noData(res.data.serviceFee)),  //通道费
        networkFee: Number(noData(res.data.networkFee)), //网络费
        cryptoPrice: Number(noData(res.data.price)), //加密货币价格
        discountRampFee: Number(noData(res.data.serviceFee)), //折扣后的通道费
      }
      this.$store.commit("faitBuyPage/set_feeInfo",feeInfo);
      //存储数字货币金额
      this.$store.commit("faitBuyPage/set_cryptoAmount",Number(noData(res.data.cryptoAmount)));
      //存储用户是否有折扣
      this.$store.commit("userInfo/set_isDiscount",false);
    },

    //展开或收起费用
    expandCollapse(){
      //商户接入模式禁止点击
      if(this.merchantInfo.merchantType === 'api' && this.merchantInfo.merchantStatus === true){
        return;
      }
      this.detailsState === true ? (this.detailsState = false) : (this.detailsState = true);
      if(this.detailsState === true){
        setTimeout(()=>{
          this.$parent.$refs.includedDetails_ref.$el.scrollIntoView({behavior: "smooth", block: "end", inline: 'end'});
        })
      }
    },
  }
}
</script>
<style lang="scss">
.el-popover .popper__arrow::after{
  margin-left: -1px !important;
  border-top-color: #333333 !important;
}
</style>
<style lang="scss" scoped>
.paymentInformation{
  .titleMargin{
    margin-top: 0.18rem !important;
  }

  .feeTitle{
    font-size: 13px;
    font-weight: normal;
    color: #6E7687;
    line-height: 15px;
    display: flex;
    align-items: center;
    padding: 0 .2rem 0;
    .feeTitle-name{
      font-family: Regular;
      font-weight: 400;
      color: #031633;
    }
    .feeTitle-value{
      font-family: Regular;
      font-weight: 400;
      display: flex;
      align-items: center;
      margin-left: auto;
      .loading-svg{
        margin-right: 0.04rem;

        margin-top: 0.02rem;
        img{
          height: 0.14rem;
        }
      }
      .feeTitle-value-text{
        color: #6E7687;
        span{
          display: inline-block;
          width: 0.15rem;
          text-align: center;
        }
      }
    }
  }

  .contentMargin{
    margin-top: 0.4rem !important;
  }


  .fee-content{
    position: relative;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #F4F5F7 ;
    border: 1px solid #D9D9D9;
    border-radius: 0.16rem;
    margin-top: 0.08rem;
    padding: 0 0.2rem 0 0.2rem;
    .fee-content-title{
      min-height: 0.6rem;
      border-bottom: 1px dashed #FFFFFF;
      display: flex;
      align-items: center;
      font-size: 0.16rem;
      font-family: Regular;
      font-weight: 400;
      color: #6E7687;
      cursor: pointer;
      span{
        font-weight: bold;
      }
      .left{
        word-break: break-all;
        font-size: 0.13rem;
        font-family: Regular;
        font-weight: 400;
        color: #6E7687;
        span{
          color: rgba(3, 22, 51, 1);
          font-family: Regular;
          font-weight: 400;
          font-weight: bold;
        }
        span:nth-of-type(1){
          font-weight: 400;
          color: #031633;
          font-weight: bold;
        }
      }
      .right {
        margin-left: auto;
        img{
          width: 0.24rem;
        }
      }
    }

    .clearTitleBorder{
      border-bottom: 1px dashed #D9D9D9;
    }

    .fee-content-details{
      padding: 0 0 0.2rem 0;
      overflow: hidden;
      .fee-content-details-line{
        display: flex;
        align-items: center;
        margin-top: 0.16rem;
        .title{
          display: flex;
          align-items: center;
          font-size: 0.13rem;
          font-family: Regular;
          font-weight: 400;
          color: #6E7687;
          .tipsIcon{
            width: 0.12rem;
            height: 0.12rem;
            margin-left: 0.08rem;
            display: flex;
            img{
              width: 100%;
              height: 100%;
            }
          }
        }
        .value{
          margin-left: auto;
          display: flex;
          align-items: center;
          font-size: 0.13rem;
          font-family: Regular;
          font-weight: 400;
          color: #6E7687;
          .minText{
            font-family: Regular;
            font-weight: 400;
            color: #C2C2C2;
            margin-right: 0.04rem;
            margin-top: -0.02rem;
          }
        }
      }
    }
  }

  //展开过渡动画
  .openAnimation{
    animation: openHandler 0.2s;
  }

}

//展开过渡动画
@keyframes openHandler{
  0% {
    height: 0.1rem;
  }
  10% {
    height: 0.2rem;
  }
  20% {
    height: 0.3rem;
  }
  30% {
    height: 0.4rem;
  }
  50% {
    height: 0.5rem;
  }
  60% {
    height: 0.6rem;
  }
  70% {
    height: 0.7rem;
  }
}
.wordValue{
  display: flex;
  .minText{
    display: block;
    margin-top: .03rem !important;
  }
  p:nth-of-type(1){
    color: #0AB24D;
    font-weight: 700;
    font-size: .16rem;
    margin: 0 .04rem 0;
  }
  p:nth-of-type(2){
    text-decoration:line-through;
    text-decoration-color: #0AB24D;
    color: #BFBFBF;
    font-size: .11rem;
    margin-top: .03rem;
  }
}

</style>
