<template>
  <div id="viewBox" ref="viewApp">
    <div class="buyCrypto_iframe_view" :class="{'buyCrypto_iframe_view_pc': logoState}">
      <!-- 填写卡信息 -->
      <div id="App" :class="{ App_pc: logoState }" :style="{ width: shadowPadding ? '100%' : logoState ? '86.4%' : '89%' }">
        <!-- ,'shadow-view': shadowPadding -->
        <!-- 导航栏 -->
        <tab ref="viewTab" :style="{ paddingLeft: shadowPadding ? (logoState ? '5.5%' : '5.5%') : 0, paddingRight: shadowPadding ? (logoState ? '5.5%' : '5.5%') : 0 }" :confirmOrderStatus="confirmOrderStatus" />
        <!--  :class="{'viewTab-padding': shadowPadding}" -->
        <!-- 页面内容 -->
        <div class="contentView" ref="routerView_box"> <!--  :style="{ paddingLeft: shadowPadding ? (logoState ? '6.5%' : '5.5%') : 0, paddingRight: shadowPadding ? (logoState ? '5.5%' : '5.5%') : 0}" -->
          <!--  :class="{'contentView-padding': shadowPadding}" -->
          <div class="routerView_box" v-show="routerViewState">
            <div class="routerView">
              <!-- 买币下单步骤 -->
              <BuyNavTab :style="{ paddingLeft: shadowPadding ? (logoState ? '6.5%' : '5.5%') : 0, paddingRight: shadowPadding ? (logoState ? '5.5%' : '5.5%') : 0}"/>
              <keep-alive class="keepAlive" :exclude="keepAlive" :style="{ paddingLeft: shadowPadding ? (logoState ? '6.5%' : '5.5%') : 0, paddingRight: shadowPadding ? (logoState ? '5.5%' : '5.5%') : 0, padding: clearPadding ? 0 : ''}">
                <router-view ref="routerView" />
              </keep-alive>
            </div>
          </div>
          <!-- 菜单栏  -->
          <routerMenu v-if="!routerViewState" :style="{ paddingLeft: shadowPadding ? (logoState ? '6.5%' : '5.5%') : 0, paddingRight: shadowPadding ? (logoState ? '5.5%' : '5.5%') : 0}"/>
          <!-- 语言切换 -->
          <Language v-if="LanguageShow" />
          <!-- 买币 - 确认支付后查询支付状态提示框、泰铢支付确认跳转弹框 -->
          <QueryOrderStatusTips :buyOrderStatus="BuyTimeOut" v-if="tipsState" :style="{ paddingLeft: shadowPadding ? (logoState ? '6.5%' : '5.5%') : 0, paddingRight: shadowPadding ? (logoState ? '5.5%' : '5.5%') : 0}"/>
          <!-- 卖币 - 历史卡信息 -->
          <HistoricalCardInfoSell :data="cardInfoList" v-show="historicalCardInfoSell_state" :style="{ paddingLeft: shadowPadding ? (logoState ? '6.5%' : '5.5%') : 0, paddingRight: shadowPadding ? (logoState ? '5.5%' : '5.5%') : 0}"/>
        </div>
      </div>

      <!-- pc端展示logo -->
      <div class="logoView" v-if="logoState">
        <div>Powered By</div>
        <div><img src="./assets/images/pcLogo.png" /></div>
      </div>
      <!-- 版本号 -->
      <!--      <span class="version">V: {{ version }}</span>-->
      <!-- 账号风险提示无法进行下一步 -->
      <!-- <div class="kycToast" v-show="AccountisShow">
          <div>
            <img src="@/assets/images/kycDisable.png" alt="">
              <div>
             {{ $t('nav.accountTraded') }}
              </div>
            <div @click="goHome">{{ $t('nav.Confirm') }}</div>
          </div>
        </div> -->
    </div>
  </div>
</template>

<script>
import common from './utils/common';
import remSize from './utils/remSize';
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    HistoricalCardInfoSell: () => import('./components/CardInfoListSell/HistoricalCardInfo-sell'),
    routerMenu: () => import('./components/Menu/routerMenu'),
    QueryOrderStatusTips: () => import('./components/Dialog/QueryOrderStatusTips'),
    Language: () => import('./components/Language/Language.vue'),
    tab: () => import('./components/NavBar/navigationBar'),
    BuyNavTab: () => import('./components/StepBar/buyNavTab')
  },
  data() {
    return {
      routerViewState: true,
      logoState: true,
      tipsState: false,
      version: '',
      LanguageShow: false,
      historicalCardInfoSell_state: false,
      AccountisShow: false,
      BuyTimeOut: '',
      //卖币历史卡信息
      cardInfoList: [],
      confirmOrderStatus: false
    };
  },
  computed: {
    //路由是否需要缓存状态
    keepAlive() {
      let keepAliveName = '';
      let keepAliveList = this.$router.options.routes.filter(item => {
        if(item.meta === undefined){
          return false
        }
        return !item.meta.keepAlive;
      });
      keepAliveList.forEach(item => {
        keepAliveName = item.name + ',' + keepAliveName;
      });
      return keepAliveName;
    },
    shadowPadding() {
      return ['/otherWayPay','/brazilPay','/phpPayment'].includes(this.$route.path);
    },
    clearPadding() {
      return ['/otherWayPay','/brazilPay','/phpPayment'].includes(this.$route.path);
    },

    //商户信息
    ...mapState('buyCryptoMerchant',['merchantInfo']),
  },
  watch: {
    // '$store.state.LanguageIsShow': {
    //   immediate: true,
    //   handler(newVal) {
    //     this.LanguageShow = newVal;
    //   },
    //   deep: true
    // },
    routerViewState(newVal) {
      this.$store.state.menuState = !newVal;
    }
  },
  mounted() {
    console.log(`%c version %c ${require('../package.json').version} `, 'background:#35495e ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff', 'background:#41b883 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff');

    // 窗口滚动监听
    window.addEventListener('scroll', this.handleScroll, true);
    this.obtainWidth();
    // let innerHight = document.documentElement.clientHeight || document.body.clientHeight;
    // let innerWidth = document.documentElement.clientWidth || document.body.clientWidth
    // window.addEventListener('resize',()=>{
    //   if(innerWidth < 791)
    //     this.$refs.viewApp.style.height = (innerHight ) + 'px'
    //   else
    //     return false
    // })

    //如果首页刷新清空首页基本数据
    // if(this.$route.path === '/'){
    //   this.$store.commit("faitBuyPage/restoreDefault");
    // }
    //存储重新复制store数据
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))));
    }
    //api商户进入关闭当前浏览器清空商户登陆信息
    let beginTime = 0; //开始时间
    let differTime = 0; //时间差
    window.onunload = function() {
      differTime = new Date().getTime() - beginTime;
      let buyMerchantInfo = JSON.parse(sessionStorage.getItem('buyMerchantInfo'));
      if (differTime <= 1 && buyMerchantInfo && buyMerchantInfo.merchantParam_state === true && buyMerchantInfo.type === 'api') {
        window.localStorage.getItem('token') ? window.localStorage.removeItem('token') : '';
        window.localStorage.getItem('token') ? window.localStorage.removeItem('sign') : '';
        window.localStorage.getItem('token') ? window.localStorage.removeItem('email') : '';
        window.localStorage.getItem('token') ? window.localStorage.removeItem('fin_token') : '';
        window.localStorage.getItem('token') ? window.localStorage.removeItem('login_email') : '';
        window.localStorage.getItem('token') ? window.localStorage.removeItem('userId') : '';
        window.localStorage.getItem('token') ? window.localStorage.removeItem('fingerprint_id') : '';
        window.localStorage.getItem('token') ? window.localStorage.removeItem('userNo') : '';
      }
    };
    //pagehide防止移动端beforeunload不能触发
    window.addEventListener('pagehide', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state));
    });
    window.addEventListener('beforeunload', () => {
      beginTime = new Date().getTime();
      sessionStorage.setItem('store', JSON.stringify(this.$store.state));
    });
  },
  methods: {
    //动态获取屏幕大小计算rem
    obtainWidth() {
      this.version = require('../package.json').version;
      this.changeRem(document.body.clientWidth);
      window.onresize = () => {
        return (() => {
          this.changeRem(document.body.clientWidth);
        })();
      };
    },
    changeRem(width) {
      setTimeout(() => {
        if (width < 791) {
          //991
          common.uiSize = 375;
          common.equipmentEnd = 'phone';
          remSize();
          this.logoState = false;
          this.$store.state.isPcAndPhone = 'phone';
          return;
        }
        common.uiSize = width + 100;
        common.equipmentEnd = 'pc';
        remSize();
        this.logoState = true;
        this.$store.state.isPcAndPhone = 'pc';
      });
    },
    goHome() {
      //存在商户订单禁止点击logo跳转
      if (this.merchantInfo.merchantStatus === true) {
        return;
      }
      this.AccountisShow = false;
      if (this.$route.path === '/index' && this.LanguageShow === true) {
        this.$children[1].menuState = false;
        this.$store.state.LanguageIsShow = false;
        return;
      }
      if (this.$route.path === '/index') {
        this.$children[1].menuState = false;
        return;
      }
      if (this.$route.path !== '/index' && this.routerViewState === false) {
        this.routerViewState = true;
        this.$router.push('/');
        return;
      }
      this.$router.push('/');
    },

    //监听页面滚动给footer设置阴影
    handleScroll(e) {
      // let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (e) {
        this.$store.state.scrollState = true;
        setTimeout(() => {
          this.$store.state.scrollState = false;
        }, 1000);
      }
    },
  }
};
</script>

<style lang="scss" src="@/assets/css/app.scss"></style>
