<template>
  <div id="buyCrypto">
    <!-- 买币功能模块 -->
    <div class="buyCrypto_content">
      <div class="methods_select cursor" :class="{'inputFocus': inputFocus}" style="padding-top:.1rem ">
        <div class="methods_select-left" >
          <div class="form_title pay_title">{{ $t('homePage.youSell') }}</div>
          <van-field class="pay_input" type="number" v-model.number="payAmount" @focus="inputFocus=true" @blur="inputFocus=false" @input="inputChange" :disabled="payAmountState" pattern="[0-9]*" inputmode="decimal" placeholder="0.00"/>
        </div>
        <div class="get_company" @click="openSearch('currency-sell')">
          <div class="getImg networkImg">
            <img :src="currencyData.icon">
            <div class="networkIcon" v-if="currencyData.sellNetwork && currencyData.sellNetwork.logo"><img :src="currencyData.sellNetwork.logo"></div>
          </div>
          <div class="getText">{{ currencyData.name }}</div>
          <div class="rightIcon"><img src="@/assets/images/homeRight-icon.png"></div>
        </div>
      </div>
      <div class="methods_select cursor get_methods_select">
        <div class="methods_select-left">
          <div class="form_title">{{ $t('homePage.buyFee_title1') }}</div>
          <div class="get_input">
            <span v-if="getAmount!==''">{{ getAmount }}</span>
            <span class="no_getAmount" v-else>0.00</span>
          </div>
        </div>
        <div class="warning_text" v-if="warningTextState" v-html="payAmount_tips"></div>
        <div class="get_company" @click="openSearch('payCurrency-sell')">
          <div class="getImg"><img :src="positionData.positionImg"></div>
          <div class="getText">{{ payCommission.code }}</div>
          <img class="rightIcon" src="@/assets/images/homeRight-icon.png">
        </div>
      </div>

      <!-- 费用模块 -->
      <div class="calculationProcess" v-if="detailedInfo_state">
        <IncludedDetailsSell v-show="payAmount_tips===''"  ref="includedDetails_ref" :isHome="true"/>
      </div>
    </div>

    <footer>
      <button class="continue" @click="nextStep" :disabled="!continueState" :class="{'continue_true': continueState}">
        {{ $t('homePage.bottomTips1') }} {{ currencyData.name }}
        <img class="rightIcon" src="../../../assets/images/button-right-icon.svg" alt="" v-if="lodingStatus">
        <van-loading class="rightIcon" type="spinner" color="#fff" v-else/>
      </button>
      <div class="footer_logoView">
        <p class="logoText">Powered By</p>
        <div class="logo">
          <img src="@/assets/images/homePageLogo3.png" alt="">
<!--          <img src="../../../assets/images/homePageLogo.png" alt="">-->
<!--          <img src="../../../assets/images/homePageLogo1.png" alt="">-->
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import common from "../../../utils/common";
import IncludedDetailsSell from '../../../components/IncludedDetails/IncludedDetails-sell';
import { hashQuery } from "@/utils/publicRequest";

export default {
  name: "sellCrypto",
  components: { IncludedDetailsSell },
  props: ['allBasicData'],
  data(){
    return{
      //you pay Prompt information
      warningTextState: false,

      //All data
      basicData: {},

      //Location data
      positionData: {
        positionValue: '',
        positionImg: '',
        alpha2: '',
        code: ''
      },

      payAmount: '',
      getAmount: '',

      //Expense information
      feeInfo: {},
      detailedInfo_state: false,

      //you get Currency information
      currencyData: {
        icon: '',
        name: '',
        maxSell: '',
        minSell: '',
        cryptoCurrencyNetworkId: '',
        symbol: ''
      },

      //Payment method information
      payCommission: {},
      allPayCommission: [],

      triggerType: "hover",

      inputFocus: false,
      lodingStatus:true,
      //接收商户数据
      accessMerchantInfo:{},
      //商户携带参数输入框状态
      detailedInfo_cryptoAmount: false,

      cryptoSate: false,
      //控制国家可选择
      fiatSate: false,
      //法币兑换美元的金额加按钮禁用
      // exchange:false
    }
  },

  mounted(){
    //配置商户获取携带的加密货币值
    // this.accessMerchantInfo = JSON.parse(sessionStorage.getItem('buyMerchantInfo'))
    // if(this.accessMerchantInfo.cryptoAmount){
    //     this.payAmount = this.accessMerchantInfo.cryptoAmount
    // }
  },
  computed: {
    //you pay Input status - Data can only be entered after loading
    payAmountState(){
      //商户配置输入框禁用
      // if(this.accessMerchantInfo.cryptoAmount){
      //   return true
      // }
      //this.currencyData.maxSell > 0 && this.currencyData.minSell > 0 &&
      if( !this.detailedInfo_cryptoAmount){
        return false
      }else{
        return true
      }
    },
    //确认按钮状态
    continueState(){
      //取消最大最小值
      //&& Number(this.payAmount) >= this.currencyData.minSell &&
      //Number(this.payAmount) <= this.currencyData.maxSell
      if((this.positionData.positionValue !== ''&&
          this.payAmount !== ''  && this.getAmount !== '' && this.payAmount_tips === '' &&
          Number(this.payAmount) > 0 ) && this.lodingStatus ){
        //增加loding效果
        return true
      }else{
        return false
      }
    },
    payAmount_tips(){
      //单个商户配置最大金额
      const appId = 'mep88Oysd18jMhOr'
      let UsdMax = JSON.parse(sessionStorage.getItem('buyMerchantInfo'))
      var minError
      let fixedCode = this.feeInfo.rateUsd
      //获取60美元换算成法币是多少
      let minamountUsd = (80 * fixedCode)
      //单个商户配置最大金额75000美元
      let maxamountUsd = (UsdMax && UsdMax.appId &&UsdMax.appId === appId)?(75000 * fixedCode):(10000 * fixedCode)
      //查看换算的是否有小数点 如果有小数位直接+1否则则下一步
      var result = (minamountUsd.toString()).indexOf(".");
      var result1 = (maxamountUsd.toString()).indexOf(".");
      if(result != -1) {
        minamountUsd = Number((minamountUsd.toString()).substring(0,result)) + 1
      }
      if(result1 != -1) {
        maxamountUsd = Number((maxamountUsd.toString()).substring(0,result1)) + 1
      }
      if(Number(this.payAmount) === '' && Number(this.payAmount) <= 0){
        return ' '
      }
      if(Number(this.payAmount) >0 &&  Number(this.getAmount) < minamountUsd){
        minError = `${this.$t('homePage.sellminAmount')} ${minamountUsd} ${this.$store.state.sellRouterParams.positionData.code}.`
        return minError;
      }else if(Number(this.payAmount) > 0 && Number(this.getAmount) !== '' && Number(this.getAmount) > maxamountUsd){
        // this.detailedInfo_state = false;
        minError = `${this.$t('homePage.sellmaxAmount')} ${maxamountUsd} ${this.$store.state.sellRouterParams.positionData.code}.`
        return minError;
      }else{
        return ''
      }
    },
  },
  watch: {
    allBasicData: {
      immediate: true,
      deep: true,
      handler() {
        let state = sessionStorage.getItem("merchantType") && sessionStorage.getItem("merchantType") === 'buy' && this.$store.state.tableState === true;
        if(state){
          return
        }
        this.allBasicData.worldList !== undefined ? this.currentLocation() : '';
      },
    },
    payAmount: {
      deep: true,
      handler(newVal,oldVal) {
        if((newVal && oldVal && newVal > 0 && oldVal > 0 && newVal !== oldVal) || newVal === '' || newVal <= 0){
          this.getAmount = ''
        }
        this.amountControl();
      }
    },
  },
  methods: {
    //选择法币或加密货币
    openSearch(view){
      //存在商户无法切换法币、加密货币
      if(view === 'currency-sell' && this.cryptoSate === false){
        return;
      }
      if(view === 'payCurrency-sell' && this.fiatSate === false){
        return;
      }
      this.$parent.openSearch(view);
    },

    //限制输入六位小数
    inputChange(val){
      if(val.indexOf('.') > 0){
        this.payAmount = val.substr(0,val.indexOf('.')+7);
      }
    },

    //Process the quantity and display status of received legal currency
    amountControl(){
      if( Number(this.payAmount) === '' && Number(this.payAmount) < 0 ){
        this.warningTextState = false;
        this.exchange = false
        return;
      }
      //Purchase amount prompt
      if (Number(this.payAmount) !== '' && Number(this.payAmount) > 0){
        this.$store.state.sellRouterParams.amount = this.payAmount;
        //How many digital currencies can I exchange
        this.payinfo();
      }else{
        this.warningTextState = false;
        this.detailedInfo_state = false;
      }
    },

    //Purchase information details - Scheduled refresh
    payinfo(){
      if (Number(this.payAmount) ){
        this.detailedInfo_state = true;
        setTimeout(()=>{
          document.getElementById("buyCrypto").scrollIntoView({behavior: "smooth", block: "end"});
        })
      }else{
        this.detailedInfo_state = false;
      }
    },

    //基本数据处理
    currentLocation(){
      let state = sessionStorage.getItem("buyMerchantInfo") && JSON.parse(sessionStorage.getItem("buyMerchantInfo")).showTable === 'buy';
      if(state){
        return
      }
      if(JSON.stringify(this.allBasicData) !== '{}'){
        //提示效果判断
        this.triggerType = common.equipmentEnd === 'pc' ? "hover" : "click";

        this.basicData = this.allBasicData;

        //卖币法币国家处理 - 将法币和国家数据合并在一起
        this.basicData.worldList.forEach((item,index)=>{
          if(item.sellFiatList){
            item.sellFiatList.forEach((item2,index2)=>{
              this.basicData.fiatCurrencyList.forEach(item3=>{
                if(item3.code === item2){
                  this.basicData.worldList[index].sellFiatList[index2] = item3;
                }
              })
            })
          }
        })
        if(this.basicData.sellRecentWorldList){
          this.basicData.sellRecentWorldList.forEach((item,index)=>{
            if(item.sellFiatList){
              item.sellFiatList.forEach((item2,index2)=>{
                this.basicData.fiatCurrencyList.forEach(item3=>{
                  if(item3.code === item2){
                    this.basicData.sellRecentWorldList[index].sellFiatList[index2] = item3;
                  }
                })
              })
            }
          })
        }

        //判断是否需要走卖币商户逻辑 - 不是卖币商户默认数据处理
        let merchantType = sessionStorage.getItem("merchantType");
        if(merchantType !== 'sell'){
          //默认法币、国家、数字货币、网络
          this.basicDataDefault(true,true)
          this.fiatSate = true;
        }
      }
    },

    //加密币、法币、国家默认数据
    /**
     * @param legalCurrencyWorld - 法币国家是否默认
     * @param currencyNetwork - 加密币网络是否默认
     */
    basicDataDefault(legalCurrencyWorld,currencyNetwork){
      //获取定位的国家信息
      if(legalCurrencyWorld === true){
        // this.fiatSate = true;
        var worldData = {};
        worldData = this.basicData.worldList.filter((item)=> {
          if(item.alpha2 === this.basicData.param.areaCode){
            return item;
          }
        })
        //定位国家法币不可卖，默认第一个可以卖的国家法币
        if(worldData[0].sellEnable === 0){
          worldData = this.basicData.worldList.filter(item=>{return item.sellEnable === 1});
        }
        this.handlePayWayList(worldData[0],1);
      }
      if(currencyNetwork === true){
        this.cryptoSate = true;
        this.handleDigitalCurrency({},1)
      }
    },
    //处理数字货币数据
    handleDigitalCurrency(data,state){
      // state - 1初始化数据处理 state - 2对选择额数据处理
      if(state === 1){
        let currencyData_default = [];
        currencyData_default = this.basicData.cryptoCurrencyResponse.cryptoCurrencyList.filter(item=>{return item.name === "BTC"})[0];
        if(currencyData_default === undefined || currencyData_default.isSell !== 1){
          currencyData_default = this.basicData.cryptoCurrencyResponse.cryptoCurrencyList.filter(item=>{return item.isSell===1})[0];
        }
        if(currencyData_default) {
          this.currencyData = {
            icon: currencyData_default.logoUrl,
            name: currencyData_default.name,
            maxSell: currencyData_default.sellNetworkList[0].maxSell,
            minSell: currencyData_default.sellNetworkList[0].minSell,
            cryptoCurrencyNetworkId: currencyData_default.cryptoCurrencyNetworkId,
            sellNetwork: currencyData_default.sellNetworkList[0]
          }
          this.$store.state.feeParams.symbol = currencyData_default.symbol; //name -- popularList币种
          this.$store.state.feeParams_order.symbol = currencyData_default.symbol; //name -- popularList币种
          this.$store.state.sellRouterParams.cryptoCurrency = currencyData_default.name;
          this.$store.state.sellRouterParams.currencyData = this.currencyData;
        }
      }

      //商户携带amount金额
      // if(sessionStorage.getItem("buyMerchantInfo") && JSON.parse(sessionStorage.getItem("buyMerchantInfo")).fiat && JSON.parse(sessionStorage.getItem("buyMerchantInfo")).fiatAmount){
      //   this.payAmount = JSON.parse(sessionStorage.getItem("buyMerchantInfo")).fiatAmount
      //   this.detailedInfo_cryptoAmount =  true
      // }else{
      //   //页面初始化 - 买币预设初始法币金额是300刀等值法币
      //   let exchangeRate = this.basicData.usdToEXR[payCommission.code];
      //   this.payAmount = 300 * exchangeRate;
      // }
    },
    // 根据国家对应的币种处理数据
    handlePayWayList(data,state){
      // state - 1初始化数据处理 state - 2对选择额数据处理
      if(state === 1){
        this.payCommission = data.sellFiatList[0];
        this.positionData.code = data.sellFiatList[0].code;
      }else{
        data.sellFiatList.forEach(item=>{
          if(item.code === data.code){
            this.payCommission = item;
          }
        })
      }
      //展示所需国家参数
      this.positionData = {
        positionValue: data.enCommonName,
        positionImg: data.flag,
        alpha2: data.alpha2,
        code: this.payCommission.code,
        enCommonName: data.enCommonName,
      };
      //费用所需参数
      this.$store.state.sellRouterParams.payCommission = this.payCommission;
      this.$store.state.feeParams.fiatCode = this.payCommission.code;
      this.$store.state.feeParams.alpha2 = data.alpha2;
      this.$store.state.feeParams_order.fiatCode = this.payCommission.code;
      this.$store.state.feeParams_order.alpha2 = data.alpha2;
      this.positionData.worldId = data.worldId;
      this.$store.state.sellRouterParams.positionData = this.positionData;
      this.$store.state.sellRouterParams.formPositionData = this.positionData;
      this.amountControl();
    },

    //卖币接入商户信息接收、验证
    merchantInformationAssignment(res,merchantParams){
      let state = sessionStorage.getItem("buyMerchantInfo") && JSON.parse(sessionStorage.getItem("buyMerchantInfo")).showTable === 'buy';
      if(state){
        return
      }
      //判断是否需要走卖币商户逻辑
      let merchantType = sessionStorage.getItem("merchantType");
      if(merchantType === 'sell'){
        //商户信息接口success创建订单添加merchantParam参数
        if(res && res.returnCode === "0000" && res.data){
          // this.fiatSate = true;
          this.cryptoSate = merchantParams.crypto ? false : true;
          merchantParams.merchantParam = JSON.stringify(hashQuery().query) !== "{}" ? hashQuery().fullPath : JSON.parse(sessionStorage.getItem("buyMerchantInfo")).merchantParam;
          merchantParams.merchantParam_state = true;
          sessionStorage.setItem("buyMerchantInfo",JSON.stringify(merchantParams));

          //匹配商户携带加密币和网络
          if(merchantParams && merchantParams.crypto){
            let merchantCurrency = this.basicData.cryptoCurrencyResponse.cryptoCurrencyList.filter(item=>{return item.name === merchantParams.crypto})[0];
            let merchantNetwork = merchantParams.networkDefault ? merchantCurrency.sellNetworkList.filter(item=>{return item.network === merchantParams.network})[0] : merchantCurrency.sellNetworkList[0];
            this.currencyData = {
              icon: merchantCurrency.logoUrl,
              name: merchantCurrency.name,
              maxSell: merchantCurrency.sellNetworkList[0].maxSell,
              minSell: merchantCurrency.sellNetworkList[0].minSell,
              cryptoCurrencyNetworkId: merchantCurrency.cryptoCurrencyNetworkId,
              sellNetwork: merchantNetwork
            }
            this.$store.state.feeParams.symbol = merchantCurrency.symbol; //name -- popularList币种
            this.$store.state.feeParams_order.symbol = merchantCurrency.symbol; //name -- popularList币种
            this.$store.state.sellRouterParams.cryptoCurrency = merchantCurrency.name;
            this.$store.state.sellRouterParams.currencyData = this.currencyData;
          }else{
            //没有匹配到默认数据
            this.handleDigitalCurrency({},1)
          }

          //法币匹配国家 - 则匹配第一个支持法国  如果商户传USD则匹配美国
          if(merchantParams.fiat && !merchantParams.country){
            let fiat = this.basicData.worldList.filter(item=>{
              if(merchantParams.fiat==='USD'){
                if(item.alpha2==='US'){
                  return item.sellFiatList.find(item1=>{
                    return item1.code == merchantParams.fiat
                  })
                }
                return
              }
              if(merchantParams.fiat==='EUR'){
                if(item.alpha2==='FR'){
                  return item.sellFiatList.find(item1=>{
                    return item1.code == merchantParams.fiat
                  })
                }
                return
              }
              return item.sellFiatList.find(item1=>{
                return item1.code == merchantParams.fiat
              })
            })
            if(fiat[0]){
              this.handlePayWayList(fiat[0],1)
              // this.fiatSate = false;
            }
          }else if(merchantParams && merchantParams.country){ //匹配法币国家
            if(!merchantParams.fiat && merchantParams.country || (merchantParams.fiat && merchantParams.country)){
              let country = this.basicData.worldList.filter(item=>{return item.alpha2==merchantParams.country && item.sellEnable === 1})[0]
              this.handlePayWayList(country,1)
              // this.fiatSate = false;
            }
          }else{
            //默认法币国家
            this.basicDataDefault(true,false)
            this.fiatSate = true;
          }

          //商户接入如果携带值时付给页面并且无法更改
          if(merchantParams && merchantParams.crypto && merchantParams.cryptoAmount){
            this.payAmount = merchantParams.cryptoAmount
            this.detailedInfo_cryptoAmount =  true
          }
          this.merchantStatusManagement(res,merchantParams);
        }else{
          this.merchantStatusManagement(res,merchantParams);
          //默认法币、国家、数字货币、网络
          this.basicDataDefault(true,true)
        }
      }
    },

    //商户状态管理
    /**
     * @param res - 商户校验参数回调
     * @param merchantParams - 需要存储商户信息
     */
    merchantStatusManagement(res,merchantParams){
      //商户信息有效、network和address参数无效
      if(res && res.returnCode === "0000" && res.success === true && res.data === null){
        merchantParams.addressDefault = false;
        merchantParams.networkDefault = false;
        sessionStorage.setItem("buyMerchantInfo",JSON.stringify(merchantParams));
        return;
      }

      //商户信息有效、network｜address参数有效
      if(res && res.returnCode === "0000" && res.success === true && res.data !== null){
        //address: false - Address is not brought out by default
        if(res.data.address === false || res.data.address === undefined){ //No parameter defaults
          merchantParams.addressDefault = false;
        }else{
          merchantParams.addressDefault = true;
        }

        //network: false - The network is not brought out by default
        if(res.data.network === false || res.data.network === undefined) { //No parameter defaults
          merchantParams.networkDefault = false;
        }else{
          merchantParams.networkDefault = true;
        }
        sessionStorage.setItem("buyMerchantInfo",JSON.stringify(merchantParams));
        return;
      }

      //商户信息无效
      this.fiatSate = true;
      this.cryptoSate = true;
      merchantParams.addressDefault = false;
      merchantParams.networkDefault = false;
      merchantParams.merchantParam_state = false;
      sessionStorage.setItem("buyMerchantInfo",JSON.stringify(merchantParams));
    },

    nextStep(){
      //是否是从菜单进入
      this.$store.commit('changeRouterQueryPath',false)
      this.lodingStatus = false
      //返回首页情况菜单栏状态
        this.$store.state.routerViewState = true
      //清空邮件单页面跳转状态
      this.$store.state.emailFromPath = '';

      //跳转填写卡信息
      this.$store.state.homeTabstate = 'sellCrypto';
      this.$store.state.cardInfoFromPath = 'configSell';

      delete this.$store.state.sellForm;
      delete this.$store.state.sellRouterParams.cardInfoList;
      //是否为风险
      if(localStorage.getItem("token")){
        // this.$axios.post(this.$api.post_kycDisabled,'','').then(res=>{
        //   if(res && res.data){
        //     //KYC失败次数过多 此账号为风险账号
        //     if(res.data){
        //       this.$parent.$parent.AccountisShow = true
        //         this.lodingStatus = true

        //     }else{

        //     }
        //   }
        // })
        this.$router.push('/sell-formUserInfo');
          setTimeout(() => {
            this.lodingStatus = true
          }, 1000);
      }else{

        this.$store.state.emailFromPath = 'sellCrypto';
        this.$router.push('/emailCode');
         setTimeout(() => {
            this.lodingStatus = true
          }, 1000);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
html,body,#buyCrypto{
  width: 100%;
  height: 100%;
}
#buyCrypto{
  display: flex;
  flex-direction: column;
  .buyCrypto_content{
    flex: 1;
    overflow: auto;
  }
}

.form_title{
  font-family: Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 0.13rem;
  color: #031633;
}

.methods_title{
  margin-top: 0.2rem;
}
.methods_select{
  min-height: .8rem;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 0.06rem;
  padding: 0 0.16rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  &:nth-of-type(2){
    margin-top: 0.12rem;
  }
}
.inputFocus{
  border: 1px solid #41B8FD;
  box-shadow: 0 0 0.35rem rgba(89, 153, 248, 0.2);
}

.get_methods_select{
  min-height: 1rem;
  display: flex;
  overflow: hidden;
  .methods_select-left{
    margin-top: -.08rem;
  }
  .get_company{
    margin-top: 0;
    margin-top: -.08rem;
  }
}


.pay_input{
  width: 1.4rem;
  border: none;
  outline: none;
  font-family: Medium;
  font-weight: 500;
  font-size: 0.29rem;
  color: #0047AD;
  padding: 0;
  margin: 0.06rem 0 .04rem 0;
  &::placeholder{
    color: #C2C2C2 !important;
  }
}

.warning_text{
  position: absolute;
  bottom: 0.08rem;
  left: 0.18rem;
  font-family: Regular;
  font-weight: 400;
  font-size: 0.13rem;
  color: #FF3333;
  line-height: 0.13rem;
}

.methods_select-left{
  margin-top: -0.1rem;
}
.get_input{
  width: 1.8rem;
  height: 0.28rem;
  overflow: auto;
  font-family: Medium;
  font-weight: 500;
  font-size: 0.28rem;
  line-height: 0.28rem;
  color: #031633;
  margin-top: 0.06rem;
  .no_getAmount{
    color: #C2C2C2;
  }
}
.get_company{
  margin-left: auto;
  margin-top: -0.1rem;
  padding: 0 0.12rem;
  min-width: 1.2rem;
  height: 0.46rem;
  background: #F7F8FA;
  border: 1px solid #EEEEEE;
  border-radius: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Regular;
  cursor: pointer;
  .networkImg{
    margin-right: 0.1rem!important;
  }
  .getImg{
    display: flex;
    margin-right: 0.1rem;
    width: 0.24rem;
    min-height: 0.24rem;
    background: #94ACBA;
    border-radius: 50%;
    position: relative;
    img{
      width: 0.24rem;
      border-radius: 50%;
    }
    .networkIcon{
      background: #FFFFFF;
      width: 0.16rem;
      height: 0.16rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 0;
      right: -0.06rem;
      img{
        width: 0.12rem;
        height: 0.12rem;
        border-radius: 50%;
      }
    }
  }
  .getText{
    display: flex;
    font-family: Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 0.13rem;
    color: #063376;
    min-width: 0.28rem;
  }
  .rightIcon{
    display: flex;
    align-items: center;
    width: 0.24rem;
    margin-left: auto;
    img{
      width: 0.24rem;
    }

  }

}


footer{
  .footer_logoView{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.08rem;
    font-family: Regular;
    font-weight: 400;
    font-size: 0.13rem;
    color: #94ACBA;
    .logoText{
      margin-right: 0.12rem;
      margin-top: 0.02rem;
    }
    .logo{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 0.8rem;
      img:first-child{
        width: .9rem;
      }
    }
  }
}
.continue{
  width: 100%;
  height: 0.58rem;
  background: linear-gradient(88.06deg, #0059DA 0%, #1BB2F2 100%);
  opacity: 0.25;
  border-radius: 0.29rem;
  font-size: 0.17rem;
  font-family: Regular;
  font-weight: normal;
  color: #FFFFFF;
  margin-top: 0.16rem;
  cursor: no-drop;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.12rem;
  .rightIcon{
    width: 0.2rem;
    margin-left: 0.08rem;
  }
  .rightIcon{
    span{
      height: .18rem;
    }

  }
}
.continue_true{
  opacity: 1;
  cursor: pointer;
}

.cursor{
  cursor: auto;
}

.calculationProcess{
  margin-top: 0.08rem;
  margin-bottom: 0.1rem;
}

@keyframes loadingIcon {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (prefers-reduced-motion: no-preference) {
  .loadingIcon {
    animation: loadingIcon infinite 2s linear;
  }
}

.pay_input ::v-deep .van-cell__value--alone{
  min-height: 0.28rem;
}
.pay_input ::v-deep .van-field__control{
  min-height: 0.28rem;
  border: none;
  outline: none;
  font-size: 0.28rem !important;
  font-family: Medium;
  font-weight: 500;
  color: #0047AD !important;
  &::placeholder{
    color: #C2C2C2 !important;
  }
}
.pay_input ::v-deep .van-field__control:disabled{
  color: #0059DA !important;
  -webkit-text-fill-color: #0059DA !important;
}
</style>
