import Vue from 'vue';

//注册全局组件 - components
import Button from '@/components/Button/Button.vue'
Vue.component('Button', Button)

//ui - element
import 'element-ui/lib/theme-chalk/index.css';
import { Popover,InfiniteScroll,Checkbox } from 'element-ui';
Vue.use(Popover);
Vue.use(InfiniteScroll);
Vue.use(Checkbox);
