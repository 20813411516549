module.exports = {
  language: 'Español',
  //首页
  homePage: {
    tab_buy: 'Comprar Cripto',
    tab_sell: 'Vender Cripto',
    //买币
    youPay: 'Usted paga',
    youBuyGet: 'Usted obtiene',
    buyFee_title1: 'Usted obtiene',
    //卖币
    youSell: 'Usted vende',
    youSellGet: 'Usted obtiene',
    sellFee_title1: 'Usted vende',
    sellFee_title2: 'A',
    sellFee_rampFee: 'Tarifa de Ramp',

    minAmountTips: 'El importe mínimo de la transacción es',
    maxAmountTips: 'El importe máximo de la transacción es',
    sellminAmount: 'Importe mínimo de venta',
    sellmaxAmount: 'Importe máximo de venta',
    bottomTips1: 'Proceder · Vender',
    bottomTips2: 'Proceder · Comprar',
    //钱包地址验证页的提示
    receivingMode: 'Por favor, asegúrese de que la dirección que ha introducido es correcta antes de confirmar la transferencia. No envíe fichas a la dirección de otro. Una dirección incompatible causará el fracaso del pedido o la pérdida de fondos'
    // bottomTips2: 'Con el respaldo técnico de',
  },
  //买币表单新增文案
  sellFormPageTitle: {
    Information: 'Utiliza esta información',
    AccountNumber: 'Número de cuenta',
    More: 'Más',
    Attention: 'Atención',
    ReturnediInformation: 'Por favor, asegúrese de que la cuenta bancaria le pertenece y de que la información es correcta. Las transacciones devueltas están sujetas a una tarifa de 25 dólares que cobran nuestros socios bancarios.'
  },
  worldCuo: {
    World: 'Copa Mundial de Catar 2022',
    discount: '50% descuento',
    Rampfee: 'primer pedido Ramp tarifa ',
    Details: 'Detalles',
    WorldCup: 'Copa Mundial',
    WorldDiscount: 'Descuento en la Copa Mundial',
    Participated: 'Participado',
    order: 'en este pedido',
    countdown: 'Cuenta atrás del evento',
    countdownstart: 'El evento está a punto de comenzar'
  },
  //导航文案
  navTabTitle:{
    Completepayment:'Completa el pago',
  },

//墨西哥支付文案
  mexPayment:{
    tiemOut:'El pedido será cancelado en',
    PleasePay:'Por favor pague',
    title1:'Abre tu solicitud bancaria. Introduce este número de referencia',
    title2:'Ingrese la información de registro de la cuenta',
    CLABEinterbancaria:'CLABE interbancaria:',
    Destinationbank:'Banco de destino:',
    Beneficiary:'Beneficiario:',
    Payamount:'Cantidad de pago:',
    title3:'Si su pago no se completa durante mucho tiempo, le sugerimos que cargue los documentos de pago a nuestro servicio de atención al cliente. Esto acelerará nuestra confirmación de su pago.'
  },
  vndChildren: {
    amount:'Cantidad',
    oneTitle:' Vaya a la aplicación bancaria, escanee el código QR o ingrese la identificación del destinatario manualmente',
    twoTitle:' Complete el contenido de la transferencia en el cuadro de mensaje',
    threeTitle:' hacer una transferencia de dinero',
    Accountname:'Nombre para mostrar de la cuenta',
    AccountID:'ID de la cuenta',
    Transfercontent:'Transferir contenido',
  },
  //等待结果组件
  buypayWait: {
    Processing: 'Procensando',
    Waiting: 'Esperando su compra...',
    purchase: 'Por favor, pase a la página de pago para completar su compra.',
    Afterpayment: 'Después de su pago',
    Wepurchase: 'Le enviaremos un correo electrónico al recibir su compra para que pueda encontrarla fácilmente más tarde.',
    historyorder: 'ver el historial de pedidos'
  },

  //菲律宾页面
  phpPayment:{
    PaymentGuide:'Guía de pago',
    BillNumber:'Número de envío digital',
    PAYSO1:'Ingrese a la billetera Gcash, haga clic en "Facturas" y seleccione pago de facturas',
    PAYSO2:'Busca y selecciona "PAYSO"',
    PAYSO3:'complete esta información y haga clic en [SIGUIENTE]',
    Incorrect:'Por favor, asegúrese de que la cantidad que nos transfirió es precisa y correcta. La información o cantidad incorrecta causará el fracaso del pedido o la pérdida de fondos.'
  },
  //买币费用组件
  buyFee_components: {
    codeSecond: 's',
    youGet: 'Usted obtiene',
    title2: 'para',
    rampFee: 'Tarifa de Ramp',
    rampFeeTips: 'tan bajo como',
    networkFee: 'Cuota de red',
    tips: 'Basado en la forma de pago'
  },
  //卖币费用组件
  sellFee_components: {
    codeSecond: 's',
    title1: 'Usted vende',
    title2: 'a',
    rampFee: 'Tarifa de Ramp',
    tips: 'Basado en la forma de pago',
    RampFeeTips: 'tan bajo como'
  },
  //菜单
  menu: {
    home: 'Inicio',
    menu: 'Menú',
    transactions: 'Transacciones',
    language: 'Idioma',
    privacy: 'Política de privacidad',
    logOut: 'Cierre de sesión',
    service: 'Condiciones de servicio',
    RouterMenu_experience: 'Iniciar sesión para desbloquear la experiencia completa',
    login_Youlogged: 'Ya ha iniciado sesión',
    RouterMenu_Welcome: '¡Bienvenido a Alchemy Pay!',
    RouterMenu_history: 'No hay historial todavía',
    loginOut_Dismiss: 'Cancelar',
    loginOut_title: '¿Seguro que quiere cerrar la sesión?'
  },

  //买币接收方式页
  buyReceivingMethod: {
    networkTitle1: 'Introduzca su',
    networkTitle2: 'dirección',
    addressTips1: 'No es una dirección válida',
    addressTips2: 'dirección',
    memo: 'Memo',
    memoTips: 'No es un memo ACH válido.',
    supported: 'dominios admitidos',
    Unstoppable: 'Admitimos dominios imparables para este activo',
    ENSTips: 'Introduzca una dirección de cartera válida, un dominio ENS o no accesible'
  },
  //买币阿根廷支付
  ArgentinaPay: {
    BankCode: 'Codigo bancario',
    ChooseBank: 'Elige tu Banco',
    selectcard: 'Seleccione un banco',
    BankAccount: 'Cuenta bancaria',
    cardnumber: 'Por favor ingrese el número de tarjeta correcto',
    Bankcode: 'codigo bancario',
    correctcode: 'Por favor ingrese el código correcto',
    ID: 'ID',
    SelectBank: 'Seleccionar banco',
    phone: 'número de teléfono',
    phoneTips: 'Numero de telefono invalido',
    paymentResultError: 'La información de la cuenta bancaria es incorrecta. Por favor, compruebe y compre de nuevo.',
    Choose: 'Elija su tipo de cuenta',
    chequing: 'cheques',
    Saving: 'ahorros',
    AccountNumber: 'Número de cuenta',
    fullNameTips: 'Nombre completo inválido',
  },
  //买币选择支付方式页
  buyPayWay: {
    BINANCEPAY:'BINANCE PAY',
    qrCode: 'QR Code',
    Philippines: 'Transferencias bancarias',
    Khipu: 'Transferencias bancarias',
    ThaiQR: 'ThaiQR',
    pix: 'PIX',
    recentlyUsed: 'Método de pago guardado',
    paymentMethod: 'Añadir un nuevo método de pago',
    rampFee: 'Tarifa de Ramp',
    card: 'Tarjeta',
    card1: 'Tarjeta',
    creditCard: 'Tarjeta de crédito',
    bankTransfer: 'Pago inmediato',
    bancoTransfer: 'Transferencia Bancaria',
    opm: 'OPM',
    virtualAccount: 'Transferencias bancarias',
    eWallet: 'Billetera electrónica',
    binancePay: 'BINANCE PAY',
    applePay: 'Apple Pay',
    googlePay: 'Google Pay',
    promptPay: 'Pronto Pago',
    addCardTitle: 'Añadir nueva tarjeta',
    useCardTitle: 'Uso de la nueva tarjeta',
    edit: 'Editar',
    cancel: 'Cancelar',
    support: 'Soporte',
    cardLimitTips: 'Límite de 6 tarjetas alcanzado'
  },

  //买币国际卡表单组件
  buyForm_components: {
    formTitle: 'Introduzca los datos de su tarjeta',
    firstName: 'Nombre',
    lastName: 'Apellido',
    nameTips: '¡Nombre inválido!',
    cardNumber: 'Número de tarjeta',
    cardNumTips: 'El número de tarjeta que introdujo no es válido',
    expirationDate: 'Fecha de caducidad',
    dataTips: 'Fecha de caducidad no válida',
    cvv: 'CVV',
    cvvTips: 'Por favor, introduzca un CVV válido'
  },

  //国际卡确定订单页 - 老变量没有处理
  creditCard: {
    payingWith: 'Pagando con',
    card: 'Tarjeta',
    cvv: 'CVV'
  },

  //买币确定订单页 - 巴西支付
  configPay_brl: {
    phoneNumber: 'Número de teléfono móvil',
    fullName: 'Nombre completo',
    formTitle: 'Introduzca sus datos',
    code_tips: 'Escanear el código QR a través de su PIX para completar el pago',
    name_tips: '¡Nombre inválido!',
    id_tips: '¡Identificación inválida!',
    number_tips: '¡Número de teléfono móvil no válido!',
    Country: 'Seleccionar país / región'
  },

  opmPayment: {
    banco: 'Banco'
  },

  //买币结果页
  payResult: {
    //支付结果提示
    tipsText_40010: 'Los datos de la tarjeta son inexactos. Por favor, compruebe la información de la tarjeta',
    tipsText_40011: 'Los datos de la tarjeta son inexactos. Por favor, compruebe la información de la tarjeta',
    tipsText_40019: 'Los datos de la tarjeta son inexactos. Por favor, compruebe la información de la tarjeta',
    tipsText_40020: 'Fondo insuficiente',
    tipsText_40030: 'La jurisdicción de la tarjeta está restringida. Por favor, intente con otra tarjeta',
    tipsText_40031: 'Tarjeta restringida',
    tipsText_40032: 'Tarjeta anormal',
    tipsText_40033: 'Tarjeta restringida',
    tipsText_40034: 'Tarjeta restringida, control de riesgos',
    tipsText_40090: 'Rechazo anormal'
  },

  //搜索组件
  search_components: {
    recent: 'Reciente',
    availableNow: 'Disponible ahora'
  },
  //弹出框文案
  payConfig_components: {
    title_THB: 'Pago en curso',
    button_THB: 'Revisar el pedido',
    button_timeOut: 'Volver a la página de inicio',
    button_timeOut1: 'Volver al inicio',
    double: 'Por favor, no haga un pago doble',
    completed: 'Por favor, confirme que su transferencia ha sido completada',
    timeorder: 'Este pedido está fuera de plazo',
    checkout: '¿Está seguro de dejar la caja?',
    buttonLeave: 'Salir ahora'
  },

  //按钮组件
  button_components: {
    proceed: 'Proceder'
  },

  //选择网络组件
  network_components: {
    network: 'Elija la red'
  },

  //---------------------------- 支付方式信息组件 ----------------------------
  PayMethodInfo_components: {
    title: 'Resumen del pedido',
    title2: 'Su método de pago elegido es',
  },
  //---------------------------- 扫码QR Code组件 ----------------------------
  QrCode_components: {
    title1: 'Pagar',
    title2: 'para completar tu compra.',
    title3: 'Abra su aplicación de pago y escanee el código QR a continuación para completar el pago.',
    copy: 'COPIAR',
  },
  //---------------------------- 钱包地址和网络信息组件 ----------------------------
  WalletAddress_components: {
    title1: 'Su',
    title2: 'cartera',
  },
  //---------------------------- 表单信息 ----------------------------
  formInfo: {
    //新增
    fullName_placeholder: 'Ingrese el nombre completo',
    phone_title: 'Por favor, introduzca su número de teléfono',
    id_title: 'Ingrese el número de identificación',
    id_placeholder1: 'Introducir el',
    id_placeholder2: 'número de identificación de dígitos',
    bankAccount_placeholder: 'Elija su tipo de cuenta',
    accountNumber_placeholder: 'Ingrese el número de identificación de 22 dígitos',
    bankCode_placeholder: 'Ingrese el número de identificación de 3 dígitos',
    //vnd 选择bank code
    vnd_bankCode_placeholder: 'Elige el código del banco',
  },

  /**
   * 卖币
   */
  //卖币支付页+订单状态页
  sellOrderFonts: {
    minimumAmount: 'Su pedido está por debajo de su importe mínimo',
    maximumAmount: 'Su orden es superior a su importe máximo',
    sellTitle: 'Vender',
    Sellorder_Network: 'Elija la red',
    Sellorder_Ramp: 'Tarifa de Ramp',
    Sellorder_PleaseCorrect: 'Por favor, asegúrese de que la red seleccionada es correcta para evitar la pérdida de activos',
    Sellorder_transferred: 'He transferido el cripto a la dirección y red indicadas',
    Sellorder_ButtonNot: 'Todavía no',
    Sellorder_ButtonConfirm: 'Confirmar',
    Sellorder_ButtonHaveSent: ' He enviado el ',
    Sellorder_ButtonGoHome: '  Volver a la página de inicio',
    Sellorder_ButtongoHistory: 'Historial de pedidos',
    Sellorder_timeOutTitle: ' Su pedido ha caducado, por favor no envíe dinero a esta dirección, de lo contrario el activo puede perderse',
    Sellorder_StatusTitle: 'Puede abandonar esta página. Las actualizaciones del pedido se enviarán a su correo electrónico y pueden comprobarse en el historial de pedidos.',
    Sellorder_StatusNav: 'Estado del pedido',
    Sellorder_StatusCryptoSent: 'Cripto enviado',
    Sellorder_StatusBlockConfirmed: 'Bloqueo confirmado',
    Sellorder_StatusConfirmOrder: 'Pedido confirmado',
    Sellorder_StatusOrderconfirmed: 'Su pedido ha sido confirmado',
    Sellorder_StatusInTransfer: 'En transferencia',
    Sellorder_StatusInTransferfiat: 'Su fiat está en transferencia',
    Sellorder_StatusResult: 'Resultado',
    Sellorder_StatusResultCompleted: 'Pedido completado',
    Sellorder_StatusResultfail: 'Pedido fallido',
    //卖币状态
    Sellorder_transfer: 'Por favor, transfiera ',
    Sellorder_within: 'a la dirección dentro de ',
    Sellorder_will: 'va a ',
    Sellorder_get: 'obtener ',
    Sellorder_for: ' para ',
    Sellorder_Id: 'ID del pedido',
    Sellorder_Address: 'Dirección',
    Sellorder_Fail: 'Causa del fallo',
    Sellorder_You: 'Vende',
    Sellorder_to: 'a',
    SellOrder_modified: 'La información de la tarjeta no se puede modificar en este momento'
  },

  //卖币表单页
  sellForm: {
    tips: 'consejos',
    fullName: 'Nombre completo',
    lastName: 'Apellido',
    NameTips: '¡Nombre inválido!',
    Address: 'Dirección',
    AddressTips: '¡Dirección inválida!',
    AddressMultinomialTips: 'La dirección no puede ser nula',
    City: 'Ciudad',
    CityTips: '¡Ciudad inválida!',
    State: 'Estado',
    StateTips: '¡Estado inválido!',
    Postcode: 'Código Postal',
    PostcodeTips: '¡Código postal inválido!',
    accountNumber: 'Número de cuenta',
    accountNumberTips: '¡Número de cuenta no válido!',
    achCode: 'Código ACH',
    achCodeTips: '¡Código ACH inválido!',
    contactNumber: 'Número de contacto',
    contactNumberTips: '¡Número de contacto no válido!',
    swiftCode: 'Código Swift',
    swiftCodeTips: '¡Código Swift inválido!',
    branchName: 'Nombre de la sucursal',
    branchNameTips: '¡Nombre de sucursal inválido!',
    branchNameMultinomialTips: 'El nombre de la sucursal no puede ser nulo',
    accountType: 'Tipo de cuenta',
    accountTypeTips: '¡Tipo de cuenta inválido!',
    bankAccountType: 'Tipo de cuenta bancaria',
    bankAccountTypeTips: '¡Tipo de cuenta bancaria no válida!',
    bankAccountTypeLi_Saving: 'Guardando',
    bankAccountTypeLi_Checking: 'Revisando',
    bankAccountTypeLi_TimeDeposit: 'Depósito a plazo',
    bankAccountTypeLi_Others: 'Otros',
    bankCode: 'Código bancario',
    bankCodeTips: '¡Código bancario inválido!',
    branchCode: 'Código de sucursal',
    branchCodeTips: '¡Código de sucursal no válido!',
    locationId: 'Ubicación  Id',
    locationIdTips: '¡Id de ubicación inválido!',
    CPFNumber: 'Número CPF',
    CPFNumberTips: '¡Número CPF inválido!',
    IDType: 'Tipo de ID',
    IDTypeTips: '¡Tipo de ID inválido!',
    bankAccountTypeLi_CUIT: 'CUIT',
    bankAccountTypeLi_CUIL: 'CUIL',
    IDNumber: 'Número de identificación',
    IDNumberTips: '¡Número de identificación inválido!',
    accountTypeLi_Maestra: 'Maestra',
    IDTypeLi_DNI: 'DNI',
    IDTypeLi_RUC: 'RUC',
    IDTypeLi_CE: 'CE',
    IDTypeLi_PASS: 'PASS',
    RUTNumber: 'Número RUT',
    RUTNumberTips: '¡Número RUT inválido!',
    BSBCode: 'Código BSB',
    BSBCodeTips: '¡Código BSB inválido!',
    Email: 'Correo electrónico',
    EmailTips: '¡Correo electrónico inválido!',
    SortCode: 'Código Sort',
    SortCodeTips: '¡Código Sort inválido!',
    IFSC: 'IFSC',
    IFSCTips: '¡Inválido IFSC!',
    IDTypeLi_NIT: 'NIT',
    IDTypeLi_CC: 'CC',
    IDTypeLi_TI: 'TI',
    IDTypeLi_RUT: 'RUT',
    IDTypeLi_CI: 'CI',
    TransitNumber: 'Número de tránsito',
    TransitNumberTips: '¡Número de tránsito inválido!',
    bankSwiftTips: 'El código Swift y el id de ubicación no pueden estar vacíos al mismo tiempo',
    bankSwiftCodeTips: 'El código bancario y el código swift no pueden estar vacíos al mismo tiempo',
    // bankCodeLocationIdTips: 'El código bancario y el ID de ubicación no pueden estar vacíos al mismo tiempo',
    sell_Order_network_selected: 'Por favor, asegúrese de que la red seleccionada es correcta para evitar la pérdida de activos',
    Sell_Order_haveSent: 'He enviado el ',
    Sell_Order_transferred: 'He transferido el cripto a la dirección y red indicadas'
  },

  //买币历史订单页+买币订单详情页
  buyHistory: {
    buttonCard: 'Cambiar la Tarjeta Bancaria',
    buttonAgain: 'Intentar de nuevo',
    buttonMethod: 'Cambiar la Forma de Pago',
    buttonInformation: 'Modificar la Información de la Tarjeta',
    buttonBinance: 'Binance Pago',
    buttonApplePay: 'Apple Pay',
    buttonGooglePay: 'Google Pay',
    tryToBinance:'Trate de pagar por Binance Pay',
    tryToApplePay: 'Trate de pagar por Apple Pay',
    tryToGooglePay: 'Trate de pagar por Google Pay',
    qrCode:'Trate de pagar por QR Code',
    Philippines: 'Trate de pagar por Transferencias bancarias',
    eWallet: 'Trate de pagar por Billetera electrónica',
    buy: 'Comprar',
    buyCrypto: 'Comprar Cripto',
    buyCryptoHideClosed: 'Ocultar pedido cerrado',
    buyPrice: ' Precio ',
    buyOrderAmount: 'Importe del pedido',
    buyTotal: 'Total ',
    buyRampFee: 'Tarifa de Ramp ',
    buyNetworkFee: 'Tarifa de red',
    buyNetwork: 'Red ',
    buyAddress: 'Dirección ',
    buyOrderID: 'ID del pedido',
    buyOrdeTime: 'Hora del Pedido',
    buyPaymentTime: 'Hora de Pago',
    buyTxID: 'TxID',
    buyPaymentMethod: 'Forma de pago',
    buyPayerAccount: 'Cuenta del pagador',
    buyUnpaid: 'Confirmando',
    buyProcessing: 'Procesando',
    buyCompleted: 'Completado',
    buyFailed: 'Fallido',
    buyClosed: 'Cerrado',
    history_noListText: 'No hay transacciones',
    history_noListText2: 'Comprar algo de cripto para empezar',
    history_noListButton: 'Comprar cripto ahora',
    history_noMore: 'No hay más'
  },

  //卖币历史页加订单详情

  sellHistory: {
    sell: 'Vender',
    sellOrderAmount: 'Importe del pedido',
    sellOrderSellCrypto: 'Vender Cripto',
    sellOrderActualAmount: 'Importe real',
    sellOrderFinalTotal: 'Total Final',
    sellOrderBlockConfirmed: 'Bloqueo confirmado',
    sellOrderPayNow: 'Pagar ahora',
    sellConfirmOrder: 'Confirmar pedido',
    sellInTransfer: 'En transferencia',
    sellCompleted: 'Completado',
    sellFailed: 'Fallido',
    selltransferrejected: 'Transferencia de Fiat rechazada por el banco. Por favor, actualice los datos del banco',
    selltransRequest: 'Solicitud de reembolso en USDT',
    selltransUpdate: 'Actualizar información bancaria',
    sellClosed: 'Cerrado',
    sellRefunding: 'Reembolsando',
    sellRefund: 'Reembolsado',
    sellSellcryptonow: 'Vender cripto ahora',
    sellstarted: 'Vender algo de cripto para empezar',
    //卖币订单详情
    selltransRequestof: 'Solicitud de reembolso de USDT',
    sellUpdateInformation: 'Actualizar información',
    sellPrice: 'Precio',
    sellTotal: 'Total',
    sellRampFee: 'Tarifa de Ramp',
    sellReturnfee: 'Comisión de devolución',
    sellFinalTotal: 'Total Final',
    sellStatus: 'Estado',
    sellOrderID: 'ID del pedido',
    sellOrderTime: 'Hora del Pedido',
    sellConfirmedTime: 'Hora de Confirmación',
    sellTransferTime: 'Hora de Transferencia',
    sellRefundTime: 'Hora de Reembolso',
    sellNetwork: 'Red',
    sellAddress: 'Dirección',
    sellTxID: 'TxID',
    sellRecipientAccount: 'Cuenta del Destinatario'
  },
  //卖币退款页面
  RefundFonts: {
    RefundNavTitle: ' Solicitud de reembolso de ',
    RefundTitle: 'Introduzca su dirección de Tron (USDT)',
    Refundplaceholder: 'Introduzca la dirección de su billetera',
    RefundInvalidAddress: 'Dirección inválida',
    Refundattention: 'Atención',
    RefundPlease: ' Por favor, asegúrese de que la dirección que introduce es correcta y pertenece a la red que ha elegido. Si introduce una dirección incompatible, puede perder sus fondos',
    RefundButtonConfirm: 'Confirmar'
  },
  //kyc验证页面
  kycVerificationFonts: {
    kycminutestitle: 'Por favor, verifique su identidad. Suele tardar unos 2 minutos',
    kycPrepareyour: 'Prepare su documento de identidad',
    kycminutosdocumento: 'Prepárese para tomarse un selfie con el documento',
    kycButtonminutes: 'Comenzar la verificación',
    kyccompleted1: '¡Felicitaciones! ',
    kyccompleted2: 'Su verificación completada.',
    kycButtoncompleted: 'Continuar el pago',
    kycPayLimitUpTips: 'Límite de pago hasta',
    kycError: 'Su verificación falló',
    kycRiskAccount: 'La cuenta está en riesgo y no se puede comerciar ahora',
    kycButtonError: 'Volver a la página de inicio',
    kycButtonTryAgain: 'Inténtelo de nuevo',

    kyc2_startTitle: 'Por favor, proporcione su material de dirección',
    kycErrorTips: 'Por favor, asegúrese de que su dirección es auténtica y verificable',
    kycErrorTryAgain_title: 'Su verificación ha fallado',
    kycErrorTryAgain_tipsTitle: 'Por favor, asegúrese',
    kycErrorTryAgain_tips1: 'Ha presentado su propio documento de identidad',
    kycErrorTryAgain_tips2: 'El nombre de su documento de identidad es idéntico al nombre del titular de la tarjeta de pago',
    kycErrorTryAgain_tips3: 'No ha utilizado varios documentos de identidad para la verificación KYC'
  },
  //买币结果成功文案
  paymentResult: {
    Paymentsuccess: '¡Pago exitoso!',
    Paymenttransfer: 'se transferirá a la dirección de su billetera. ',
    Paymentemail: 'Le notificaremos el resultado por correo electrónico '
  },
  //登陆页
  loginPage: {
    enterEmail: '¿Cuál es su dirección de correo electrónico?',
    enterEmail1: '¿Es este su dirección de correo electrónico?',
    emailanother: 'Acceder con otra dirección de correo electrónico',
    emailNotyou: '¿No es usted?',
    codeTitle1: 'Introduzca el código de acceso que acaba de recibir',
    necessary: 'Necesario'
  },
  nav: {
    //买币确认订单文案提示

    buyConfigPaytitle: 'Al continuar, usted acepta nuestra ',
    /**
     * 按钮文案
     */
    Continue: 'Continuar',
    Confirm: 'Confirmar',
    ConfirmPayment: 'Confirmar el pago',
    queryOderState: 'He completado el pago',
    orderRsult: 'Seguir comprando cripto',
    Proceed: 'Proceder',

    //风险账户文案
    accountTraded: 'Esta cuenta está en riesgo y no se puede comerciar ahora.',
    /**
     * 组件
     */
    //kyc姓名为空提示
    cardinformation: 'La información de la tarjeta es incorrecta',
    //网络地址组件
    networkAddress_components_title: 'Dirección',
    //协议组件
    agreement_components_text1: 'Autorizo ',
    agreement_components_text2: 'AlchemyPay ',
    agreement_components_text3: 'a debitar en mi forma de pago elegida el importe anterior en la fecha de hoy y entiendo que esto no puede ser cancelado, retirado o reembolsado. ',
    //查询订单状态提示
    orderTips_title: 'Por favor, espere en esta página',
    orderTips_text1: 'Lo sentimos, aún no hemos recibido su pago. Si confirma el pago, por favor espere en esta página durante unos minutos, una vez que recibamos su pago, transferiremos la cantidad correspondiente de',
    orderTips_text2: 'a usted',
    orderTips_buttonText: 'Entiendo',
    //菜单
    menu: 'Menú',
    transactions: 'Transacciones',
    language: 'Idioma',
    terms: 'Términos del servicio',
    privacy: 'Política de privacidad',
    logOut: 'Cierre de sesión',

    /**
     * 路由
     */
    routerName_buy: 'Comprar Cripto',
    routerName_sell: 'Vender Cripto',
    routerName_email: 'Introducir dirección de correo electrónico',
    routerName_emailCode: 'Código de verificación',
    routerName_Transactions: 'Historial de pedidos',
    routerName_Transactions_details: 'Historial de pedidos',
    routerName_Refund: 'Solicitud de reembolso de ',
    routerName_receivingMode: 'Recibir ',
    routerName_paymentMethod: 'Elegir forma de pago',
    routerName_basisIdAuth: 'Autentificación Id básica',
    routerName_buyForm: 'Introducir datos de la tarjeta',
    routerName_creditCardConfig: 'Confirmar pago',
    routerName_buyPayment: 'Pago',
    routerName_buyOtherWayPay: 'Confirmar su pago',
    routerName_paymentResult: 'Resultado del pago',
    routerName_sellForm: 'Añadir cuenta bancaria',
    routerName_configSell: 'Confirmar',
    routerName_sellOrder: 'Pedido',
    routerName_errorFormInfo: 'Esta tarjeta no está disponible ahora',
    routerName_errorFormInfo2: 'Información incorrecta de la tarjeta',

    /**
     * 登陆
     */
    login: 'Iniciar sesión',
    login_required: 'Error de formato',
    loginOut: 'Salir de la sesión',
    loginOut_Dismiss: 'Cancelar',

    loginTitle1: 'Pago con Alchemy Pay',
    loginTitle2: 'La forma más rápida y segura de pagar en cientos de aplicaciones de cripto',

    codeTitle2: '¿No se ha recibido? ',
    codeTitle3: 'Nuevo código de verificación enviado',
    codeSecond: 's',
    code_text: 'He leído y estoy de acuerdo con las ',
    code_name: 'Condiciones de servicio',
    code_and: 'y',
    code_name2: 'Política de privacidad',
    login_Youlogged: 'Ya ha iniciado sesión',

    /**
     * 首页
     */
    copyTips: 'Éxito con la copia',
    // 买币标题
    home_buyTitle: 'Comprar Cripto',
    //卖币标题
    home_sellTitle: 'Vender Cripto',
    home_minAmountTips: 'El importe mínimo de la transacción es',
    home_maxAmountTips: 'El importe máximo de la transacción es',
    home_feeTimeDownTitle: 'El tiempo restante',
    home_feeTimeDown: 'La cotización se actualiza en',
    home_feeRampFeeTips: 'tan bajo como',

    /**
     * 买币
     */
    home_youPay: 'Usted paga',
    home_youBuyGet: 'Usted obtiene',
    home_buyFee_timeDownTitle: 'Tiempo restante',
    home_buyFee_timeDown: 'La cotización se actualiza en',
    home_buyFee_title1: 'Usted obtiene',
    home_buyFee_title2: 'para',
    home_buyFee_rampFee: 'Tarifa de Ramp',
    home_buyFee_networkFee: 'Cuota de red',
    //卖币
    home_youSell: 'Usted vende',
    home_youSellGet: 'Usted obtiene',
    home_sellFee_title1: 'Usted vende',
    home_sellFee_title2: 'a',
    home_sellFee_rampFee: 'Tarifa de Ramp',
    //搜索组件
    search_components_countryTitle: 'Seleccionar país',
    search_components_cryptoTitle: 'Seleccione Criptomoneda',
    search_components_networkTitle: 'Seleccione la red',
    //买币 - 选择接收方式
    buy_receivingMode_tips: 'Introduzca la dirección de su billetera',
    buy_receivingMode_addressTitle: 'Su ',
    buy_receivingMode_addressPlaceholder: 'Dirección de la billetera...',
    buy_receivingMode_networkTitle: 'Su dirección ACH',
    buy_receivingMode_networkPlaceholder: 'Seleccione la red',
    buy_receivingMode_addressTips1: 'No es una dirección válida',
    buy_receivingMode_addressTips2: 'Dirección',
    //买币 - 选择支付方式
    buy_payment_savedTitle: 'Formas de pago guardadas',
    buy_payment_addTitle: 'Añadir una forma nueva de pago',
    buy_payment_instant: 'Instantáneo',
    buy_payment_ending: 'Fin',
    //买币 - 确认订单 - 国际卡
    buy_configPay_title1: 'Pagando con',
    buy_configPay_title2: 'CVV',
    buy_configPay_title3: 'Confirmo que el pago se ha completado',
    buy_configPay_title4: 'Confirmar',
    buy_configPay_title5: 'Cancelar',
    //买币 - 填写表单 - 国际卡
    buy_form_firstName: 'Nombre',
    buy_form_lastName: 'Apellido',
    buy_form_cardNumber: 'Número de tarjeta',
    buy_form_expirationDate: 'Fecha de caducidad',
    buy_form_CVV: 'CVV',
    buy_form_dataTips: 'Fecha de caducidad no válida',
    buy_form_cardNumTips: 'Por favor, introduzca un número de tarjeta válido',
    buy_form_cvvTips: 'Por favor, introduzca un CVV válido',

    //买币 - 确认订单 - 印尼支付
    buy_configPayIDR_va_title: 'BANCO',
    buy_configPayIDR_va_codeTitle: 'Código de pago',
    buy_configPayIDR_timeDownTips: 'Por favor, complete el pago con',
    buy_configPayIDR_codeTips: 'Escanear el código QR para completar el pago',
    buy_configPayIDR_ovo_phoneTips: 'Abrir la app de OVO para completar el pago',
    //订单结果页
    payResult_errorMessage: '¡Factura caducada!',
    payResult_timeErrorMessage: '¡Fallo de Pago!',
    payResult_feeAmount: 'Importe',
    payResult_feeAddress: 'Dirección',
    payResult_feeHash: 'Hash',
    payResult_feeWallet: 'Billetera',
    payResult_feePassword: 'Billetera ACH',
    payResult_createdTime: 'Hora del pedido',
    payResult_orderNo: 'Número de pedido',

    //菜单栏语言
    RouterMenu_Welcome: '¡Bienvenido a Alchemy Pay!',
    RouterMenu_experience: 'Iniciar sesión para desbloquear la experiencia completa',
    RouterMenu_Transactions: 'Transacciones',
    RouterMenu_history: 'No hay historial todavía',
    RouterMenu_Language: 'Idioma',
    RouterMenu_Service: 'Condiciones de servicio',
    RouterMenu_Policy: 'Política de privacidad',
    RouterMenu_logout: '¿Seguro que quiere cerrar la sesión?',
    RouterMenu_Dismiss: 'Cancelar',

    sell_configOrder_title: 'Tarjeta bancaria',

    //表单页
    sell_form_tips: 'consejos',
    sell_form_Name: 'Nombre',
    sell_form_NameTips: '¡Nombre inválido!',
    sell_form_Address: 'Dirección',
    sell_form_AddressTips: '¡Dirección no válida!',
    sell_form_AddressMultinomialTips: 'La dirección no puede ser nula',
    sell_form_City: 'Ciudad',
    sell_form_CityTips: '¡Ciudad inválida!',
    sell_form_State: 'Estado',
    sell_form_StateTips: '¡Estado inválido!',
    sell_form_Postcode: 'Código postal',
    sell_form_PostcodeTips: '¡Código postal no válido!',
    sell_form_accountNumber: 'Número de cuenta',
    sell_form_accountNumberTips: '¡Número de cuenta no válido!',
    sell_form_achCode: 'Código ACH',
    sell_form_achCodeTips: '¡Código ACH inválido!',
    sell_form_contactNumber: 'Número de contacto',
    sell_form_contactNumberTips: '¡Número de contacto no válido!',
    sell_form_swiftCode: 'Código Swift',
    sell_form_swiftCodeTips: '¡Código Swift inválido!',
    sell_form_branchName: 'Nombre de la sucursal',
    sell_form_branchNameTips: '¡Nombre de sucursal no válido!',
    sell_form_branchNameMultinomialTips: 'El nombre de la sucursal no puede ser nulo',
    sell_form_accountType: 'Tipo de cuenta',
    sell_form_accountTypeTips: '¡Tipo de cuenta inválido!',
    sell_form_bankAccountType: 'Tipo de cuenta bancaria',
    sell_form_bankAccountTypeTips: '¡Tipo de cuenta bancaria no válida!',
    sell_form_bankAccountTypeLi_Saving: 'Guardando',
    sell_form_bankAccountTypeLi_Checking: 'Revisando',
    sell_form_bankAccountTypeLi_TimeDeposit: 'Depósito a plazo',
    sell_form_bankAccountTypeLi_Others: 'Otros',
    sell_form_bankCode: 'Código bancario',
    sell_form_bankCodeTips: '¡Código bancario inválido!',
    sell_form_branchCode: 'Código de sucursal',
    sell_form_branchCodeTips: '¡Código de sucursal no válido!',
    sell_form_locationId: 'Ubicación Id',
    sell_form_locationIdTips: '¡Id de ubicación inválido!',
    sell_form_CPFNumber: 'Número de CPF',
    sell_form_CPFNumberTips: '¡Número CPF inválido!',
    sell_form_IDType: 'Tipo de ID',
    sell_form_IDTypeTips: '¡Tipo de DNI no válido!',
    sell_form_bankAccountTypeLi_CUIT: 'CUIT',
    sell_form_bankAccountTypeLi_CUIL: 'CUIL',
    sell_form_IDNumber: 'Número de identificación',
    sell_form_IDNumberTips: '¡Número de identificación inválido!',
    sell_form_accountTypeLi_Maestra: 'Maestra',
    sell_form_IDTypeLi_DNI: 'DNI',
    sell_form_IDTypeLi_RUC: 'RUC',
    sell_form_IDTypeLi_CE: 'CE',
    sell_form_IDTypeLi_PASS: 'PASS',
    sell_form_RUTNumber: 'Número RUT',
    sell_form_RUTNumberTips: '¡Número RUT inválido!',
    sell_form_BSBCode: 'Código BSB',
    sell_form_BSBCodeTips: '¡Código BSB inválido!',
    sell_form_Email: 'Correo electrónico',
    sell_form_EmailTips: '¡Correo electrónico inválido!',
    sell_form_SortCode: 'Código Sort',
    sell_form_SortCodeTips: '¡Código Sort inválido!',
    sell_form_IFSC: 'IFSC',
    sell_form_IFSCTips: '¡Código de identificación inválido!',
    sell_form_IDTypeLi_NIT: 'NIT',
    sell_form_IDTypeLi_CC: 'CC',
    sell_form_IDTypeLi_TI: 'TI',
    sell_form_IDTypeLi_RUT: 'RUT',
    sell_form_IDTypeLi_CI: 'CI',
    sell_form_TransitNumber: 'Número de tránsito',
    sell_form_TransitNumberTips: '¡Número de tránsito no válido!',
    sell_form_bankSwiftTips: 'El código Swift y el id de ubicación no pueden estar vacíos al mismo tiempo',
    sell_form_bankSwiftCodeTips: 'El código bancario y el código swift no pueden estar vacíos al mismo tiempo',
    // sell_form_bankCodeLocationIdTips: 'El código bancario y el id de ubicación no pueden estar vacíos al mismo tiempo',

    // 后增
    history_state_Complete: 'Completo',
    history_state_Processing: 'Procesando',
    history_state_Transfer: 'Cripto enviado',
    history_listTitle1: 'Número de pedido',
    history_listTitle2: 'Cripto',
    history_listTitle3: 'Hash ID',
    fee_listTitle_price: 'Precio',
    sell_order_title: 'estado-contenido',
    fee_tips: 'Basado en la forma de pago',
    search_components_Popular: 'Popular',
    search_components_All: 'Todos',
    search_components_placeHolder: 'Buscar aquí... ',
    result_stateTo4_your: 'Su',
    result_stateTo4: 'El pedido se está procesando y se enviará a su dirección designada en breve. ',
    result_stateTo5: 'se ha transferido a su dirección de billetera',
    result_returnText_merchant: 'Devolver',
    buy_configPayIDR_ovo_title: 'Teléfono móvil OVO',
    login_getCode: 'Reenviar código',
    login_VerifyCode: 'Por favor, introduzca el código de verificación de seis dígitos',
    login_Agreement: 'Por favor, marque el Acuerdo de Usuario'
  },
  // 账号禁用页面
  accountDisabled: {
    title: 'Cuenta desactivada',
    content: 'Desafortunadamente, su cuenta ha sido desactivada por no cumplir con nuestros términos y condiciones.',
    buttonText: 'Aprende más'
  }
};
