import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import getters from '@/store/getters';

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
const store = new Vuex.Store({
  modules,
  getters,
  state: {
    //页面滚动状态
    scrollState: false,

    //登陆后前往页面地址
    emailFromPath: '',
    //退款页
    emailFromquery_refund_view: {
      orderId: '',
      cryptocurrency: '',
      fiatCode: ''
    },
    //交易历史详情
    emailFromquery_tradeHistoryDetails_view: {
      orderId: ''
    },
    //修改卡信息
    emailFromquery_sellCardInfo_view: {
      orderId: '',
      position_alpha2: '',
      position_code: '',
      getAmount: ''
    },
    //新增墨西哥支付状态
    MEXPayStatus: false,
    cardInfoFromPath: '', //填写卡信息后返回页面地址
    //返回首页状态
    homeTabstate: 'buyCrypto', //sellCrypto buyCrypto
    tableState: false, //有参数 隐藏买币或者卖币

    //订单tab状态 sell | buy
    historyTab: 'buy',

    //语言
    language: '',

    //买币下单参数
    placeOrderQuery: {},

    //买币支付状态tab
    buyPayProcessTab: {
      tabState: true
    },

    //买币页面参数
    buyRouterParams: {
      buyCrypto_default: true,

      exchangeRate: 0,
      buyNetworkList: [],
      cryptoCurrencyData: {},
      buyNetwork: {
        networkName: ''
      },
      payCommission: {
        symbol: '',
        code: ''
      },
      network: '',
      network_fullName: '',
      networkDefault: '',
      addressDefault: '',
      feeRate: '',
      fixedFee: '',

      payMethod: {
        bankInfo: {}
      },
      payMethodStatus: false
    },
    //买币二次支付数据存放
    buyPaymentParams: {},
    //卖币页面参数
    sellRouterParams: {
      amount: '',
      payCommission: {
        fiatCode: ''
      },
      currencyData: {
        name: '',
        price: '' //kyc验证需要
      },
      cardInfoList: [],
      PositionData: {
        enCommonName: '',
        alpha2: '',
        code: ''
      },
      //国家币种 - 表单
      formPositionData: {
        enCommonName: '',
        alpha2: '',
        code: ''
      },

      confirmParams: {},

      //交易历史跳转订单订单状态返回
      historyBack: ''
    },
    //菜单展示
    routerViewState: true,
    sellForm: {},

    //卖币费用请求参数
    feeParams: {
      symbol: '',
      fiatCode: '',
      alpha2: ''
    },
    feeParams_order: {
      symbol: '',
      fiatCode: '',
      alpha2: ''
    },
    sellOrderId: '',

    //ajax请求队列
    cancelTokenArr: [],

    //多语言
    languageValue: 'en-US', // EN-US ZH-TW
    LanguageIsShow: false,
    menuState: false,
    isLogin: false,

    routerQueryPath: false, // 修改是否从带单进入登录

    codeState: false,
  },
  mutations: {
    //结束所有请求进程
    pushToken(state, payload) {
      state.cancelTokenArr.push(payload.cancelToken);
    },
    DEL_ITEM(state, id) {
      state.sellForm = id;
    },
    clearToken({ cancelTokenArr }) {
      cancelTokenArr.forEach(item => {
        if (item) {
          item('路由跳转取消请求');
        }
      });
      cancelTokenArr = [];
    },
    emptyToken(state) {
      state.cancelTokenArr = [];
    },
    //修改恢复买币进度条状态
    changeBuyPayProcessTab(state, val) {
      state.buyPayProcessTab.tabState = val;
    },
    // 修改是否从带单进入登录
    changeRouterQueryPath(state, flag) {
      state.routerQueryPath = flag;
    },
    // 修改登陆后前往页面地址
    changeEmailFromPath(state, path) {
      state.emailFromPath = path;
    },
    // 修改返回首页状态
    changeHomeTabstate(state, tab) {
      state.homeTabstate = tab;
    },
    //修改二次支付数据
    setbuyPaymentData(state, val) {
      state.buyPaymentParams = val;
      // console.log(state.buyPaymentParams,'给到了值')
    },
  },
  actions: {
    //结束所有请求进程
    clearAllQuery({ commit }) {
      commit('clearToken');
      commit('emptyToken');
    }
  }
});
export default store;
