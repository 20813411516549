module.exports = {
  language:'English(US)',
  //首页
  homePage: {
    tab_buy: 'Buy Crypto',
    tab_sell: 'Sell Crypto',
    //买币
    youPay: 'You Pay',
    youBuyGet: 'You get',
    buyFee_title1: 'You Get',
    //卖币
    youSell: 'You Sell',
    youSellGet: 'You Get',
    sellFee_title1: 'You sell',
    sellFee_title2: 'to',
    sellFee_rampFee: 'Ramp Fee',
    sellminAmount:'Minimum selling amount',
    sellmaxAmount:'Maximum selling amount',
    minAmountTips: 'The minimum transaction amount is',
    maxAmountTips: 'The maximum transaction amount is',

    bottomTips1: 'Proceed · Sell',
    bottomTips2: 'Proceed · Buy',
    //钱包地址验证页的提示
    receivingMode: 'Please make sure the address you entered is correct before confirming the transfer. Do not send tokens to other one’s address. Incompatible address will cause fail order or fund loss.',
    // bottomTips2: 'Powered By',
  },
  //世界杯多语言文案
  worldCuo:{
    World:'2022 Qatar World Cup',
    discount:'50% discount',
    Rampfee:'on your first order Ramp fee',
    Details:'Details',
    WorldCup:'World Cup',
    WorldDiscount:'World Cup discount',
    Participated:'Participated',
    order:'one this order',
    countdown:'Event countdown',
    countdownstart:'The event is about to start',
  },

//墨西哥支付页面改版本案
  mexPayment:{
    tiemOut:'The order will be cancelled in ',
    PleasePay:'Please Pay',
    title1:'Open your bank application . Enter this reference number  ',
    title2:'Enter the account registration information',
    CLABEinterbancaria:'CLABE interbancaria:',
    Destinationbank:'Destination bank:',
    Beneficiary:'Beneficiary:',
    Payamount:'Pay amount:',
    title3:'If your payment is not completed for a long time, we suggest you upload the payment documents to our customer service. This will speed up our confirmation of your payment.'
  },

  //等待结果组件
  buypayWait:{
    Processing:'In Processing',
    Waiting:'Waiting for your purchase…',
    purchase:'Please move on the payment page to complete your purchase.',
    Afterpayment:'After your payment',
    Wepurchase:'We will send you an email when receive your purchase so you can find them easily later on.',
    historyorder:'view order history',
  },
  //菲律宾页面
  phpPayment:{
    PaymentGuide:'Payment Guide',
    BillNumber:'Digital Remit number',
    PAYSO1:'Enter Gcash wallet, click "Bills" and select bill payment',
    PAYSO2:'Search and select "PAYSO"',
    PAYSO3:'fill in these informations, and click [NEXT]',
    Incorrect:'Please ensure the amount you transfer to us is accurate. Incorrect information or amount will cause fail order or fund loss.'

  },
  //vnd新增文案 越南支付文案
  vndChildren: {
    amount:'Amount',
    oneTitle:`Go to the banking app , scan the QRcode or enter the recipient's id manually`,
    twoTitle:' Fill in the transfer content in the message box',
    threeTitle:' make a money transfer',
    Accountname:'Account name',
    AccountID:'Account ID',
    Transfercontent:'Transfer content',
  },
  //买币表单新增文案
  sellFormPageTitle:{
    Information:'Use this Information',
    AccountNumber:'Account number',
    More:'More',
    Attention:'Attention',
    ReturnediInformation:'Please ensure the bank account belongs to you and the information is accurate. Returned transactions are subjected to $25 fee charged by our banking partners.',
    buy_configphp_codeTips:'Scan QR code via your QRPH to complete the payment'
  },
  //导航文案
  navTabTitle:{
    Completepayment:'Complete the payment',
  },
  //买币费用组件
  buyFee_components: {
    codeSecond: 's',
    youGet: 'You get',
    title2: 'for',
    rampFee: 'Ramp Fee',
    rampFeeTips: 'as low as',
    networkFee: 'Network Fee',
    tips: 'This is charged by AlchemyPay to cover costs associated with payment processing. This may vary based on the payment method you choose later.',

  },
  //卖币费用组件
  sellFee_components: {
    codeSecond: 's',
    title1: 'You Sell',
    title2: 'to',
    rampFee: 'Ramp Fee',
    tips: 'Based on payment method',
    RampFeeTips: 'as low as',
  },
  //菜单
  menu: {
    home: 'Home',
    menu: 'Menu',
    transactions: 'Transactions',
    language: 'Language',
    privacy: 'Privacy Policy',
    logOut: 'Log Out',
    service: 'Terms of Service',
    RouterMenu_experience:'Login to unlock the full experience',
    login_Youlogged:'You re already logged in',
    RouterMenu_Welcome:'Welcome to Alchemy Pay!',
    RouterMenu_history:'No history yet',
    loginOut_Dismiss: 'Dismiss',
    loginOut_title: 'Are you sure you want to logout?',
  },

  //买币接收方式页 //买币填写地址页面
  buyReceivingMethod: {
    networkTitle1: 'Enter your',
    networkTitle2: 'address',
    addressTips1: 'Not a valid',
    addressTips2: 'address.',
    memo: 'Memo',
    memoTips: 'Not a valid ACH memo.',
    ENSTips: 'Enter a valid wallet address address, ENS or Unstoppable domain.',
    supported: 'supported domains',
    Unstoppable: 'We support Unstoppable domains for this asset',
  },
  //---------------------------- 阿根廷支付页面 ----------------------------
  ArgentinaPay:{
    BankCode:'Bank Code',
    ChooseBank:'Choose your Bank',
    selectcard:'Please select a bank',
    BankAccount:'Bank Account',
    cardnumber:'Please enter the correct card number',
    Bankcode:'Bank code',
    correctcode:'Please enter the correct code',
    ID:'ID',
    SelectBank:'Select Bank',
    phone:'phone Number',
    phoneTips:'Invalid phone number!',
    paymentResultError:'The bank account information is incorrect. Please check and purchase again.',
    Choose:'Choose your account type',
    chequing:'Chequing',
    Saving:'Saving',
    AccountNumber:'Account number',
    fullNameTips: 'Invalid full name',
  },
  //买币选择支付方式页
  buyPayWay: {
    BINANCEPAY:'BINANCE PAY',
    qrCode:'QR Code',
    Khipu:'Bank Transfer',
    ThaiQR:'ThaiQR',
    Philippines:'Bank Transfer',
    saas:'PayNow',
    pix:'PIX',
    ChangeCard:'Change bank card',
    recentlyUsed: 'Saved Payment Method',
    paymentMethod: 'Add a New Payment Method',
    towMethod: 'Change payment method',
    rampFee: 'Ramp Fee',
    card: 'Card',
    card1: 'Card',
    creditCard: 'Credit Card',
    bankTransfer: 'Prompt Pay',
    bancoTransfer: 'Banco Transfer',
    opm: 'SPEI(OPM)',
    virtualAccount: 'Bank Transfer',
    eWallet: 'E-Wallet',
    binancePay: 'BINANCE PAY',
    applePay: 'Apple Pay',
    googlePay: 'Google Pay',
    promptPay: 'Prompt Pay',
    addCardTitle: 'Add New Card',
    useCardTitle:'Use new card',
    edit: 'Edit',
    cancel: 'Cancel',
    support: 'Support',
    cardLimitTips: '6 Cards Limit Reached',
  },

  //买币国际卡表单组件
  buyForm_components: {
    formTitle: 'Enter your card details.',
    firstName: 'First Name',
    lastName: 'Last Name',
    nameTips: 'Invalid Name!',
    cardNumber: 'Card Number',
    cardNumTips: 'You’ve entered an invalid card number',
    expirationDate: 'Expiration Date',
    dataTips: 'Invaid expiry date',
    cvv: 'CVV',
    cvvTips: 'Please enter a valid CVV',
    NameError:'The name doesn’t match the card, please fix it'
  },

  //国际卡确定订单页 - 老变量没有处理
  creditCard: {
    payingWith: 'Paying with',
    card: 'card',
    cvv: 'CVV',
  },

  //买币确定订单页 - 巴西支付
  configPay_brl: {
    phoneNumber: 'Cell phone Number',
    fullName: 'Full Name',
    formTitle: 'Enter your details.',
    code_tips: 'Scan QR code via your PIX to complete the payment.',
    name_tips: 'Invalid Name!',
    id_tips: 'Invalid Id!',
    number_tips: 'Invalid Cell phone Number!',
    Country:'Select Country',
  },

  opmPayment: {
    banco: 'Banco',
  },

  //买币结果页
  payResult: {
    //支付结果提示
    tipsText_40010: 'Card data inaccurate. Please check card information.',
    tipsText_40011: 'Card data inaccurate. Please check card information.',
    tipsText_40019: 'Card data inaccurate. Please check card information.',
    tipsText_40020: 'Insufficient fund',
    tipsText_40030: 'Card jurisdiction is restricted. Please try another card.',
    tipsText_40031: 'Card restricted',
    tipsText_40032: 'Card abnormality',
    tipsText_40033: 'Card restricted',
    tipsText_40034: 'Card restricted, risk control.',
    tipsText_40090: 'Abnormal rejection',
  },

  //搜索组件
  search_components: {
    recent: 'Recent',
    availableNow: 'Available now',
  },
  //弹出框文案
  payConfig_components: {
    title_THB: 'Payment in progress',
    button_THB: 'Check Order',
    button_timeOut: 'Back to Home Page',
    button_timeOut1: 'Back to Home',
    double: 'Please do not make double payment',
    completed:'Please confirm that your transfer has been completed.',
    timeorder:'This order is out of time.',
    checkout:'Are you sure leave checkout counter?',
    buttonLeave:'Leave Now',
  },

  //按钮组件
  button_components: {
    proceed: 'Proceed',
  },

  //选择网络组件
  network_components: {
    network:'Choose Network',
  },

  //---------------------------- 支付方式信息组件 ----------------------------
  PayMethodInfo_components: {
    title: 'Order summary',
    title2: 'Your chosen payment method is',
  },
  //---------------------------- 扫码QR Code组件 ----------------------------
  QrCode_components: {
    title1: 'Pay',
    title2: 'to completed your purchuse.',
    title3: 'Please open your payment application and scan the QR code below to complete the payment.',
    copy: 'COPY',
  },
  //---------------------------- 钱包地址和网络信息组件 ----------------------------
  WalletAddress_components: {
    title1: 'Your',
    title2: 'wallet',
  },
  //---------------------------- 表单信息 ----------------------------
  formInfo: {
    //新增
    fullName_placeholder: 'Enter the full name',
    phone_title: 'Please enter your phone number',
    id_title: 'Enter the ID number',
    id_placeholder1: 'Enter the',
    id_placeholder2: 'digit ID number',
    bankAccount_placeholder: 'Choose your account type',
    accountNumber_placeholder: 'Enter the 22 digit ID number',
    bankCode_placeholder: 'Enter the 3 digit ID number',
    //vnd 选择bank code
    vnd_bankCode_placeholder: 'Choose bank code',
  },

  /**
   * 卖币
   */
  //卖币支付页+订单状态页
  sellOrderFonts:{
    minimumAmount:'Your order is below its minimum amount',
    maximumAmount:'Your order is higher than its maximum amount',
    sellTitle:'Sell',
    Sellorder_Network:'Choose Network',
    Sellorder_Ramp:'Ramp Fee ',
    Sellorder_PleaseCorrect:'Please ensure the network selected is correct to avoid loss of assets.',
    Sellorder_transferred:'I have transferred the crypto to the indicated address and network.',
    Sellorder_ButtonNot:'Not Yet',
    Sellorder_ButtonConfirm:'Confirm',
    Sellorder_ButtonHaveSent:' I have sent the ',
    Sellorder_ButtongoHome:'  Back to Home Page',
    Sellorder_ButtongoHistory:'Order History',
    Sellorder_timeOutTitle:' Your order has expired, please do not send money to this address, otherwise the asset may be lost.',
    Sellorder_StatusTitle:'You may leave this page. Order updates will be sent to your email and can be checked in the order history.',
    Sellorder_StatusNav:'Order Status',
    Sellorder_StatusCryptoSent:'Crypto Sent',
    Sellorder_StatusBlockConfirmed:'Block confirmed',
    Sellorder_StatusConfirmOrder:'Confirm Order',
    Sellorder_StatusOrderconfirmed:'Your order has been confirmed',
    Sellorder_StatusInTransfer:'In Transfer',
    Sellorder_StatusInTransferfiat:'Your fiat is in transfer',
    Sellorder_StatusResult:'Result',
    Sellorder_StatusResultCompleted:'Order completed',
    Sellorder_StatusResultfail:'Order fail',
    //卖币状态
    Sellorder_transfer:'Please transfer ',
    Sellorder_within:'to the address within ',
    Sellorder_will:'will ',
    Sellorder_get:'get ',
    Sellorder_for:'  for ',
    Sellorder_Id:'Order ID',
    Sellorder_Address:'Address',
    Sellorder_Fail:'Fail Reason',
    Sellorder_You:'You Sell',
    Sellorder_to:'to',
    SellOrder_modified:'Card information cannot be modified at this time',
  },


  //卖币表单页
  sellForm: {
    phone:'Phone',
    phoneTips: 'Invalid phone number!',
    tips: 'tips',
    fullName: 'Full Name',
    lastName: 'Last Name',
    NameTips: 'Invalid Name!',
    Address: 'Address',
    AddressTips: 'Invalid Address!',
    AddressMultinomialTips: 'Address can not be null',
    City: 'City',
    CityTips: 'Invalid City!',
    State: 'State',
    StateTips: 'Invalid State!',
    Postcode: 'Postcode',
    PostcodeTips: 'Invalid Postcode!',
    accountNumber: 'Account Number',
    accountNumberTips: 'Invalid Account Number!',
    achCode: 'ACH Code',
    achCodeTips: 'Invalid ACH Code!',
    contactNumber: 'Contact Number',
    contactNumberTips: 'Invalid Contact Number!',
    swiftCode: 'Swift Code',
    swiftCodeTips: 'Invalid Swift Code!',
    branchName: 'Branch Name',
    branchNameTips: 'Invalid Branch Name!',
    branchNameMultinomialTips: 'Branch name can not be null',
    accountType: 'Account Type',
    accountTypeTips: 'Invalid Account Type!',
    bankAccountType: 'Bank Account Type',
    bankAccountTypeTips: 'Invalid Bank Account Type!',
    bankAccountTypeLi_Saving: 'Saving',
    bankAccountTypeLi_Checking: 'Checking',
    bankAccountTypeLi_TimeDeposit: 'Time Deposit',
    bankAccountTypeLi_Others: 'Others',
    bankCode: 'Bank Code',
    bankCodeTips: 'Invalid Bank Code!',
    branchCode: 'Branch Code',
    branchCodeTips: 'Invalid Branch Code!',
    locationId: 'Location Id',
    locationIdTips: 'Invalid Location Id!',
    CPFNumber: 'CPF Number',
    CPFNumberTips: 'Invalid CPF Number!',
    IDType: 'ID Type',
    IDTypeTips: 'Invalid ID Type!',
    bankAccountTypeLi_CUIT: 'CUIT',
    bankAccountTypeLi_CUIL: 'CUIL',
    IDNumber: 'ID Number',
    IDNumberTips: 'Invalid ID Number!',
    accountTypeLi_Maestra: 'Maestra',
    IDTypeLi_DNI: 'DNI',
    IDTypeLi_RUC: 'RUC',
    IDTypeLi_CE: 'CE',
    IDTypeLi_PASS: 'PASS',
    RUTNumber: 'RUT Number',
    RUTNumberTips: 'Invalid RUT Number!',
    BSBCode: 'BSB Code',
    BSBCodeTips: 'Invalid BSB Code!',
    Email: 'Email',
    EmailTips: 'Invalid Email!',
    SortCode: 'Sort Code',
    SortCodeTips: 'Invalid Sort Code!',
    IFSC: 'IFSC',
    IFSCTips: 'Invalid IFSC!',
    IDTypeLi_NIT: 'NIT',
    IDTypeLi_CC: 'CC',
    IDTypeLi_TI: 'TI',
    IDTypeLi_RUT: 'RUT',
    IDTypeLi_CI: 'CI',
    TransitNumber: 'Transit Number',
    TransitNumberTips: 'Invalid Transit Number!',
    bankSwiftTips: 'Swift code and location id cannot be empty at the same time',
    bankSwiftCodeTips: 'Bank code and swift code cannot be empty at the same time',
    // bankCodeLocationIdTips: 'Bank code and location id cannot be empty at the same time',
    sell_Order_network_selected:'Please ensure the network selected is correct to avoid loss of assets.',
    Sell_Order_haveSent:'I have sent the ',
    Sell_Order_transferred:'I have transferred the crypto to the indicated address and network.',
  },

  //买币历史订单页+买币订单详情页
  buyHistory:{
    buttonCard:'Change Bank Card',
    buttonAgain:'Try Again',
    buttonMethod:'Change Payment Method',
    buttonInformation:'Modify Card Information',
    buttonBinance:'Binance Pay',
    buttonApplePay: 'Apple Pay',
    buttonGooglePay: 'Google Pay',
    tryToBinance:'Try to pay by Binance Pay',
    tryToApplePay: 'Try to pay by Apple Pay',
    tryToGooglePay: 'Try to pay by Google Pay',
    qrCode:'Try to pay by QR Code',
    Philippines:'Try to pay by Bank Transfer',
    eWallet: 'Try to pay by E-Wallet',
    buy:'Buy',
    buyCrypto:'Buy Crypto',
    buyCryptoHideClosed:'Hide Closed Order',
    buyPrice:' Price ',
    buyOrderAmount:'Order Amount ',
    buyTotal:'Total ',
    buyRampFee:'Ramp Fee ',
    buyNetworkFee:'Network Fee ',
    buyNetwork:'Network ',
    buyAddress:'Address ',
    buyOrderID:'Order ID',
    buyOrdeTime:'Order Time',
    buyPaymentTime:'Payment Time',
    buyTxID:'TxID',
    buyPaymentMethod:'Payment Method',
    buyPayerAccount:'Payer Account',
    buyUnpaid:'Confirming',
    buyProcessing:'Processing',
    buyCompleted:'Completed',
    buyFailed:'Failed',
    buyClosed:'Closed',
    history_noListText: 'No transactions',
    history_noListText2: 'Buy some crypto to get started',
    history_noListButton: 'Buy crypto now',
    history_noMore: 'No more',
  },

  //卖币历史页加订单详情
  sellHistory:{
    sell:'Sell',
    sellOrderAmount:'Order Amount',
    sellOrderSellCrypto:'Sell Crypto',
    sellOrderActualAmount:'Actual Amount',
    sellOrderFinalTotal:'Final Total',
    sellOrderBlockConfirmed:'Block Confirmed',
    sellOrderPayNow:'Pay Now',
    sellConfirmOrder:'Confirm Order',
    sellInTransfer:'In Transfer',
    sellCompleted:'Completed',
    sellFailed:'Failed',
    selltransferrejected:'Fiat transfer rejected by bank. Please update bank info.',
    selltransRequest:'Request Refund in USDT',
    selltransUpdate:'Update Bank Info',
    sellClosed:'Closed',
    sellRefunding:'Refunding',
    sellRefund:'Refunded',
    sellSellcryptonow:'Sell crypto now',
    sellstarted:'Sell some crypto to get started',
    //卖币订单详情
    selltransRequestof:'Request Refund of USDT',
    sellUpdateInformation:'Update Information',
    sellPrice:'Price',
    sellTotal:'Total',
    sellRampFee:'Ramp Fee',
    sellReturnfee:'Return fee',
    sellFinalTotal:'Final Total',
    sellStatus:'Status',
    sellOrderID:'Order ID',
    sellOrderTime:'Order Time',
    sellConfirmedTime:'Confirmed Time',
    sellTransferTime:'Transfer Time',
    sellRefundTime:'Refund Time',
    sellNetwork:'Network',
    sellAddress:'Address',
    sellTxID:'TxID',
    sellRecipientAccount:'Recipient Account',
  },
  //卖币退款页面
  RefundFonts:{
    RefundNavTitle:' Request Refund of  ',
    RefundTitle:'Enter your Tron (USDT) address',
    Refundplaceholder:'Enter your wallet address',
    RefundInvalidAddress:'Invalid address',
    Refundattention:'Pay attention',
    RefundPlease:' Please make sure the address you enter is correct and belong to the network that you choose. If you enter incompatible address, you will lose your funds.',
    RefundButtonConfirm:'Confirm',
  },
  //kyc验证页面
  kycVerificationFonts:{
    kycminutestitle:'Please verify your identity. It usually takes about 2 minutes.',
    kycPrepareyour:'Prepare your ID',
    kycminutesdocument:'Get ready to take a selfie with document.',
    kycButtonminutes:'Begin Verification',
    kyccompleted1:'Congrats! ',
    kyccompleted2:'Your verification completed.',
    kycButtoncompleted:'Continue the Payment',
    kycPayLimitUpTips: "Payment limit up to",
    kycError:'Your verification failed.',
    kycRiskAccount: 'The account is at risk and cannot be traded now.',
    kycButtonError:'Back to Home Page ',
    kycButtonTryAgain:'Try Again',

    kyc2_startTitle: "Please provide your address material.",
    kycErrorTips: "Please make sure your address is genuine and verifiable",
    kycErrorTryAgain_title: 'Your verification failed.',
    kycErrorTryAgain_tipsTitle: 'Please ensure',
    kycErrorTryAgain_tips1: "You submitted your own ID document.",
    kycErrorTryAgain_tips2: "The name on your ID document is identical to your payment's cardholder name.",
    kycErrorTryAgain_tips3: "You did not use multiple ID documents for the KYC verification.",
  },
  //买币结果成功文案
  paymentResult:{
    Paymentsuccess:'Payment success!',
    Paymenttransfer:'will transfer to your wallet address. ',
    Paymentemail:'We will notify you of the result by email ',
  },
  //登陆页
  loginPage:{
    enterEmail: 'What is your email address?',
    enterEmail1: 'Is this your email address?',
    emailanother:'Sign in with another email address',
    emailNotyou:'Not you?',
    codeTitle1: 'Enter the login code you just received',
    necessary:'necessary'
  },
  nav:{
    //买币确认订单文案提示

    buyConfigPaytitle: 'By continuing you agree to our ',
    /**
     * 按钮文案
     */
    Continue: 'Continue',
    Confirm: 'Confirm',
    ConfirmPayment: 'Confirm the Payment',
    queryOderState: 'I have completed the payment',
    orderRsult: 'Continue to buy Cryptos',
    orderRsultTransList: 'Continue to buy crypto',
    Proceed:'Proceed',

    //风险账户文案
    accountTraded:'This account is at risk and cannot be traded now.',
    /**
     * 组件
     */
    //kyc姓名为空提示
    cardinformation:'The card information is incorrect',
    //网络地址组件
    networkAddress_components_title: 'address',
    //协议组件
    agreement_components_text1: 'I authorise ',
    agreement_components_text2: 'AlchemyPay ',
    agreement_components_text3: 'to debit my chosen payment method for the amount above on today’s date and understand that this can not be cancelled, recalled or refunded. ',
    //查询订单状态提示
    orderTips_title: 'Please wait on this page',
    orderTips_text1: 'Sorry, we have not receive your payment yet. If you confirm the payment, please wait on this page for a few minutes, once we receive your payment, we will transfer the corresponding amount of',
    orderTips_text2: 'to you.',
    orderTips_buttonText: 'I Understand',
    //菜单
    menu: 'Menu',
    transactions: 'Transactions',
    language: 'Language',
    terms: 'Terms of Service',
    privacy: 'Privacy Policy',
    logOut: 'Log Out',

    /**
     * 路由
     */
    routerName_buy: 'Buy Crypto',
    routerName_sell: 'Sell Crypto',
    routerName_email: 'Enter Email Address',
    routerName_emailCode: 'Verification code',
    routerName_Transactions: 'Order History',
    routerName_Transactions_details: 'Order History',
    routerName_Refund: 'Request Refund of ',
    routerName_receivingMode: 'Receive ',
    routerName_paymentMethod: 'Choose payment method',
    routerName_basisIdAuth: 'basis-Id-Auth',
    routerName_buyForm: 'Enter card details',
    routerName_creditCardConfig: 'Confirm Payment',
    routerName_buyPayment: 'Payment',
    routerName_buyOtherWayPay: 'Confirm your payment',
    routerName_paymentResult: 'Payment Result',
    routerName_sellForm: 'Add Bank Account',
    routerName_configSell: 'Confirm',
    routerName_sellOrder: 'Order',
    routerName_errorFormInfo: 'This card unavailable now',
    routerName_errorFormInfo2: 'Incorrect Card Information',

    /**
     * 登陆
     */
    login: 'Login',
    login_required: 'Format Error.',
    loginOut: 'Login out',
    loginOut_Dismiss: 'Dismiss',

    loginTitle1: 'Checkout with Alchemy Pay',
    loginTitle2: 'The fastest and safest way to checkout on hundreds of crypto apps.',

    codeTitle2: 'Not received? ',
    codeTitle3: 'New verification code sent ',
    codeSecond: 's',
    code_text: " I have read and agree to Alchemy pay's ",
    code_name: 'Terms of Service',
    code_and: 'and',
    code_name2: 'Privacy Policy',
    login_Youlogged:'You re already logged in',

    /**
     * 首页
     */
    copyTips: 'copy success',
    // 买币标题
    home_buyTitle: "Buy Crypto",
    //卖币标题
    home_sellTitle: "Sell Crypto",
    home_minAmountTips: 'The minimum transaction amount is',
    home_maxAmountTips: 'The maximum transaction amount is',
    home_feeTimeDownTitle: 'Remaining time',
    home_feeTimeDown: 'Quote updates in',
    home_feeRampFeeTips: 'as low as',

    /**
     * 买币
     */
    home_youPay: 'You Pay',
    home_youBuyGet: 'You get',
    home_buyFee_timeDownTitle: 'Remaining time',
    home_buyFee_timeDown: 'Quote updates in',
    home_buyFee_title1: 'You Get',
    home_buyFee_title2: 'for',
    home_buyFee_rampFee: 'Ramp Fee',
    home_buyFee_networkFee: 'Network Fee',
    //卖币
    home_youSell: 'You Sell',
    home_youSellGet: 'You Get',
    home_sellFee_title1: 'You sell',
    home_sellFee_title2: 'to',
    home_sellFee_rampFee: 'Ramp Fee',
    //搜索组件
    search_components_countryTitle: 'Select Country',
    search_components_cryptoTitle: 'Select Crypto',
    search_components_networkTitle: 'Select Network',
    //买币 - 选择接收方式
    buy_receivingMode_tips: 'Enter your wallet address.',
    buy_receivingMode_addressTitle: 'Your ',
    buy_receivingMode_addressPlaceholder: 'Wallet Address…',
    buy_receivingMode_networkTitle: 'Your ACH address',
    buy_receivingMode_networkPlaceholder: 'Select Network',
    buy_receivingMode_addressTips1: 'Not a valid',
    buy_receivingMode_addressTips2: 'address.',
    //买币 - 选择支付方式
    buy_payment_savedTitle: 'Saved payment methods',
    buy_payment_addTitle: 'Add a new payment methods',
    buy_payment_instant: 'Instant',
    buy_payment_ending: 'Ending',
    //买币 - 确认订单 - 国际卡
    buy_configPay_title1: 'Paying with',
    buy_configPay_title2: 'CVV',
    buy_configPay_title3: 'I confirm that the payment has been completed.',
    buy_configPay_title4: 'Confirm',
    buy_configPay_title5: 'Cancel',
    //买币 - 填写表单 - 国际卡
    buy_form_firstName: 'First Name',
    buy_form_lastName: 'Last Name',
    buy_form_cardNumber: 'Card Number',
    buy_form_expirationDate: 'Expiration Date',
    buy_form_CVV: 'CVV',
    buy_form_dataTips: 'Invaid expiry date',
    buy_form_cardNumTips: 'Please enter a valid card number.',
    buy_form_cvvTips: 'Please enter a valid CVV',

    //买币 - 确认订单 - 印尼支付
    buy_configPayIDR_va_title: 'BANK',
    buy_configPayIDR_va_codeTitle: 'Payment Code',
    buy_configPayIDR_timeDownTips: 'Please complete payment with',
    buy_configPayIDR_codeTips: 'Scan QR Code to complete payment',
    buy_configPayIDR_ovo_phoneTips: 'Open OVO app to complete payment.',
    //订单结果页
    payResult_errorMessage: 'Invoice Expired!',
    payResult_timeErrorMessage: 'Payment Failed! Please check your card information.',
    payResult_feeAmount: 'Amount',
    payResult_feeAddress: 'Address',
    payResult_feeHash: 'Hash',
    payResult_feeWallet: 'Wallet',
    payResult_feePassword: 'ACH Wallet',
    payResult_createdTime: 'Order Time',
    payResult_orderNo: 'Order No',



    //菜单栏语言
    RouterMenu_Welcome:'Welcome to Alchemy Pay!',
    RouterMenu_experience:'Login to unlock the full experience',
    RouterMenu_Transactions:'Transactions',
    RouterMenu_history:'No history yet',
    RouterMenu_Language:'Language',
    RouterMenu_Service:'Terms of Service',
    RouterMenu_Policy:'Privacy Policy',
    RouterMenu_logout:'Are you sure you want to logout?',
    RouterMenu_Dismiss:'Dismiss',

    sell_configOrder_title: 'Bank Card',

    //表单页
    sell_form_tips: 'tips',
    sell_form_Name: 'Name',
    sell_form_NameTips: 'Invalid Name!',
    sell_form_Address: 'Address',
    sell_form_AddressTips: 'Invalid Address!',
    sell_form_AddressMultinomialTips: 'Address can not be null',
    sell_form_City: 'City',
    sell_form_CityTips: 'Invalid City!',
    sell_form_State: 'State',
    sell_form_StateTips: 'Invalid State!',
    sell_form_Postcode: 'Postcode',
    sell_form_PostcodeTips: 'Invalid Postcode!',
    sell_form_accountNumber: 'Account Number',
    sell_form_accountNumberTips: 'Invalid Account Number!',
    sell_form_achCode: 'ACH Code',
    sell_form_achCodeTips: 'Invalid ACH Code!',
    sell_form_contactNumber: 'Contact Number',
    sell_form_contactNumberTips: 'Invalid Contact Number!',
    sell_form_swiftCode: 'Swift Code',
    sell_form_swiftCodeTips: 'Invalid Swift Code!',
    sell_form_branchName: 'Branch Name',
    sell_form_branchNameTips: 'Invalid Branch Name!',
    sell_form_branchNameMultinomialTips: 'Branch name can not be null',
    sell_form_accountType: 'Account Type',
    sell_form_accountTypeTips: 'Invalid Account Type!',
    sell_form_bankAccountType: 'Bank Account Type',
    sell_form_bankAccountTypeTips: 'Invalid Bank Account Type!',
    sell_form_bankAccountTypeLi_Saving: 'Saving',
    sell_form_bankAccountTypeLi_Checking: 'Checking',
    sell_form_bankAccountTypeLi_TimeDeposit: 'Time Deposit',
    sell_form_bankAccountTypeLi_Others: 'Others',
    sell_form_bankCode: 'Bank Code',
    sell_form_bankCodeTips: 'Invalid Bank Code!',
    sell_form_branchCode: 'Branch Code',
    sell_form_branchCodeTips: 'Invalid Branch Code!',
    sell_form_locationId: 'Location Id',
    sell_form_locationIdTips: 'Invalid Location Id!',
    sell_form_CPFNumber: 'CPF Number',
    sell_form_CPFNumberTips: 'Invalid CPF Number!',
    sell_form_IDType: 'ID Type',
    sell_form_IDTypeTips: 'Invalid ID Type!',
    sell_form_bankAccountTypeLi_CUIT: 'CUIT',
    sell_form_bankAccountTypeLi_CUIL: 'CUIL',
    sell_form_IDNumber: 'ID Number',
    sell_form_IDNumberTips: 'Invalid ID Number!',
    sell_form_accountTypeLi_Maestra: 'Maestra',
    sell_form_IDTypeLi_DNI: 'DNI',
    sell_form_IDTypeLi_RUC: 'RUC',
    sell_form_IDTypeLi_CE: 'CE',
    sell_form_IDTypeLi_PASS: 'PASS',
    sell_form_RUTNumber: 'RUT Number',
    sell_form_RUTNumberTips: 'Invalid RUT Number!',
    sell_form_BSBCode: 'BSB Code',
    sell_form_BSBCodeTips: 'Invalid BSB Code!',
    sell_form_Email: 'Email',
    sell_form_EmailTips: 'Invalid Email!',
    sell_form_SortCode: 'Sort Code',
    sell_form_SortCodeTips: 'Invalid Sort Code!',
    sell_form_IFSC: 'IFSC',
    sell_form_IFSCTips: 'Invalid IFSC!',
    sell_form_IDTypeLi_NIT: 'NIT',
    sell_form_IDTypeLi_CC: 'CC',
    sell_form_IDTypeLi_TI: 'TI',
    sell_form_IDTypeLi_RUT: 'RUT',
    sell_form_IDTypeLi_CI: 'CI',
    sell_form_TransitNumber: 'Transit Number',
    sell_form_TransitNumberTips: 'Invalid Transit Number!',
    sell_form_bankSwiftTips: 'Swift code and location id cannot be empty at the same time',
    sell_form_bankSwiftCodeTips: 'Bank code and swift code cannot be empty at the same time',
    // sell_form_bankCodeLocationIdTips: 'Bank code and location id cannot be empty at the same time',


    // 后增
    history_state_Complete: 'Complete',
    history_state_Processing: 'Processing',
    history_state_Transfer: 'Crypto sent',
    history_listTitle1: 'Order No',
    history_listTitle2: 'Crypto',
    history_listTitle3: 'Hash ID',
    fee_listTitle_price: 'Price',
    sell_order_title: 'state-content',
    fee_tips: 'Based on payment method',
    search_components_Popular: 'Popular',
    search_components_All: 'All',
    search_components_placeHolder: 'Search here… ',
    result_stateTo4_your: 'Your',
    result_stateTo4: 'order is being processed and will be sent to your designated address shortly. ',
    result_stateTo5: 'has transfered to your wallt address.',
    result_returnText_merchant: 'Return',
    buy_configPayIDR_ovo_title: 'OVO Phone',
    login_getCode: 'Resend code',
    login_VerifyCode:'Please enter the six-digit verification code',
    login_Agreement:'Please tick the User Agreement',


  },
  // 账号禁用页面
  accountDisabled:{
    title:'Account disabled',
    content:'Unfortunately your account has been disabled due to noncompliance with our terms and conditions',
    buttonText:'Learn more'
  }
}
