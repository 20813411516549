module.exports = {
  language: '繁体中文',
  //首页
  homePage: {
    tab_buy: '買幣',
    tab_sell: '賣幣',
    //买币
    youPay: '您支付',
    youBuyGet: '你購買',
    buyFee_title1: '您獲得',
    //卖币
    youSell: '您賣出',
    youSellGet: '您獲得',
    sellFee_title1: '您賣出',
    sellFee_title2: '獲得',
    sellFee_rampFee: '服務費',
    home_minAmountTips: '最低交易額為',
    home_maxAmountTips: '最高交易額為',
    minAmountTips: '最低交易額為',
    maxAmountTips: '最高交易額為',

    bottomTips1: '繼續 · 賣出',
    bottomTips2: '繼續 · 買入',
    receivingMode: '在付款前請確認你的錢包地址是正確的。不要將加密幣打給他人的地址，不匹配的地址會導致訂單失敗和財產損失。'
    // bottomTips2: '由...提供支持',
  },
  //世界杯多语言文案
  worldCuo: {
    World: '2022 Qatar World Cup',
    discount: '5折優惠',
    Rampfee: '你的首單手續費權益',
    Details: '詳情',
    WorldCup: 'World Cup',
    WorldDiscount: 'World Cup 折扣 ',
    Participated: '已參與',
    order: '这一份订单',
    countdown: '活動倒計時',
    countdownstart: '活動即將開始'
  },
  //导航文案
  navTabTitle:{
    Completepayment:'完成支付',
  },
  vndChildren: {
    amount:'數量',
    oneTitle:' 前往銀行應用程序，掃描二維碼或手動輸入收款人ID',
    twoTitle:' 在消息框中填寫轉賬內容',
    threeTitle:' 進行匯款',
    Accountname:'帳戶顯示名稱',
    AccountID:'帳戶ID',
    Transfercontent:'傳輸內容',
  },


  //墨西哥支付页面改版本案
  mexPayment:{
    tiemOut:'訂單將在 ',
    PleasePay:'請付款',
    title1:'打開您的銀行申請。輸入此參考號',
    title2:'輸入賬號註冊信息',
    CLABEinterbancaria:'CLABE 銀行間：',
    Destinationbank:'目的地銀行：',
    Beneficiary:'受益人：',
    Payamount:'支付金額：',
    title3:'如果您的付款長期未完成，我們建議您將付款文件上傳至我們的客服。 這將加快我們確認您的付款的速度。'
  },
  //等待结果组件
  buypayWait: {
    Processing: '正在處理中',
    Waiting: '正在等待您的購買…',
    purchase: '請轉到付款頁面完成您的購買.',
    Afterpayment: '付款後',
    Wepurchase: '我們將在收到您的購買時向您發送電子郵件，以便您稍後可以輕鬆找到。',
    historyorder: '查看訂單歷史記錄'
  },
  //菲律宾页面
  phpPayment:{
    PaymentGuide:'付款指南',
    BillNumber:'數字彙款號碼',
    PAYSO1:'進入Gcash錢包，點擊“Bills”，選擇bill payment',
    PAYSO2:'搜索並選擇“PAYSO”',
    PAYSO3:'填寫這些信息，然後點擊【NEXT】】',
    Incorrect:'請確保您轉帳給我們的金額準確無誤。 錯誤的資訊或金額將導致訂單失敗或資金損失.'

  },
  //买币表单新增文案
  sellFormPageTitle: {
    Information: '使用此資訊',
    AccountNumber: '賬戶號碼',
    More: '更多',
    Attention: '注意',
    ReturnediInformation: '請確認銀行賬戶屬於你並且信息正確。退款交易將會被我們的銀行合作方收取$25費用。',
    buy_configphp_codeTips: '通過QRPH掃碼並完成支付'
  },
  //买币费用组件
  buyFee_components: {
    codeSecond: 's',
    youGet: '你購買',
    title2: '花費',
    rampFee: '服務費',
    rampFeeTips: '低至',
    networkFee: '網絡費',
    tips: '這由AlchemyPay收取，以支付與支付處理相關的費用。這可能因您稍後選擇的付款方式而異。'
  },
  //卖币费用组件
  sellFee_components: {
    codeSecond: 's',
    title1: '您賣出',
    title2: '獲得',
    rampFee: '服務費',
    tips: '基於支付方式',
    RampFeeTips: '低至'
  },

  //搜索组件
  search_components: {
    recent: '最近',
    availableNow: '現在可用'
  },

  //买币结果页
  payResult: {
    //支付结果提示
    tipsText_40010: '明确卡信息错误',
    tipsText_40011: '明确卡信息错误',
    tipsText_40019: '明确卡信息错误',
    tipsText_40020: '卡余额不足',
    tipsText_40030: '卡受限-所属地区',
    tipsText_40031: '卡受限-发卡行限制',
    tipsText_40032: '卡受限-过期卡',
    tipsText_40033: '卡受限-发卡行限制-风险控制',
    tipsText_40034: '卡受限-风险控制',
    tipsText_40090: '待确认问题'
  },

  //菜单
  menu: {
    home: '首頁',
    menu: '菜單',
    transactions: '歷史訂單記錄',
    language: '語言',
    privacy: '隱私政策',
    logOut: '退出',
    service: '服務條款',
    RouterMenu_experience: '登錄方可體驗全部功能',
    login_Youlogged: '你已經在登陸頁了',
    RouterMenu_Welcome: '歡迎來到Alchemy Pay!',
    history: '沒有交易記錄',
    loginOut_Dismiss: '取消',
    loginOut_title: '確定退出?'
  },
  //登陆页
  loginPage: {
    enterEmail: '請告知您的電子郵件地址',
    enterEmail1: '這是否為您的電子郵件地址？',
    emailanother: '使用其他電子郵件地址登錄',
    emailNotyou: '不是您?',
    codeTitle1: '請輸入您剛剛收到的登錄代碼',
    necessary: '必需的'
  },
  //卖币退款页面
  RefundFonts: {
    RefundNavTitle: ' 申請退款',
    RefundTitle: '輸入Tron (USDT)地址',
    Refundplaceholder: '輸入錢包地址',
    RefundInvalidAddress: '無效地址',
    Refundattention: '請注意',
    RefundPlease: ' 請確保您輸入的地址正確無誤，並且屬於您選擇的網路。如果您輸入不相容的地址，您將失去您的資金。',
    RefundButtonConfirm: '確認'
  },
  //kyc验证页面
  kycVerificationFonts: {
    kycminutestitle: '請驗證您的身份。通常需要大約2分鐘。',
    kycPrepareyour: '準備好您的身份證件',
    kycminutesdocument: '持身份證件準備自拍',
    kycButtonminutes: '開始驗證',
    kyccompleted1: '恭喜！',
    kyccompleted2: '驗證通過。',
    kycButtoncompleted: '繼續支付',
    kycPayLimitUpTips: '付款限額最高為',
    kycError: '您的驗證失敗。',
    kycRiskAccount: '該賬戶存在風險，此刻不可進行交易。',
    kycButtonError: ' 返回首頁 ',
    kycButtonTryAgain: '再試一次 ',

    kyc2_startTitle: '請提供您的地址材料。',
    kycErrorTips: '請確保您的地址真實可靠',
    kycErrorTryAgain_title: '您的驗證失敗。',
    kycErrorTryAgain_tipsTitle: '請確保',
    kycErrorTryAgain_tips1: '您提交了自己的身份證件。',
    kycErrorTryAgain_tips2: '您身份證件上的姓名與您付款的持卡人姓名相同。',
    kycErrorTryAgain_tips3: '您沒有使用多個身份證件進行KYC驗證。'
  },
  //卖币支付页+订单状态页
  sellOrderFonts: {
    minimumAmount: '您的訂單低於其最低金額',
    maximumAmount: '您的訂單高於其最大金額',
    sellTitle: '賣出',
    Sellorder_Network: '選擇網路',
    Sellorder_Ramp: '手續費 ',
    Sellorder_PleaseCorrect: '請確保所選網路正確無誤，以免資金損失。',
    Sellorder_transferred: '我已將加密貨幣轉賬到指定的地址和網路。',
    Sellorder_ButtonNot: '還沒有',
    Sellorder_ButtonConfirm: '確認',
    Sellorder_ButtonHaveSent: ' 我已發送 ',
    Sellorder_ButtongoHome: '  返回首頁',
    Sellorder_ButtongoHistory: '歷史訂單',
    Sellorder_timeOutTitle: ' 您的訂單已過期，請不要向此地址匯款，否則資金可能遭遇丟失。',
    Sellorder_StatusTitle: '您可以離開此頁面。訂單更新將發送到您的電子郵箱，並可在訂單歷史記錄中進行檢查。',
    Sellorder_StatusNav: '訂單狀態',
    Sellorder_StatusCryptoSent: '加密幣匯出',
    Sellorder_StatusBlockConfirmed: '區塊確認',
    Sellorder_StatusConfirmOrder: '確認訂單',
    Sellorder_StatusOrderconfirmed: '您的訂單已獲確認',
    Sellorder_StatusInTransfer: '正在轉賬',
    Sellorder_StatusInTransferfiat: '您的法幣正在轉賬',
    Sellorder_StatusResult: '結果',
    Sellorder_StatusResultCompleted: '訂單已完成',
    Sellorder_StatusResultfail: '訂單失敗',
    //卖币状态
    Sellorder_transfer: '請轉賬 ',
    Sellorder_within: '至該地址 ',
    Sellorder_will: '將',
    Sellorder_get: '獲 ',
    Sellorder_for: ' 取',
    Sellorder_Id: '訂單編號',
    Sellorder_Address: '地址',
    Sellorder_Fail: '失敗原因',
    Sellorder_You: '您賣出',
    Sellorder_to: '獲得',
    SellOrder_modified: '銀行卡資訊目前無法修改'
  },

  //卖币历史页加订单详情
  sellHistory: {
    sell: '賣出',
    sellOrderAmount: '訂單金額',
    sellOrderSellCrypto: '賣出加密幣',
    sellOrderActualAmount: '實際金額',
    sellOrderFinalTotal: '最終金額',
    sellOrderBlockConfirmed: '區塊確認',
    sellOrderPayNow: '立即支付',
    sellConfirmOrder: '確認訂單',
    sellInTransfer: '正在轉賬',
    sellCompleted: '完成轉賬',
    sellFailed: '轉賬失敗',
    selltransferrejected: '法幣轉賬被銀行退回。請更新銀行資料。',
    selltransRequest: '申請USDT退款',
    selltransUpdate: '更新銀行資料',
    sellClosed: '已關閉',
    sellRefunding: '退款中',
    sellRefund: '已退款',
    sellSellcryptonow: '即刻賣出加密幣',
    sellstarted: '試著賣出少量加密幣',
    //卖币订单详情
    selltransRequestof: '申請USDT退款',
    sellUpdateInformation: '更新資料',
    sellPrice: '價格',
    sellTotal: '總計',
    sellRampFee: '手續費',
    sellReturnfee: '退貨費',
    sellFinalTotal: '最終金額',
    sellStatus: '狀態',
    sellOrderID: '訂單編號',
    sellOrderTime: '訂單時間',
    sellConfirmedTime: '確認時間',
    sellTransferTime: '轉賬時間',
    sellRefundTime: '退款時間',
    sellNetwork: '網路',
    sellAddress: '地址',
    sellTxID: 'TxID',
    sellRecipientAccount: '收款人賬戶'
  },
  //买币历史订单页+买币订单详情页
  buyHistory: {
    buttonCard: '更改銀行卡',
    buttonAgain: '再試一次',
    buttonMethod: '更改付款方式',
    buttonInformation: '修改卡資訊',
    buttonBinance: '幣安支付',
    buttonApplePay: 'Apple Pay',
    buttonGooglePay: 'Google Pay',
    tryToBinance:'嘗試Binance Pay',
    tryToApplePay: '嘗試Apple Pay',
    tryToGooglePay: '嘗試Google Pay',
    qrCode:'嘗試QR Code支付',
    Philippines: '嘗試銀行轉帳支付',
    eWallet: '嘗試使用電子錢包支付',
    buy: '買入',
    buyCrypto: '買入加密幣',
    buyCryptoHideClosed: '隱藏已關閉訂單',
    buyPrice: ' 價格 ',
    buyOrderAmount: '訂單金額 ',
    buyTotal: '總計 ',
    buyRampFee: '手續費 ',
    buyNetworkFee: '網路費 ',
    buyNetwork: '網路',
    buyAddress: '地址 ',
    buyOrderID: '訂單編號',
    buyOrdeTime: '訂單時間',
    buyPaymentTime: '支付時間',
    buyTxID: 'TxID',
    buyPaymentMethod: '支付方式',
    buyPayerAccount: '付款賬戶',
    buyUnpaid: '未支付',
    buyProcessing: '處理中',
    buyCompleted: '完成',
    buyFailed: '失敗',
    buyClosed: '已關閉',
    history_noListText: '無交易',
    history_noListText2: '試著買入少量加密幣',
    history_noListButton: '立即買入加密幣',
    history_noMore: '無更多交易記錄'
  },
  //买币接收方式页
  buyReceivingMethod: {
    networkTitle1: '輸入您的',
    networkTitle2: '地址',
    addressTips1: '為無效',
    addressTips2: '地址',
    memo: '單據',
    memoTips: '非有效的ACH單據。',
    ENSTips: '輸入有效的錢包地址、ENS或Unstoppable域',
    supported: '支持的域',
    Unstoppable: '我们支持此资产的 Unstoppable 域'
  },

  //按钮组件
  button_components: {
    proceed: '繼續'
  },

  //选择网络组件
  network_components: {
    network: '網路'
  },

  //---------------------------- 支付方式信息组件 ----------------------------
  PayMethodInfo_components: {
    title: '訂單摘要',
    title2: '您選擇的付款方式是',
  },
  //---------------------------- 扫码QR Code组件 ----------------------------
  QrCode_components: {
    title1: '支付',
    title2: '完成您的購買.',
    title3: '請打開您的支付申請，掃描下方二維碼完成支付.',
    copy: '複製',
  },
  //---------------------------- 钱包地址和网络信息组件 ----------------------------
  WalletAddress_components: {
    title1: '你的',
    title2: '錢包',
  },
  //---------------------------- 表单信息 ----------------------------
  formInfo: {
    //新增
    fullName_placeholder: '輸入全名',
    phone_title: '請輸入您的電話號碼',
    id_title: '輸入身份證號碼',
    id_placeholder1: '輸入',
    id_placeholder2: '數字身份證號碼',
    bankAccount_placeholder: '選擇您的賬戶類型',
    accountNumber_placeholder: '輸入22位身份證號碼',
    bankCode_placeholder: '輸入 3 位數的 ID 號',
    //vnd 选择bank code
    vnd_bankCode_placeholder: '選擇銀行代碼',
  },

  //买币国际卡表单组件
  buyForm_components: {
    formTitle: '輸入您的銀行卡信息。',
    firstName: '名字',
    lastName: '姓氏',
    nameTips: '無效名字!',
    cardNumber: '卡號',
    cardNumTips: '您輸入的卡號無效',
    expirationDate: '有效日期',
    dataTips: '有效日期輸入有誤',
    cvv: 'CVV',
    cvvTips: '請輸入正確的CVV',
    NameError: '名稱與卡不匹配，請修復它'
  },

  creditCard: {
    payingWith: '支付方式',
    card: '銀行卡',
    cvv: 'CVV'
  },

  //买币确定订单页 - 巴西支付
  configPay_brl: {
    phoneNumber: '手機號碼',
    fullName: '姓名',
    formTitle: '輸入您的詳細信息',
    code_tips: '通過PIX掃描二維碼以完成付款。',
    name_tips: '無效姓名!',
    id_tips: '無效Id!',
    number_tips: '無效手機號碼!',
    Country: '選擇國家/地區'
  },

  opmPayment: {
    banco: 'Banco'
  },

  payConfig_components: {
    title_THB: '正在支付',
    button_THB: '查詢訂單',
    button_timeOut: '返回首頁',
    button_timeOut1: '返回首頁',
    double: '请不要重复付款',
    completed: '請確認您的轉帳已完成。',
    timeorder: '此訂單已超時。',
    checkout: '您確定離開結帳櫃檯嗎？',
    buttonLeave: '現在離開'
  },

  //卖币表单页
  sellForm: {
    phone: '手機',
    phoneTips: '無效手機號!',
    tips: '提示',
    fullName: '全名',
    lastName: '姓',
    NameTips: '無效名字!',
    Address: '地址',
    AddressTips: '無效地址!',
    AddressMultinomialTips: '地址不可為空',
    City: '城市',
    CityTips: '無效城市名!',
    State: '州/省',
    StateTips: '無效州/省名!',
    Postcode: '郵政編碼',
    PostcodeTips: '無效郵政編碼!',
    accountNumber: '賬號',
    accountNumberTips: '無效賬號!',
    achCode: 'ACH 碼',
    achCodeTips: '無效ACH 碼!',
    contactNumber: '聯係號碼',
    contactNumberTips: '無效聯係號碼!',
    swiftCode: 'Swift 碼',
    swiftCodeTips: '無效Swift碼!',
    branchName: '支行名稱',
    branchNameTips: '無效支行名稱！',
    branchNameMultinomialTips: '支行名稱不可爲空',
    accountType: '賬戶類型',
    accountTypeTips: '無效賬戶類型!',
    bankAccountType: '銀行賬戶類型',
    bankAccountTypeTips: '無效銀行賬戶類型!',
    bankAccountTypeLi_Saving: '儲蓄賬戶',
    bankAccountTypeLi_Checking: '支票賬戶',
    bankAccountTypeLi_TimeDeposit: '定期賬戶',
    bankAccountTypeLi_Others: '其他',
    bankCode: '銀行識別碼',
    bankCodeTips: '無效銀行識別碼!',
    branchCode: '分行行號',
    branchCodeTips: '無效分行行號!',
    locationId: '位置編碼',
    locationIdTips: '無效位置編碼!',
    CPFNumber: 'CPF 號',
    CPFNumberTips: '無效CPF 號!',
    IDType: 'ID 類型',
    IDTypeTips: '無效ID 類型!',
    bankAccountTypeLi_CUIT: 'CUIT',
    bankAccountTypeLi_CUIL: 'CUIL',
    IDNumber: 'ID 號碼',
    IDNumberTips: '無效ID 號碼!',
    accountTypeLi_Maestra: 'Maestra',
    IDTypeLi_DNI: 'DNI',
    IDTypeLi_RUC: 'RUC',
    IDTypeLi_CE: 'CE',
    IDTypeLi_PASS: 'PASS',
    RUTNumber: 'RUT 號',
    RUTNumberTips: '無效RUT 號!',
    BSBCode: 'BSB 編碼',
    BSBCodeTips: '無效BSB 編碼!',
    Email: 'Email',
    EmailTips: '無效Email地址!',
    SortCode: '識別代碼',
    SortCodeTips: '無效識別代碼!',
    IFSC: 'IFSC',
    IFSCTips: '無效IFSC!',
    IDTypeLi_NIT: 'NIT',
    IDTypeLi_CC: 'CC',
    IDTypeLi_TI: 'TI',
    IDTypeLi_RUT: 'RUT',
    IDTypeLi_CI: 'CI',
    TransitNumber: '轉賬編號',
    TransitNumberTips: '無效轉賬編號!',
    bankSwiftTips: 'Swift碼和位置編碼不能同時為空',
    bankSwiftCodeTips: '銀行識別碼和swift代碼不能同時為空',
    bankCodeLocationIdTips: '銀行代碼和位置ID不能同時為空'
  },
  //买币阿根廷支付
  ArgentinaPay: {
    BankCode: '銀行代碼',
    ChooseBank: '選擇您的銀行',
    selectcard: '請選擇銀行',
    BankAccount: '銀行賬戶',
    cardnumber: '請輸入正確的卡號',
    Bankcode: '銀行代碼',
    correctcode: '請輸入正確的密碼',
    ID: 'ID',
    SelectBank: '選擇銀行',
    phone: '電話號碼',
    phoneTips: '無效的電話號碼!',
    paymentResultError: '銀行賬戶信息不正確。 請檢查並再次購買。',
    Choose: '選擇您的賬戶類型',
    chequing: '支票',
    Saving: '儲蓄',
    AccountNumber: '帳號',
    fullNameTips: '全名無效',
  },
  //买币选择支付方式页
  buyPayWay: {
    BINANCEPAY:'BINANCE PAY',
    qrCode: 'QR Code',
    Khipu: '銀行轉帳',
    ThaiQR: 'ThaiQR',
    ChangeCard: '更改銀行卡',
    towMethod: '更改付款方式',
    Philippines: '銀行轉帳',
    saas: 'PayNow',
    recentlyUsed: '保存的付款方式',
    paymentMethod: '添加新的付款方式',
    rampFee: '服務費',
    card: '銀行卡',
    card1: '銀行卡',
    creditCard: '信用卡',
    bankTransfer: '即時支付',
    bancoTransfer: '銀行轉賬',
    opm: 'SPEI(OPM)',
    virtualAccount: '銀行轉帳',
    eWallet: '電子錢包',
    binancePay: 'BINANCE PAY',
    applePay: 'Apple Pay',
    googlePay: 'Google Pay',
    promptPay: 'Prompt Pay',
    addCardTitle: '添加新卡',
    useCardTitle: '使用新卡',
    edit: '編輯',
    cancel: '取消',
    support: '支持',
    cardLimitTips: '已達到6張卡的限製'
  },
  //买币结果成功文案
  paymentResult: {
    Paymentsuccess: '支付成功!',
    Paymenttransfer: '將轉賬至您的錢包地址。 ',
    Paymentemail: '我們將通過電郵向您通報結果 '
  },

  nav: {
    //kyc姓名为空提示
    cardinformation: '卡信息不正確',
    //风险账户文案
    accountTraded: '該賬戶存在風險，此刻不可進行交易。',
    //买币确认订单文案提示
    buyConfigPaytitle: '繼續，即表示您同意我們的 ',
    /**
     * 按钮文案
     */
    Continue: '繼續',
    Confirm: '確認',
    ConfirmPayment: '確認付款',
    queryOderState: '我已經完成付款',
    orderRsult: '返回首頁',
    Proceed: '進行',

    /**
     * 组件
     */
    //网络地址组件
    networkAddress_components_title: '地址',
    //协议组件
    agreement_components_text1: '兹授權',
    agreement_components_text2: 'AlchemyPay ',
    agreement_components_text3: '當日從我選擇的付款方式中扣除上述金額，我充分瞭解此操作不能取消、召回或退款。',
    //查询订单状态提示
    orderTips_title: '系統沒有檢索到付款資訊',
    orderTips_text1: '抱歉，我們沒有檢測到您的付款信息。 如果您確認付款，請在此頁面稍作停留，一旦我們收到您的付款，我們會將相應金額的',
    orderTips_text2: '轉給您。',
    orderTips_buttonText: '我已瞭解',

    /**
     * 路由
     */
    routerName_buy: '買幣',
    routerName_sell: '賣幣',
    routerName_email: '輸入email地址',
    routerName_emailCode: '驗證碼',
    routerName_Transactions: '交易記錄',
    routerName_receivingMode: '接收',
    routerName_paymentMethod: '選擇付款方式',
    routerName_basisIdAuth: 'basis-Id-Auth',
    routerName_buyForm: '輸入銀行卡信息',
    routerName_creditCardConfig: '確認付款',
    routerName_buyPayment: '付款',
    routerName_buyOtherWayPay: '確認付款',
    routerName_paymentResult: '付款結果',
    routerName_sellForm: '輸入信息',
    routerName_configSell: '確認',
    routerName_sellOrder: '訂單',
    routerName_errorFormInfo: '此卡暫不可用',
    routerName_errorFormInfo2: '卡信息不正確',

    /**
     * 菜单
     */
    login: '登錄',
    login_required: '格式錯誤.',
    loginOut: '退出',
    loginOut_title: '確定退出?',
    loginOut_Dismiss: '取消',
    // enterEmail: '你的電子郵件地址是什麼？',
    // enterEmail1: '這是您的電子郵件位址嗎？',
    // emailanother:'使用其他電子郵件地址登錄',
    loginTitle1: '通過Alchemy Pay結算',
    loginTitle2: '在數以百計的加密幣APP中最快捷、安全的結算方式。',
    // codeTitle1: '輸入您剛剛收到的登錄代碼',
    codeTitle2: '未收到？',
    codeTitle3: '將發送新的確認碼',
    codeSecond: 's',
    code_: ' 我同意Alchemy Pay之',
    code_name: '服務條款',
    code_and: '和',
    code_name2: '隱私政策',
    code_text: '我同意Alchemy Pay之',
    menu: '菜單',
    menu_transactions: '歷史訂單記錄',
    menu_language: '語言',
    menu_terms: '服務條款',
    menu_privacy: '隱私政策',
    menu_logOut: '退出',
    RouterMenu_experience: '登錄方可體驗全部功能',
    login_Youlogged: '你已經在登陸頁了',

    /**
     * 首页
     */
    copyTips: '複製成功',
    // 买币标题
    home_buyTitle: '買幣',
    //卖币标题
    home_sellTitle: '賣幣',
    home_minAmountTips: '最低交易額為',
    home_maxAmountTips: '最高交易額為',
    home_feeTimeDownTitle: '剩餘時間',
    home_feeTimeDown: '引用更新數據',
    home_feeRampFeeTips: '低至',

    /**
     * 买币
     */
    home_youPay: '您支付',
    home_youBuyGet: '你購買',
    home_buyFee_timeDownTitle: '剩餘時間',
    home_buyFee_timeDown: '引用更新數據',
    home_buyFee_title1: '您得到',
    home_buyFee_title2: '花費',
    home_buyFee_rampFee: '服務費',
    home_buyFee_networkFee: '網絡費',
    //卖币
    home_youSell: '您賣出',
    home_youSellGet: '您獲得',
    home_sellFee_title1: '您賣出',
    home_sellFee_title2: '獲得',
    home_sellFee_rampFee: '服務費',
    //搜索组件
    search_components_countryTitle: '選擇國家',
    search_components_cryptoTitle: '選擇幣種',
    search_components_networkTitle: '選擇網路',
    //买币 - 选择接收方式
    buy_receivingMode_tips: '輸入您的錢包地址',
    buy_receivingMode_addressTitle: '您的',
    buy_receivingMode_addressPlaceholder: '錢包地址…',
    buy_receivingMode_networkTitle: '您的ACH地址',
    buy_receivingMode_networkPlaceholder: '選擇網路',
    buy_receivingMode_addressTips1: '為無效',
    buy_receivingMode_addressTips2: '地址',
    //买币 - 选择支付方式
    buy_payment_savedTitle: '已保存的付款方式',
    buy_payment_addTitle: '添加新的付款方式',
    buy_payment_instant: '實時支付',
    buy_payment_ending: '支付完畢',
    //买币 - 确认订单 - 国际卡
    buy_configPay_title1: '支付方式',
    buy_configPay_title2: 'CVV',
    buy_configPay_title3: '我確認付款已完成。',
    buy_configPay_title4: '確定',
    buy_configPay_title5: '取消',
    //买币 - 填写表单 - 国际卡
    buy_form_firstName: '名字',
    buy_form_lastName: '姓氏',
    buy_form_cardNumber: '卡號',
    buy_form_expirationDate: '有效日期',
    buy_form_CVV: 'CVV',
    buy_form_dataTips: '有效日期輸入有誤',
    buy_form_cardNumTips: '請輸入正確的卡信息。',
    buy_form_cvvTips: '請輸入正確的CVV',

    //买币 - 确认订单 - 印尼支付
    buy_configPayIDR_va_title: '銀行',
    buy_configPayIDR_va_codeTitle: '支付碼',
    buy_configPayIDR_timeDownTips: '請用以下方式完成支付',
    buy_configPayIDR_codeTips: '掃描二維碼以完成支付',
    buy_configPayIDR_ovo_phoneTips: '打開 OVO 應用程序完成支付。',
    //订单结果页
    payResult_errorMessage: '支付超時！',
    payResult_timeErrorMessage: '支付失敗！',
    payResult_feeAmount: '交易金額',
    payResult_feeAddress: '錢包地址',
    payResult_feeHash: 'Hash',
    payResult_feeWallet: 'ACH 錢包',
    payResult_feePassword: 'ACH 錢包',
    payResult_createdTime: '訂單時間',
    payResult_orderNo: '訂單號',

    /**
     * 卖币
     */
    //卖币状态

    Sellorder_will: '將',
    Sellorder_get: '獲取',
    Sellorder_for: '  從',
    Sellorder_details: '請修改您銀行卡的詳細信息',
    Sellorder_page: '若繼續賣幣，請返回首頁',

    Sellorder_Transfer: '等待结果',

    Sellorder_Network: '網路',

    Sellorder_Card: '卡',
    Sellorder_Fail: '失敗原因',
    Sellorder_Continue: '繼續賣幣',
    Sellorder_QRCode: '掃描二維碼',
    Sellorder_You: '你',
    SellOrder_modified: '卡資訊目前無法修改',
    //菜单栏语言
    RouterMenu_Welcome: '歡迎來到Alchemy Pay!',
    RouterMenu_history: '沒有交易記錄',
    //确认订单页
    sell_configOrder_title: '銀行卡',

    //新增

    history_state_Complete: '完成',
    history_state_Processing: '正在處理',
    history_state_Transfer: '加密幣已發送',
    history_listTitle1: '訂單號',
    history_listTitle2: '交易數量',
    history_listTitle3: '服務識別號',
    fee_listTitle_price: '幣價',
    sell_order_title: '狀態-内容',
    fee_tips: '基於支付方式',
    search_components_Popular: '廣受歡迎',
    search_components_All: '全部',
    search_components_placeHolder: '在此處搜索… ',
    result_stateTo4_your: '您的',
    result_stateTo4: '訂單正在處理中，很快將發送到您指定的地址。',
    result_stateTo5: '已轉至您的錢包地址。',
    result_returnText_merchant: ' 返回',
    buy_configPayIDR_ovo_title: 'OVO電話',
    login_getCode: '重新發送驗證碼',
    login_VerifyCode: '請輸入六位數驗證碼',
    login_Agreement: '請勾選用戶協定'
  },
  // 账号禁用页面
  accountDisabled: {
    title: '帳戶已禁用',
    content: '很遺憾，由於不遵守我們的條款和條件，您的帳戶已被禁用',
    buttonText: '瞭解更多'
  }
};
