<template>
  <div id="buyCrypto">
    <!-- 买币功能模块 -->
    <div class="buyCrypto_content" @scroll="scroll">
      <div class="methods_select cursor" :class="{'inputFocus': inputFocus}">
        <div class="methods_select-left">
          <div class="form_title pay_title">{{ $t('homePage.youPay') }}</div>
          <van-field class="pay_input" :type="youPaytype" @input="inputChange"
                     v-model.number="payAmount" pattern="[0-9]*" inputmode="decimal" @blur="youPayBlur"
                     @focus="inputFocus=true" :disabled="payAmountState" placeholder="0.00"/>
        </div>
        <div class="get_company" @click="openSearch('payCurrency')">
          <div class="getImg">
            <img :src="fiatCurrencyInfo.flag">
          </div>
          <div class="getText">{{ fiatCurrencyInfo.fiat }}</div>
          <div class="rightIcon"><img src="@/assets/images/homeRight-icon.png"></div>
        </div>
        <div class="warning_text" v-if="warningTextState" v-html="payAmount_tips"></div>
      </div>

      <div class="methods_select cursor get_methods_select">
        <div class="methods_select-left">
          <div class="form_title">{{ $t('homePage.buyFee_title1') }}</div>
          <div class="get_input">
            <span v-if="cryptoAmount!==''">{{ cryptoAmount }}</span>
            <span class="no_getAmount" v-else>0.00</span>
          </div>
        </div>
        <div class="get_company" @click="openSearch('currency')">
          <div class="getImg networkImg">
            <img :src="cryptoInfo.cryptoIcon">
            <div class="networkIcon" v-if="networkInfo.networkIcon && networkInfo.networkIcon"><img :src="networkInfo.networkIcon"></div>
          </div>
          <div class="getText">{{ cryptoInfo.crypto }}</div>
          <div class="rightIcon"><img src="@/assets/images/homeRight-icon.png"></div>
        </div>
      </div>

      <!-- 费用模块 -->
      <div class="calculationProcess" v-if="detailedInfo_state" >
        <IncludedDetails ref="includedDetails_ref" :type="1" :isHome="true"/> <!--  :isHome="true" :useFee="true" :network="$store.state.buyRouterParams.buyNetwork.network" -->
      </div>
    </div>

    <footer>
      <button class="continue" @click="nextStep" :disabled="!continueState" :class="{'continue_true': continueState}">
        {{ $t('homePage.bottomTips2') }} {{ cryptoInfo.crypto }}
        <img class="rightIcon" src="../../../assets/images/button-right-icon.svg" alt="" v-if="lodingStatus">
        <van-loading class="rightIcon" type="spinner" color="#fff" v-else/>
      </button>
      <div class="footer_logoView">
        <p class="logoText">Powered By</p>
        <div class="logo">
          <img src="@/assets/images/homePageLogo3.png" alt="">
<!--          <img src="../../../assets/images/homePageLogo.png" alt="">-->
<!--          <img src="../../../assets/images/homePageLogo1.png" alt="">-->
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
/**
 * 页面小数同意处理
 * 数值>0，小数点后保留2位
 * 数值<0，小数点后最多保留6位
 */
import common from "../../../utils/common";
import { hashQuery } from '@/utils/publicRequest'
import IncludedDetails from "../../../components/IncludedDetails/IncludedDetails";
import { mapState } from "vuex";
import {debounce,noData} from "@/utils";
import store from "@/store";

export default {
  name: "buyCrypto",
  components: {IncludedDetails},
  props: ['allBasicData'],
  data(){
    return{
      //you pay Prompt information
      warningTextState: false,

      //All data
      basicData: {},

      youPaytype: 'number', // Number | digit
      payAmount_index: 0,

      //Expense information
      // feeInfo: {},
      detailedInfo_state: false,

      //you get Currency information
      cryptoSate: false,

      //Payment methods
      allPayMethods: [],
      //Exchange rate in payment currency
      exchangeRate: 0,

      triggerType: "hover",

      inputFocus: false,
      lodingStatus: true,
      //商户携带参数输入框状态
      fiatAmount_inputStatus: false,
      //商户选择法币和国家状态
      fiatSate: false,

      //基本数据接口状
      pageInfoState: false,

      payAmount_tips: '',
    }
  },
  activated(){
    //获取接收方式页网络数据,原因 - 输入金额不满足网络最大值最小值
    let fiatmaxMin = {
      minAmount: this.fiatmaxMin.minAmount,
      maxAmount: this.fiatmaxMin.maxAmount,
    }
    this.$store.commit("faitBuyPage/set_fiatmaxMin",fiatmaxMin);
    this.maxMinCheck();
    //返回首页情况菜单栏状态
    this.$store.state.routerViewState = true
  },
  computed: {
    //新增优化内容
    ...mapState('faitBuyPage',{
      fiatCurrencyInfo: state => state.fiatCurrencyInfo,
      cryptoInfo: state => state.cryptoInfo,
      networkInfo: state => state.networkInfo,
      fiatmaxMin: state => state.fiatmaxMin,
      fiatCurrencyAmount: state => state.fiatCurrencyAmount,
      cryptoAmount: state => state.cryptoAmount,
      feeInfo: state => state.feeInfo,
      rawFiat: state => state.rawFiat,
      checkMerchant: state => state.checkMerchant,
    }),
    //法币金额
    payAmount: {
      get() {
        return this.fiatCurrencyAmount;
      },
      set() {
        return this.fiatCurrencyAmount
      }
    },

    //you pay Input status - 数据加载后放开状态
    payAmountState(){
      if(this.fiatmaxMin.maxAmount > 0 && this.fiatmaxMin.minAmount > 0 && this.fiatAmount_inputStatus){
        return false
      }else{
        return true
      }
    },

    //法币最大最小值提示
    // payAmount_tips(){
    //   var minError = `${this.$t('homePage.minAmountTips')} ${this.fiatCurrencyInfo.fiat}${this.fiatmaxMin.minAmount}.`;
    //   var maxError = `${this.$t('homePage.maxAmountTips')} ${this.fiatCurrencyInfo.fiat}${this.fiatmaxMin.maxAmount}.`;
    //   if(Number(this.payAmount) < this.fiatmaxMin.minAmount){
    //     this.$store.commit("faitBuyPage/set_cryptoAmount","");
    //     return minError;
    //   }else if(Number(this.payAmount) > this.fiatmaxMin.maxAmount){
    //     this.$store.commit("faitBuyPage/set_cryptoAmount","");
    //     return maxError;
    //   }
    //   return ""
    // },

    //continue button status - 限制确认按钮状态
    continueState(){
      if((this.payAmount !== '' && Number(this.payAmount) >= this.fiatmaxMin.minAmount &&
          Number(this.payAmount) <= this.fiatmaxMin.maxAmount && this.cryptoAmount !== '' &&
          Number(this.payAmount) > 0) && this.lodingStatus && this.pageInfoState === true &&
          this.cryptoAmount > 0){
        return true
      }else{
        return false
      }
    },
  },
  watch: {
    //获取首页基本数据
    allBasicData: {
      immediate: true,
      deep: true,
      handler() {
        let state = sessionStorage.getItem("buyMerchantInfo") && JSON.parse(sessionStorage.getItem("buyMerchantInfo")).showTable === 'sell';
        if(state){
          return
        }
        this.allBasicData.worldList !== undefined? this.currentLocation() : '';
      },
    },
    //监听you pay值完成页面基本数据加载
    payAmount: {
      deep: true,
      immediate: true,
      handler(val,oldVal) {
        if(val !== '' && val !== 0 && oldVal !== undefined && Number(val) !== Number(oldVal)){ // && Number(oldVal) !== 0
          this.amountControl();
        }
      }
    },
  },
  methods: {
    scroll(){
      this.$refs.includedDetails_ref.popover_state = false;
    },

    //选择法币或加密货币
    openSearch(view){
      //存在商户无法切换法币、加密货币
      if(view === 'currency' && this.cryptoSate === false){
        return;
      }
      if(view === 'payCurrency' && this.fiatSate === false){
        return;
      }
      this.$parent.openSearch(view);
    },
    //法币币种为USD如果少于两位小数，将自动添加0
    youPayBlur(){
      this.inputFocus = false;
      // if(this.payAmount > 0 && this.payCommission.code === 'USD'){
      //   this.payAmount = (Math.round(this.payAmount*100)/100).toFixed(2);
      // }
    },
    //法币币种为USD限制只能输入两位小数
    inputChange(val){
      // this.payAmount_index += 1;
      // this.payAmount_index >= 1 ? this.$store.state.buyCrypto_default = false : '';;
      // if(val.indexOf('.') > 0 && this.payCommission.code === 'USD'){
      //   this.payAmount = val.substr(0,val.indexOf('.')+3);
      // }

      //法币最大值最小值校验
      if(val !== '' && val > 0){
        this.maxMinCheck();
      }

      //新增优化处理
      this.$store.commit("faitBuyPage/set_fiatCurrencyAmount",val);

      if(val === ''){
        this.warningTextState = false;
        this.detailedInfo_state = false;
        this.$store.commit("faitBuyPage/set_cryptoAmount","");
      }
      // this.payAmount = val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      // this.payAmount = val.toString().replace(/,/g, '')
    },

    //最大值最小值校验
    maxMinCheck(){
      var maxError = `${this.$t('homePage.maxAmountTips')} ${this.fiatCurrencyInfo.fiat}${this.fiatmaxMin.maxAmount}.`;
      var minError = `${this.$t('homePage.minAmountTips')} ${this.fiatCurrencyInfo.fiat}${this.fiatmaxMin.minAmount}.`;
      if((Number(this.payAmount) > this.fiatmaxMin.maxAmount) || (Number(this.payAmount) < this.fiatmaxMin.minAmount) && this.payAmount !== ''){
        this.warningTextState = true;
        this.detailedInfo_state = false;
        if(Number(this.payAmount) > this.fiatmaxMin.maxAmount){
          this.$store.commit("faitBuyPage/set_cryptoAmount","");
          this.payAmount_tips = maxError;
        }else if(Number(this.payAmount) < this.fiatmaxMin.minAmount){
          this.$store.commit("faitBuyPage/set_cryptoAmount","");
          this.payAmount_tips = minError;
        }
      }else{
        this.warningTextState = false;
      }
    },

    //处理收到的法币的数量和显示状态
    amountControl:debounce(function () {
      if(this.payAmount === '' && this.payAmount <= 0){
        this.warningTextState = false;
        this.$store.commit("faitBuyPage/set_cryptoAmount","");
        this.detailedInfo_state = false;
        return;
      }

      this.maxMinCheck()

      //法币币种为USD限制只能输入两位小数 其他都为整数
      // if(this.payAmount > 0){
      //   if(this.fiatCurrencyInfo.fiat === 'USD'){
      //     this.youPaytype = 'number';
      //   }else{
      //     this.youPaytype = 'digit';
      //     this.$store.commit("faitBuyPage/set_fiatCurrencyAmount",parseInt(this.payAmount));
      //   }
      // }

      //限制法币的最小值、最大值
      // this.currencyData.logoUrl = this.currencyData.icon;
      if(Number(this.payAmount) >= this.fiatmaxMin.minAmount && Number(this.payAmount) <= this.fiatmaxMin.maxAmount){
        this.warningTextState = false;
        //计算加密货币数量
        this.basicsInfo(2);
      }else{
        this.warningTextState = true;
        this.detailedInfo_state = false;
        this.$store.commit("faitBuyPage/set_cryptoAmount","");
      }
    },800),

    //搜索数据处理
    currentLocation(){
      let state = sessionStorage.getItem("buyMerchantInfo") && JSON.parse(sessionStorage.getItem("buyMerchantInfo")).showTable === 'sell';
      if(state){
        return
      }
      if(JSON.stringify(this.allBasicData) !== "{}"){
        this.basicData = this.allBasicData;
        //买币币法币国家处理 - 将法币币和国家数据合并
        this.basicData.worldList.forEach((item,index)=>{
          if(item.buyFiatList){
            item.buyFiatList.forEach((item2,index2)=>{
              this.basicData.fiatCurrencyList.forEach(item3=>{
                if(item3.code === item2){
                  this.basicData.worldList[index].buyFiatList[index2] = item3;
                }
              })
            })
          }
        })
        if(this.basicData.buyRecentWorldList){
          this.basicData.buyRecentWorldList.forEach((item,index)=>{
            if(item.buyFiatList){
              item.buyFiatList.forEach((item2,index2)=>{
                this.basicData.fiatCurrencyList.forEach(item3=>{
                  if(item3.code === item2){
                    this.basicData.buyRecentWorldList[index].buyFiatList[index2] = item3;
                  }
                })
              })
            }
          })
        }
        //提示效果判断
        this.triggerType = common.equipmentEnd === 'pc' ? "hover" : "click";
      }
    },

    //--------- 优化部分 ------------
    /**
     * 修改法币、国家、加密币、网络
     * @param type 1-法币国家 2-加密币网络
     * @param val 需要存储的信息
     */
    modifyInfo(type,val){
      //当前选择法币国家和选择的法币国家相同不请求、当前选择加密币网络和选择的加密币网络相同不请求
      if(type === 1 && val.code === this.fiatCurrencyInfo.fiat && val.flag === this.fiatCurrencyInfo.alpha2)return;
      if(type === 2 && val.name === this.cryptoInfo.crypto && val.buyNetwork.network === this.networkInfo.network)return;
      //基本数据状态
      this.pageInfoState = false;
      //存储法币国家信息
      if(type===1){
        //过滤法币信息
        let newData = val.buyFiatList.filter(res=>{return res.code === val.code})[0];
        this.$store.commit("faitBuyPage/set_rawFiat",this.fiatCurrencyInfo.fiat);
        let fiatCurrencyInfo = {
          fiat: noData(val.code), //法币
          fiatSymbol: noData(newData.symbol), //法币符号
          flag: noData(val.flag), //国旗
          alpha2: noData(val.alpha2), //国家code
        }
        this.$store.commit("faitBuyPage/set_fiatCurrencyInfo",fiatCurrencyInfo);
      }else{
        //存储数字货币信息
        let cryptoInfo = {
          crypto: noData(val.name), //数字货币
          cryptoIcon: noData(val.logoUrl), //数字货币图标
        }
        this.$store.commit("faitBuyPage/set_cryptoInfo",cryptoInfo);
        //存储网络信息
        let networkInfo = {
          network: noData(val.buyNetwork.network), //网络
          networkIcon: noData(val.buyNetwork.logo), //网络图标
          addressRegex: noData(val.buyNetwork.addressRegex), //地址正则
          memoRegex: noData(val.buyNetwork.memoRegex), //memo正则
          networkName: noData(val.buyNetwork.networkName), //网络全称
        };
        this.$store.commit("faitBuyPage/set_networkInfo",networkInfo);
        this.$store.commit("faitBuyPage/set_rawFiat",'');
      }
      this.basicsInfo(2,'changeFait');
    },
    /**
     * @param type 1-默认请求 2-携带参数请求
     * @param changeState changeFait-切换法币携带上次选择法币简称
     */
    basicsInfo(type,changeState){
      let state = sessionStorage.getItem("merchantType") && sessionStorage.getItem("merchantType") === 'sell';
      if(state) return;
      let merchantType = sessionStorage.getItem("merchantType");
      if(merchantType !== 'buy') return;
      let _this = this;
      type === 1 ? this.$store.commit("faitBuyPage/set_rawFiat",'') : '';
      let params = {
        crypto: '',
        fiat: '',
        side: 'buy',
        amount: '',
        alpha2: '',
        network: '',
        payWayCode: null,
        rawFiat: '',
      };
      //官网免登陆携带信息
      if(type === 1 && hashQuery().query.type === 'officialWebsite'){
        params = {
          crypto: noData(hashQuery().query.crypto),
          fiat: noData(hashQuery().query.fiat),
          side: 'buy',
          amount: noData(hashQuery().query.amount),
          alpha2: noData(hashQuery().query.alpha2),
          network: noData(hashQuery().query.network),
          payWayCode: null,
          rawFiat: '',
        };
      }
      if(type===2){
        params = {
          crypto: this.cryptoInfo.crypto,
          fiat: this.fiatCurrencyInfo.fiat,
          side: 'buy',
          amount: this.fiatCurrencyAmount,
          alpha2: this.fiatCurrencyInfo.alpha2,
          network: this.networkInfo.network,
          networkName: noData(this.networkInfo.networkName), //网络全称
          payWayCode: null,
          rawFiat: changeState === 'changeFait' ? this.rawFiat : '',
        }
      }
      //带入商户信息 过滤商户加密货币
      let merchantInfo = "";
      if(JSON.stringify(hashQuery().query) !== "{}"){
        merchantInfo = hashQuery().fullPath;
      }else if(JSON.stringify(hashQuery().query) === "{}" && sessionStorage.getItem("buyMerchantInfo") && JSON.parse(sessionStorage.getItem("buyMerchantInfo")).merchantParam) {
        merchantInfo = JSON.parse(sessionStorage.getItem("buyMerchantInfo")).merchantParam;
      }
      this.$axios.post(this.$api.post_buyInfoFee + "?" + merchantInfo,params,'').then(res=>{
        if(res && res.data){
          this.detailedInfo_state = true;
          _this.set_basicsInfo(res);
          //法币最大最小值校验
          _this.maxMinCheck()
          //基本数据状态
          this.pageInfoState = true;
          this.merchantStatusManagement(res);
        }
      }).catch(()=>{
        //存储商户校验
        this.$store.commit("faitBuyPage/set_checkMerchant",false);
        //基本数据状态
        this.pageInfoState = true;
        this.merchantStatusManagement({});
        //信息无效清空首页数据
        this.$store.commit("faitBuyPage/restoreDefault");
        //存储用户是否有折扣
        this.$store.commit("userInfo/set_isDiscount",false);
      })
    },
    //存储页面信息
    set_basicsInfo(res){
      this.cryptoSate = true;
      this.fiatSate = true;
      this.fiatAmount_inputStatus = true;
      //存储法币国家信息
      let fiatCurrencyInfo = {
        fiat: noData(res.data.fiat), //法币
        fiatSymbol: noData(res.data.fiatSymbol), //法币符号
        flag: noData(res.data.flag), //国旗
        alpha2: noData(res.data.alpha2), //国家code
      }
      this.$store.commit("faitBuyPage/set_fiatCurrencyInfo",fiatCurrencyInfo);
      //存储数字货币信息
      let cryptoInfo = {
        crypto: noData(res.data.crypto), //数字货币
        cryptoIcon: noData(res.data.cryptoIcon), //数字货币图标
        cryptoToFiatPrice: noData(res.data.cryptoToFiatPrice), //数字货币兑法币单价 - 1crypto 约等于 多少 fiat
      }
      this.$store.commit("faitBuyPage/set_cryptoInfo",cryptoInfo);
      //存储网络信息
      let networkInfo = {
        network: noData(res.data.network), //网络
        networkIcon: noData(res.data.networkIcon), //网络图标
        addressRegex: noData(res.data.addressRegex), //地址正则
        memoRegex: noData(res.data.memoRegex), //memo正则
        networkName: noData(res.data.networkName), //网络全称
      };
      this.$store.commit("faitBuyPage/set_networkInfo",networkInfo);
      //存储费用信息
      let feeInfo = {
        rampFee: noData(res.data.rampFee),  //通道费
        networkFee: res.data.discount ? res.data.discountNetworkFee : res.data.networkFee, //网络费
        cryptoPrice: noData(res.data.cryptoPrice), //加密货币价格
        discountRampFee: noData(res.data.discountRampFee), //折扣后的通道费
      }
      this.$store.commit("faitBuyPage/set_feeInfo",feeInfo);
      //存储法币最大值最小值限制
      let fiatmaxMin = {
        maxAmount: noData(res.data.maxAmount),
        minAmount: noData(res.data.minAmount),
      }
      this.$store.commit("faitBuyPage/set_fiatmaxMin",fiatmaxMin);
      //存储数字货币金额
      let cryptoAmount = res.data.discount ? res.data.discountCryptoAmount : res.data.cryptoAmount
      this.$store.commit("faitBuyPage/set_cryptoAmount",noData(cryptoAmount));
      //存储法币金额
      this.$store.commit("faitBuyPage/set_fiatCurrencyAmount",noData(res.data.fiatAmount));
      //存储商户校验
      this.$store.commit("faitBuyPage/set_checkMerchant",res.data.checkMerchant);
      //存储用户是否有折扣
      this.$store.commit("userInfo/set_isDiscount",res.data.discount);
    },

    //商户状态管理
    /**
     * @param res - 商户校验参数回调
     */
    merchantStatusManagement(res){
      let merchantParams = JSON.parse(sessionStorage.getItem("buyMerchantInfo")) || {};
      //商户信息有效、network和address参数无效
      if(res && res.returnCode === "0000" && res.success === true && res.data !== null && res.data.checkMerchant === true){
        //商户携带加密币、法币信息有效禁止选择，携带法币金额禁止输入
        if(JSON.parse(sessionStorage.getItem("buyMerchantInfo")).fiatAmount && JSON.parse(sessionStorage.getItem("buyMerchantInfo")).fiatAmount !== ''){
          this.fiatAmount_inputStatus = false;
        }
        if(((JSON.parse(sessionStorage.getItem("buyMerchantInfo")).crypto && JSON.parse(sessionStorage.getItem("buyMerchantInfo")).crypto !== '') ||
            (JSON.parse(sessionStorage.getItem("buyMerchantInfo")).network && JSON.parse(sessionStorage.getItem("buyMerchantInfo")).network !== ''))){
          this.cryptoSate = false;
        }
        if(JSON.parse(sessionStorage.getItem("buyMerchantInfo")).fiat && JSON.parse(sessionStorage.getItem("buyMerchantInfo")).fiat !== ''){
          this.fiatSate = false;
        }
        merchantParams.merchantParam_state = true;
        sessionStorage.setItem("buyMerchantInfo",JSON.stringify(merchantParams));
        return;
      }
      //商户信息无效
      this.fiatAmount_inputStatus = true;
      this.fiatSate = true;
      this.cryptoSate = true;
      merchantParams.merchantParam_state = false;
      sessionStorage.setItem("buyMerchantInfo",JSON.stringify(merchantParams));
    },

    //下一步
    nextStep(){
      this.$store.commit('changeRouterQueryPath',false)
      this.$store.commit("secondaryBuyPayments/set_backHome_status",false)
      //买币流程步骤条
      this.$store.commit("changeBuyPayProcessTab",true)
      //loading加载
      this.lodingStatus = false
      //校验登陆信息
      if(localStorage.getItem('token') && localStorage.getItem('token')!=='' && localStorage.getItem('token') !== null){
        //查询是否是风险账号
        this.$axios.post(this.$api.post_kycDisabled,'','').then(res=>{
          if(res){
            if(res.data){
              this.lodingStatus = true
              this.isKycLoginToast(this.$parent.$parent)
            }else{
              //清空返回首页弹窗状态
              this.$store.state.tipsStatus = false
              this.$store.state.homeTabstate = 'buyCrypto';
              //配置商户携带地址参数(address)并且有效，跳转到支付方式页
              let buyMerchantInfo = JSON.parse(sessionStorage.getItem("buyMerchantInfo")) || {};
              if(this.checkMerchant === true && buyMerchantInfo.address !== undefined && buyMerchantInfo.address !== null && buyMerchantInfo.address !== '') {
                //定义创建订单参数
                let params = {
                  "cryptoCurrency": this.cryptoInfo.crypto,
                  "address": buyMerchantInfo.address,
                  "network": this.networkInfo.network,
                  "fiatCurrency": this.fiatCurrencyInfo.fiat,
                  "amount": this.fiatCurrencyAmount,
                  "depositType": 2,
                  "payWayCode": "",
                  "cryptoCurrencyVolume": this.cryptoAmount,
                  "alpha2": this.fiatCurrencyInfo.alpha2,
                  "memo": buyMerchantInfo.memo,
                  //商户信息有效携带商户参数
                  "merchantParam": JSON.parse(sessionStorage.getItem("buyMerchantInfo")).merchantParam,
                }
                this.$store.commit('buyPayments/set_placeOrderQuery',params)
                this.$router.push(`/paymentMethod`).then(()=>{
                  this.lodingStatus = true
                })
              }else {
                this.$router.push(`/receivingMode`).then(()=>{
                  this.lodingStatus = true
                })
              }
            }
          }else{
            this.lodingStatus = true
          }
        }).catch(()=>{
          this.lodingStatus = true
          this.$store.commit("changeEmailFromPath",'buyCrypto');
          this.$store.commit("changeHomeTabstate",'buyCrypto');
        })
      }else{
        this.$store.commit("changeEmailFromPath",'buyCrypto');
        this.$store.commit("changeHomeTabstate",'buyCrypto');
        this.$router.push(`/emailCode`).then(()=>{
          this.lodingStatus = true
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
html,body,#buyCrypto{
  width: 100%;
  height: 100%;
}
#buyCrypto{
  display: flex;
  flex-direction: column;
  .buyCrypto_content{
    flex: 1;
    overflow: auto;
  }
}

.form_title{
  font-family: Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 0.13rem;
  color: #031633;
}

.methods_title{
  margin-top: 0.2rem;
}
.methods_select{
  min-height: 1rem;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 0.06rem;
  padding: 0 0.16rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  &:nth-of-type(2){
    margin-top: 0.12rem;
  }
}
.inputFocus{
  border: 1px solid #41B8FD;
  box-shadow: 0 0 0.35rem rgba(89, 153, 248, 0.2);
}

.get_methods_select{
  min-height: 0.8rem;
  .methods_select-left{
    margin-top: 0;
  }
  .get_company{
    margin-top: 0;
  }
}

.pay_input{
  width: 1.65rem;
  border: none;
  outline: none;
  font-family: Medium;
  font-weight: 500;
  font-size: 0.28rem;
  color: #0059DA;
  padding: 0;
  margin-top: 0.06rem;
  &::placeholder{
    color: #C2C2C2 !important;
  }
}
.pay_company{
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  min-width: 1.44rem;
  border-radius: 0 0.12rem 0.12rem 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Regular;
  font-size: 0.16rem;
  color: #232323;
  background: #EDEDEF;
  .countryIcon{
    display: flex;
    margin-right: 0.1rem;
    height: 0.288rem;
    img{
      width: 0.3rem;
      border-radius: 50%;
      background: #E0E0E0;
    }
  }
  .rightIcon{
    width: 0.24rem;
    margin-left: 0.18rem;
  }
}

.warning_text{
  position: absolute;
  bottom: 0.08rem;
  left: 0.18rem;
  font-family: Regular;
  font-weight: 400;
  font-size: 0.13rem;
  color: #FF3333;
  line-height: 0.13rem;
}

.methods_select-left{
  margin-top: -0.1rem;
}
.get_input{
  width: 1.65rem;
  overflow: auto;
  font-family: Medium;
  font-weight: 500;
  font-size: 0.28rem;
  line-height: 0.33rem;
  color: #031633;
  margin-top: 0.06rem;
  .no_getAmount{
    color: #C2C2C2;
  }
}
.get_company{
  margin-left: auto;
  margin-top: -0.1rem;
  padding: 0 0.12rem;
  min-width: 1.2rem;
  height: 0.46rem;
  background: #F7F8FA;
  border: 1px solid #EEEEEE;
  border-radius: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Regular;
  cursor: pointer;
  .networkImg{
    margin-right: 0.1rem!important;
  }
  .getImg{
    display: flex;
    margin-right: 0.1rem;
    width: 0.24rem;
    min-height: 0.24rem;
    background: #94ACBA;
    border-radius: 50%;
    position: relative;
    img{
      width: 0.24rem;
      height: 0.24rem;
      border-radius: 50%;
    }
    .networkIcon{
      background: #FFFFFF;
      width: 0.16rem;
      height: 0.16rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 0;
      right: -0.06rem;
      img{
        width: 0.12rem;
        height: 0.12rem;
        border-radius: 50%;
      }
    }
  }
  .getText{
    display: flex;
    font-family: Regular;
    font-weight: 400;
    font-size: 0.13rem;
    color: #031633;
    min-width: 0.28rem;
  }
  .rightIcon{
    display: flex;
    align-items: center;
    width: 0.24rem;
    margin-left: auto;
    img{
      width: 0.24rem;
    }
  }
}

footer{
  z-index: 999;
  background: #FFFFFF;
  .footer_logoView{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.08rem;
    font-family: Regular;
    font-weight: 400;
    font-size: 0.13rem;
    color: #94ACBA;
    .logoText{
      margin-right: 0.12rem;
      margin-top: 0.02rem;
    }
    .logo{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 0.8rem;
      img:first-child{
        width: 0.9rem;
      }
    }
  }
}
.continue{
  width: 100%;
  height: 0.58rem;
  background: linear-gradient(88.06deg, #0059DA 0%, #1BB2F2 100%);
  opacity: 0.25;
  border-radius: 0.29rem;
  font-size: 0.17rem;
  font-family: Regular;
  font-weight: normal;
  color: #FFFFFF;
  margin-top: 0.16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: no-drop;
  border: none;
  margin-bottom: 0.12rem;
  .rightIcon{
    width: 0.2rem;
    margin-left: 0.08rem;
  }
  .rightIcon{
    span{
      height: .17rem;
    }
    margin-left: 0.08rem;
  }
}
.continue_true{
  opacity: 1;
  cursor: pointer;
}
.cursor{
  cursor: auto;
}

.calculationProcess{
  margin-top: 0.08rem;
  margin-bottom: 0.1rem;
}

@keyframes loadingIcon {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (prefers-reduced-motion: no-preference) {
  .loadingIcon {
    animation: loadingIcon infinite 2s linear;
  }
}

.pay_input ::v-deep .van-cell__value--alone{
  min-height: 0.28rem;
}
.pay_input ::v-deep .van-field__control{
  min-height: 0.28rem;
  border: none;
  outline: none;
  background: #FFFFFF;
  font-size: 0.28rem !important;
  font-family: Medium;
  font-weight: 500;
  color: #0059DA !important;
  &::placeholder{
    color: #C2C2C2 !important;
  }
}
.pay_input ::v-deep .van-field__control:disabled{
  color: #0059DA !important;
  -webkit-text-fill-color: #0059DA !important;
}
</style>
