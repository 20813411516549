export default {
  //------------------------- 公共接口 -------------------------
  "post_sendEmail": '/mail/sending',
  "post_login": '/user/login',
  "post_outLogin": '/login/to-logout',
  "get_transactionHistory": '/trade/list',
  "get_buyCryptoInfo": '/crypto/buy/v2',
  //国家法币列表
  "get_countryList": '/crypto/world/list',
  //菜单获取kyc状态
  "post_menuKYC": '/kyc/getKycStatus',
  //ENS查询
  "get_ENS": '/crypto/ens',
  //商户确认订单页费用接口
  "get_orderFee": '/trade/query/order/fee/info',

  //------------------------- 买币接口 -------------------------
  //优化所新增接口
  "post_buyInfoFee": '/index/v2/trade/quote',
  //优化整合本土支付接口
  "post_alchemyPayPay":'/pay/alchemyPay',
  "get_network": '/crypto/network/list',
  "get_inquiryFee": '/crypto/fee',
  "post_buy": '/trade/create',
  "post_indonesiaBuy": '/pay/idr/submit',
  "post_internationalCard": '/pay/card/submit',
  //新加坡支付
  "post_sinPay": '/pay/saasSubmit',
  //菲律宾支付
  "post_phpPay": '/pay/php/submit',
  "post_saveCardInfo": '/user/card/submit',
  "get_payResult": '/pay/result/check',
  "post_basisIdAuth": '/kyc/auth/check',
  "post_storageBasisIdAuth": '/kyc/auth/create',
  "get_isbasisIdAuth": '/kyc/auth/status',
  "get_orderVerification": '/merchant/order/check',
  "post_mxnSubmit": '/pay/mxn/submit',
  //查询货币支持的支付方式
  "get_payMethods": '/crypto/payment/v3',
  //查询订单状态
  "get_orderState": '/trade/',
  //信用卡信息删除
  "post_deleteForm": '/user/card/delete/',
  //BINANCE PAY
  "post_binancePay": '/pay/binance/submit',
  //买币订单详情接口
  "get_buyDetailsData": '/trade/query/buy/order/details',
  //获取submit token
  "post_submitToken": '/crypto/wallet/check',
  //支付fail结果message查询
  "get_codeMessage": '/pay/message/query',
  //巴西支付
  "post_pixPay": '/pay/pix/submit',
  //applepay validatemerchant
  "post_domainNameVerify": '/pay/apple/domainName/verify',
  //applepay alchemypay
  "post_alchemyPay":'pay/alchemyPay',
  //二次支付修改支付方式方式
  "post_updateOrderInfo": '/trade/order/update',

  //------------------------- 卖币接口 -------------------------
  //卖币查询费用
  "get_inquiryFeeSell": '/crypto/sell/fee/v4',
  //查询卖币订单订单状态
  "get_PlayCurrencyStatus":'/trade/query/sell/order',

  //获取卖币支持网络
  "get_networkList":'/crypto/sell/network',
  //卖币确认订单
  "post_sellForm": '/trade/create/sell/coin/order',
  //历史卡信息列表
  "get_cardList": '/user/card/query/sell/list',
  //卖币切换网络
  "post_sellSelectNet":'/trade/sell/order/switching/network',
  //卖币查询费率接口
  "get_sellRampfee":'/crypto/sell/fee/v4',
  //卖币退款信息
  "get_sellRefundInfo": '/trade/sell/refund/page/data',
  // 卖币申请退款
  "get_sellRefund": '/trade/sell/order/refund',
  //卖币历史订单列表
  "get_sellHistory": '/trade/query/sell/order/history',
  //卖币查询订单详细信息
  "get_sellOrderDetails": '/trade/query/sell/order/details',
  //------------------------- kyc验证获取token接口 -------------------------
    //获取kyc验证token
  "post_getKycToken":'/kyc/getKycToken',
  //买币是否要kyc验证
  "post_getKycThrough":'/kyc/buyKycThrough',
  //卖币是否要kyc验证
  "post_getKycStatus":'/kyc/getSellKycStatus',
  //用户一键登陆接口
  "getUserLogin":'/login/free/certification',
  //用户是否是风险账号
  "post_kycDisabled":'/kyc/kycDisabled',
  //查询kyc累计金额
  "post_getQuota": '/kyc/getQuota',
  //查询kyc审核结果
  "post_kycResult": '/kyc/getKycResult',
  //世界杯活动世界
  "get_activitytime":'/index/v2/activity/time'
}
