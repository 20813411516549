<template>
  <button class="button" :disabled="disabled" @click="submit" :class="{'loadingDisabled': this.buttonData.triggerNum >= 1 && loadingDisabled}" ref="buttonChild_ref">
    <slot v-if="buttonData.customName"/>
    <div style="display:flex;align-items: center;" v-else><p>{{ $t(buttonName) +''}} <span>{{ timeStatus?timeStatus:'' }}</span></p></div>
    <i v-if="buttonData.loading" class="el-icon-loading loadingIcon"></i>
    <img v-else class="rightIcon" src="../../assets/images/button-right-icon.svg" alt="">
  </button>
</template>

<script>
/**
 * 确认订单按钮组件 可查询订单状态
 * buttonData.agreement === 1 支付
 * buttonData.agreement >1 查询支付状态
 * buttonData.customName 自定义按钮名称状态
 * buttonData.type 确认支付后是否展示其他文案
 * @returns {Promise<void>}
 */
export default {
  name: "Button",
  props: {
    buttonData: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: null
    },
    loadingDisabled: {
      type: Boolean,
      default: null
    },
    timeStatus: {
      type: String,
      default: ''
    },
  },
  data(){
    return{
      buttonName: "nav.ConfirmPayment", // Confirm Payment ｜ I have completed the payment
    }
  },
  watch:{
    'buttonData': {
      deep: true,
      immediate: true,
      handler(val){
        if(val.loading === true && val.customName === false && this.buttonData.type !== 'baht'){
          this.buttonName = this.$t('nav.queryOderState');
        }
      }
    }
  },
  methods: {
    submit(){
      this.buttonData.triggerNum += 1;
      if(this.buttonData.triggerNum === 1){
        this.buttonData.loading = true;
        if(this.buttonData.customName === false && this.buttonData.type !== 'baht'){
          this.buttonName = "nav.queryOderState";
        }
        return;
      }
      //新打开tab跳外链接无弹框功能
      if(this.buttonData.type && this.buttonData.type === 'baht' && this.$store.state.ovo_va_mex_qrs_Status){
        // this.$parent.$parent.tipsState = true;
        // this.$parent.$parent.BuyTimeOut = '';
        return;
      }
      if(this.buttonData.triggerNum > 1 && this.buttonData.customName === false && this.$store.state.ovo_va_mex_qrs_Status){
        this.$parent.$parent.BuyTimeOut = '';
        this.$parent.$parent.tipsState = true;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.button{
  width: 100%;
  height: 0.58rem;
  background: linear-gradient(88.06deg, #0059DA 0%, #1BB2F2 100%);
  border-radius: 0.29rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.17rem;
  font-family: Regular;
  font-weight: 400;
  color: #FFFFFF;
  margin-top: 0.12rem;
  cursor: pointer;
  border: none;
  .rightIcon{
    width: 0.24rem;
    margin-left: 0.08rem;
  }
  .loadingIcon{
    font-size: 0.24rem;
    margin-left: 0.08rem;
  }
}
.button[disabled] {
  opacity: 0.25;
  cursor: no-drop;
}
.loadingDisabled{
  cursor: no-drop !important;
  opacity: 0.25 !important;
}

.continue{
}
.continue:disabled{
  opacity: 0.25;
  cursor: no-drop;
}
</style>
