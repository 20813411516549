/**
 * kycInfo
 * kyc所需参数
 */

export default {
    namespaced: true,
    state: {

    },
    mutations: {

    }
}
