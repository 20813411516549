import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/initialPage'

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location)
}
const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: "/",
    name: 'home',
    component: Home,
    redirectTo: '/index',
    redirect:'index',
    meta: {
      title: 'Home',
      keepAlive: true,
    }
  },
  {
    path: "/index",
    name: 'home',
    component: Home,
    meta: {
      title: 'Home',
      keepAlive: true,
    }
  },
  //邮箱填写路由
  {
    path: "/emailCode",
    name: 'emailCode',
    component: () => import('../views/emailCode/index.vue'),
    meta: {
      title: ' ',
      keepAlive: true,
    }
  },
  //账号禁用路由
  {
    path: "/accountsDisabled",
    name: 'accountsDisabled',
    component: () => import('../components/AccountsDisabled  /accountsDisabled.vue'),
    meta: {
      title: ' ',
      keepAlive: true,
    }
  },
  //验证验证码的路由
  {
    path: "/verifyCode",
    name: 'verifyCode',
    component: () => import('../views/emailCode/children/verifyCode.vue'),
    meta: {
      title: ' ',
      keepAlive: true,
    }
  },
  //kyc验证
  {
    path: "/kycVerification",
    name: 'KycVerification',
    component: () => import('../views/KycVerification/index'),
    meta: {
      title: ' ',
      keepAlive: true,
    }
  },
  //交易历史列表
  {
    path: "/tradeHistory",
    name: 'tradeHistory',
    component: () => import('../views/tradeHistory/index.vue'),
    meta: {
      requireAuth: true,
      title: 'nav.routerName_Transactions',
      keepAlive: true,
    }
  },
  //交易历史详情
  {
    path: "/tradeHistory-details",
    name: 'tradeHistory-details',
    component: () => import('../views/tradeHistory/SellDetails'),
    meta: {
      requireAuth: true,
      title: 'nav.routerName_Transactions',
      keepAlive: true,
    }
  },
  {
    path: "/tradeHistory-BuyDetails",
    name: 'buyDetails',
    component: () => import('../views/tradeHistory/buyDetails'),
    meta: {
      requireAuth: true,
      title: 'nav.routerName_Transactions',
      keepAlive: true,
    }
  },
  {
    path: "/Refund",
    name: 'Refund',
    component: () => import('../views/tradeHistory/refund.vue'),
    meta: {
      requireAuth: true,
      title: 'nav.routerName_Refund',
      keepAlive: true,
    }
  },
  //买币路由
  {
    path: "/receivingMode",
    name: 'receivingMode',
    component: () => import('../views/buyCurrency/receivingMode/index.vue'),
    meta: {
      title: 'nav.routerName_receivingMode',
      keepAlive: true,
    }
  },
  {
    path: "/paymentMethod",
    name: 'paymentMethod',
    component: () => import('../views/buyCurrency/paymentMethod/index.vue'),
    meta: {
      title: 'nav.routerName_paymentMethod',
      keepAlive: true,
    }
  },
  {
    path: "/creditCardConfig",
    name: 'creditCardConfig',
    component: () => import('../views/buyCurrency/payments/creditCard/configPayment.vue'),
    meta: {
      requireAuth: true,
      title: 'nav.routerName_creditCardConfig',
      keepAlive: true,
    }
  },
  {
    path: "/otherWays-VA",
    name: 'otherWays-VA',
    component: () => import('../views/buyCurrency/payments/virtualAccount-opm'),
    meta: {
      requireAuth: true,
      title: 'nav.routerName_buyPayment',
      keepAlive: false,
    }
  },
  {
    path: "/otherWayPay",
    name: 'otherWayPay',
    component: () => import('../views/buyCurrency/payments/e-wallet'),
    meta: {
      requireAuth: true,
      title: 'nav.routerName_buyOtherWayPay',
      keepAlive: false,
    }
  },
  {
    path: "/phpPayment",
    name: 'phpPayment',
    component: () => import('../views/buyCurrency/payments/phpPayment/index'),
    meta: {
      requireAuth: true,
      title: 'nav.routerName_buyOtherWayPay',
      keepAlive: false,
    }
  },
  {
    path: "/binancePay",
    name: 'binancePay',
    component: () => import('../views/buyCurrency/payments/binancePay/index.vue'),
    meta: {
      requireAuth: true,
      title: 'nav.routerName_buyOtherWayPay',
      keepAlive: false,
    }
  },
  {
    path: "/ArgentinaPay",
    name: 'ArgentinaPay',
    component: () => import('../views/buyCurrency/payments/ArgentinaPay/index.vue'),
    meta: {
      requireAuth: true,
      title: 'nav.routerName_buyOtherWayPay',
      keepAlive: false,
    }
  },
    //欧元支付
  {
    path: "/euroPay",
    name: 'euroPay',
    component: () => import('../views/buyCurrency/payments/euroPay/index'),
    meta: {
      requireAuth: true,
      title: 'nav.routerName_buyOtherWayPay',
      keepAlive: false,
    }
  },
  //巴西支付
  {
    path: "/brazilPay",
    name: 'BrazilPay',
    component: () => import('../views/buyCurrency/payments/brazilPay/index'),
    meta: {
      requireAuth: true,
      title: 'nav.routerName_buyOtherWayPay',
      keepAlive: false,
    }
  },
  {
    path: "/paymentResult",
    name: 'paymentResult',
    component: () => import('../views/buyCurrency/paymentResult/index.vue'),
    meta: {
      requireAuth: true,
      title: 'nav.routerName_paymentResult',
      keepAlive: true,
    }
  },
  //二次支付卡信息页
  {
    path: "/errorFormInfo",
    name: 'errorFormInfo',
    component: () => import('../views/payAgain/index'),
    meta: {
      requireAuth: true,
      title: 'nav.routerName_errorFormInfo',
      keepAlive: true,
    }
  },
  //卖币路由
  {
    path: "/sell-formUserInfo",
    name: 'sellCardInfo',
    component: () => import('../views/sellCurrency/cardInfo'),
    meta: {
      requireAuth: true,
      title: 'nav.routerName_sellForm',
      keepAlive: true,
    },
  },
  {
    path: "/sellOrder",
    name: "sellOrder",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/orderState/index.vue'),
    meta: {
      requireAuth: true,
      title: 'nav.routerName_sellOrder',
      title1:'',
      keepAlive: true,
    }
  },
]

const createRouter = () => new Router({
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

router.beforeEach((to, from, next) => {
  //keep-alive继承上一页路由滑动高度问题
  setTimeout(()=>{
    if(document.body.getElementsByClassName("routerView_box")){
      document.body.getElementsByClassName("routerView_box")[0].scrollIntoView(false)
    }
  })
  next()
})

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

router.beforeEach((to, from, next) => {
  setTimeout(()=>{
    document.body.getElementsByClassName("routerView_box")[0].scrollIntoView(false)
  })
  next()
})

export default router
