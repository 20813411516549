/**
 * 项目工程多语言配置
 */
import Vue from "vue";
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: sessionStorage.getItem("language") || 'en-US',
    //多语言默认值不可设置常量  否则刷新页面不保存状态
    fallbackLocale: sessionStorage.getItem("language")?sessionStorage.getItem("language"):'en-US',
    messages: {
        'en-US': require('./language/en-US'),
        'zh-HK': require('./language/zh-HK'),
        'es': require('./language/es'),
    },
    silentFallbackWarn: true,
    silentTranslationWarn: true,
})
export default i18n;


