/**
 * userInfo
 * 用户信息
 */

export default {
  namespaced: true,
  state: {
    //用户kyc信息
    kyc_info: {
      levelIndex: '',
      kycTips: '',
      appleUrl:''
    },
    //用户是否有折扣
    isDiscount: false,
    //用户token
    token: localStorage.getItem("token"),
    //活动状态
    activityStatus: false,
  },
  mutations: {
    //存储kyc需要进行的步骤
    set_kycInfo_levelIndex(state,val){
      state.kyc_info.levelIndex = val;
    },
    //修改kyc在执行步骤
    set_kycInfo_kycTips(state,val){
      state.kyc_info.kycTips = val;
    },
    set_isDiscount(state,val){
      state.isDiscount = val;
    },
    set_token(state,val){
      state.token = val;
    },
    set_activityStatus(state,val){
      state.activityStatus = val;
    },
    set_appleUrl(state,val){
      state.appleUrl = val
    }
  },
}
